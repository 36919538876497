import React, { useState } from "react";
import { Link } from "react-router-dom";
import { formatStringIfNullOrEmpty } from '../../../../models/Helper';
import Popup from "../../../../components/dialog/Popup";
import UpdateFees from "./UpdateFees";

const PeriodicFee = (props: any) => {
  const {
    title,
    data,
    isParent,
    setupId,
    issuerId,
    customerType,
    currencyCode,
    feesType,
    subType,
    securityClassifications,
    canPageBeRendered,
    proccedAndLoad 
  } = props;

  const updateAccess = securityClassifications?.includes("Update");

  const [openPopup, setOpenPopup] = useState(false);
  return (
    <tr>
      <td className={isParent ? "px-4" : "px-4"}>
        { updateAccess && <Link
          to="#"
          onClick={() => {
            setOpenPopup(true);
          }}
        >
          <i className="fas fa-pen-square" />
        </Link>
        }
        &emsp; {title}  
      </td>
      <td className="text-right" style={{ width: "20%" }}>
        {!data?.fixedFee ? 0 : formatStringIfNullOrEmpty(data.fixedFee / 100)}
      </td>
      <td className="text-right" style={{ width: "20%" }}>
        {!data?.percentileMarkup ? 0 : data.percentileMarkup}
      </td>
      <td className="text-right" style={{ width: "20%" }}>
        {!data?.minimumFee ? 0 : formatStringIfNullOrEmpty(data.minimumFee / 100)}
      </td>
      <Popup
        title="Update Currency Fees"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <UpdateFees
          setOpenPopup={setOpenPopup}
          data={data}
          customerType={customerType}
          issuerId={issuerId}
          setupId={setupId}
          currencyCode={currencyCode}
          feesType={feesType}
          subType={subType}
          securityClassifications={securityClassifications} 
          canPageBeRendered={canPageBeRendered} 
          proccedAndLoad={proccedAndLoad} 
        />
      </Popup>
    </tr>
  );
};

export default PeriodicFee;
