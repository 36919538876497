export const GetCurrencyName = (currencyCode: number) => {
  return ISO4217.filter(
    (item) => item.NumericCode === currencyCode.toString()
  )[0].Currency;
};

export const GetCurrencies = () => {
  let currencyList = ISO4217.map((item: any) => ({
    id: item.AlphabeticCode,
    name: `[${item.AlphabeticCode}] - ${item.Currency}`,
  }));
  
  
  return currencyList.filter((x, i, a) => a.indexOf(x) === i);
};

const ISO4217 = [
  { Currency:"Euro", AlphabeticCode:"EUR", NumericCode:"978"},
  { Currency:"Pound Sterling", AlphabeticCode:"GBP", NumericCode:"826"},
  { Currency:"US Dollar", AlphabeticCode:"USD", NumericCode:"840"},
  { Currency:"UAE Dirham", AlphabeticCode:"AED", NumericCode:"784"},
  { Currency:"Afghani", AlphabeticCode:"AFN", NumericCode:"971"},
  { Currency:"Lek", AlphabeticCode:"ALL", NumericCode:"8"},
  { Currency:"Armenian Dram", AlphabeticCode:"AMD", NumericCode:"51"},
  { Currency:"Netherlands Antillean Guilder", AlphabeticCode:"ANG", NumericCode:"532"},
  { Currency:"Kwanza", AlphabeticCode:"AOA", NumericCode:"973"},
  { Currency:"Argentine Peso", AlphabeticCode:"ARS", NumericCode:"32"},
  { Currency:"Australian Dollar", AlphabeticCode:"AUD", NumericCode:"36"},
  { Currency:"Aruban Florin", AlphabeticCode:"AWG", NumericCode:"533"},
  { Currency:"Azerbaijan Manat", AlphabeticCode:"AZN", NumericCode:"944"},
  { Currency:"Convertible Mark", AlphabeticCode:"BAM", NumericCode:"977"},
  { Currency:"Barbados Dollar", AlphabeticCode:"BBD", NumericCode:"52"},
  { Currency:"Taka", AlphabeticCode:"BDT", NumericCode:"50"},
  { Currency:"Bulgarian Lev", AlphabeticCode:"BGN", NumericCode:"975"},
  { Currency:"Bahraini Dinar", AlphabeticCode:"BHD", NumericCode:"48"},
  { Currency:"Burundi Franc", AlphabeticCode:"BIF", NumericCode:"108"},
  { Currency:"Bermudian Dollar", AlphabeticCode:"BMD", NumericCode:"60"},
  { Currency:"Brunei Dollar", AlphabeticCode:"BND", NumericCode:"96"},
  { Currency:"Boliviano", AlphabeticCode:"BOB", NumericCode:"68"},
  { Currency:"Mvdol", AlphabeticCode:"BOV", NumericCode:"984"},
  { Currency:"Brazilian Real", AlphabeticCode:"BRL", NumericCode:"986"},
  { Currency:"Bahamian Dollar", AlphabeticCode:"BSD", NumericCode:"44"},
  { Currency:"Ngultrum", AlphabeticCode:"BTN", NumericCode:"64"},
  { Currency:"Pula", AlphabeticCode:"BWP", NumericCode:"72"},
  { Currency:"Belarusian Ruble", AlphabeticCode:"BYN", NumericCode:"933"},
  { Currency:"Belize Dollar", AlphabeticCode:"BZD", NumericCode:"84"},
  { Currency:"Canadian Dollar", AlphabeticCode:"CAD", NumericCode:"124"},
  { Currency:"Congolese Franc", AlphabeticCode:"CDF", NumericCode:"976"},
  { Currency:"WIR Euro", AlphabeticCode:"CHE", NumericCode:"947"},
  { Currency:"Swiss Franc", AlphabeticCode:"CHF", NumericCode:"756"},
  { Currency:"WIR Franc", AlphabeticCode:"CHW", NumericCode:"948"},
  { Currency:"Unidad de Fomento", AlphabeticCode:"CLF", NumericCode:"990"},
  { Currency:"Chilean Peso", AlphabeticCode:"CLP", NumericCode:"152"},
  { Currency:"Yuan Renminbi", AlphabeticCode:"CNY", NumericCode:"156"},
  { Currency:"Colombian Peso", AlphabeticCode:"COP", NumericCode:"170"},
  { Currency:"Unidad de Valor Real", AlphabeticCode:"COU", NumericCode:"970"},
  { Currency:"Costa Rican Colon", AlphabeticCode:"CRC", NumericCode:"188"},
  { Currency:"Peso Convertible", AlphabeticCode:"CUC", NumericCode:"931"},
  { Currency:"Cuban Peso", AlphabeticCode:"CUP", NumericCode:"192"},
  { Currency:"Cabo Verde Escudo", AlphabeticCode:"CVE", NumericCode:"132"},
  { Currency:"Czech Koruna", AlphabeticCode:"CZK", NumericCode:"203"},
  { Currency:"Djibouti Franc", AlphabeticCode:"DJF", NumericCode:"262"},
  { Currency:"Danish Krone", AlphabeticCode:"DKK", NumericCode:"208"},
  { Currency:"Dominican Peso", AlphabeticCode:"DOP", NumericCode:"214"},
  { Currency:"Algerian Dinar", AlphabeticCode:"DZD", NumericCode:"12"},
  { Currency:"Egyptian Pound", AlphabeticCode:"EGP", NumericCode:"818"},
  { Currency:"Nakfa", AlphabeticCode:"ERN", NumericCode:"232"},
  { Currency:"Ethiopian Birr", AlphabeticCode:"ETB", NumericCode:"230"},
  { Currency:"Fiji Dollar", AlphabeticCode:"FJD", NumericCode:"242"},
  { Currency:"Falkland Islands Pound", AlphabeticCode:"FKP", NumericCode:"238"},
  { Currency:"Lari", AlphabeticCode:"GEL", NumericCode:"981"},
  { Currency:"Ghana Cedi", AlphabeticCode:"GHS", NumericCode:"936"},
  { Currency:"Gibraltar Pound", AlphabeticCode:"GIP", NumericCode:"292"},
  { Currency:"Dalasi", AlphabeticCode:"GMD", NumericCode:"270"},
  { Currency:"Guinean Franc", AlphabeticCode:"GNF", NumericCode:"324"},
  { Currency:"Quetzal", AlphabeticCode:"GTQ", NumericCode:"320"},
  { Currency:"Guyana Dollar", AlphabeticCode:"GYD", NumericCode:"328"},
  { Currency:"Hong Kong Dollar", AlphabeticCode:"HKD", NumericCode:"344"},
  { Currency:"Lempira", AlphabeticCode:"HNL", NumericCode:"340"},
  { Currency:"Kuna", AlphabeticCode:"HRK", NumericCode:"191"},
  { Currency:"Gourde", AlphabeticCode:"HTG", NumericCode:"332"},
  { Currency:"Forint", AlphabeticCode:"HUF", NumericCode:"348"},
  { Currency:"Rupiah", AlphabeticCode:"IDR", NumericCode:"360"},
  { Currency:"New Israeli Sheqel", AlphabeticCode:"ILS", NumericCode:"376"},
  { Currency:"Indian Rupee", AlphabeticCode:"INR", NumericCode:"356"},
  { Currency:"Iraqi Dinar", AlphabeticCode:"IQD", NumericCode:"368"},
  { Currency:"Iranian Rial", AlphabeticCode:"IRR", NumericCode:"364"},
  { Currency:"Iceland Krona", AlphabeticCode:"ISK", NumericCode:"352"},
  { Currency:"Jamaican Dollar", AlphabeticCode:"JMD", NumericCode:"388"},
  { Currency:"Jordanian Dinar", AlphabeticCode:"JOD", NumericCode:"400"},
  { Currency:"Yen", AlphabeticCode:"JPY", NumericCode:"392"},
  { Currency:"Kenyan Shilling", AlphabeticCode:"KES", NumericCode:"404"},
  { Currency:"Som", AlphabeticCode:"KGS", NumericCode:"417"},
  { Currency:"Riel", AlphabeticCode:"KHR", NumericCode:"116"},
  { Currency:"Comorian Franc ", AlphabeticCode:"KMF", NumericCode:"174"},
  { Currency:"North Korean Won", AlphabeticCode:"KPW", NumericCode:"408"},
  { Currency:"Won", AlphabeticCode:"KRW", NumericCode:"410"},
  { Currency:"Kuwaiti Dinar", AlphabeticCode:"KWD", NumericCode:"414"},
  { Currency:"Cayman Islands Dollar", AlphabeticCode:"KYD", NumericCode:"136"},
  { Currency:"Tenge", AlphabeticCode:"KZT", NumericCode:"398"},
  { Currency:"Lao Kip", AlphabeticCode:"LAK", NumericCode:"418"},
  { Currency:"Lebanese Pound", AlphabeticCode:"LBP", NumericCode:"422"},
  { Currency:"Sri Lanka Rupee", AlphabeticCode:"LKR", NumericCode:"144"},
  { Currency:"Liberian Dollar", AlphabeticCode:"LRD", NumericCode:"430"},
  { Currency:"Loti", AlphabeticCode:"LSL", NumericCode:"426"},
  { Currency:"Libyan Dinar", AlphabeticCode:"LYD", NumericCode:"434"},
  { Currency:"Moroccan Dirham", AlphabeticCode:"MAD", NumericCode:"504"},
  { Currency:"Moldovan Leu", AlphabeticCode:"MDL", NumericCode:"498"},
  { Currency:"Malagasy Ariary", AlphabeticCode:"MGA", NumericCode:"969"},
  { Currency:"Denar", AlphabeticCode:"MKD", NumericCode:"807"},
  { Currency:"Kyat", AlphabeticCode:"MMK", NumericCode:"104"},
  { Currency:"Tugrik", AlphabeticCode:"MNT", NumericCode:"496"},
  { Currency:"Pataca", AlphabeticCode:"MOP", NumericCode:"446"},
  { Currency:"Ouguiya", AlphabeticCode:"MRU", NumericCode:"929"},
  { Currency:"Mauritius Rupee", AlphabeticCode:"MUR", NumericCode:"480"},
  { Currency:"Rufiyaa", AlphabeticCode:"MVR", NumericCode:"462"},
  { Currency:"Malawi Kwacha", AlphabeticCode:"MWK", NumericCode:"454"},
  { Currency:"Mexican Peso", AlphabeticCode:"MXN", NumericCode:"484"},
  { Currency:"Mexican Unidad de Inversion (UDI)", AlphabeticCode:"MXV", NumericCode:"979"},
  { Currency:"Malaysian Ringgit", AlphabeticCode:"MYR", NumericCode:"458"},
  { Currency:"Mozambique Metical", AlphabeticCode:"MZN", NumericCode:"943"},
  { Currency:"Namibia Dollar", AlphabeticCode:"NAD", NumericCode:"516"},
  { Currency:"Naira", AlphabeticCode:"NGN", NumericCode:"566"},
  { Currency:"Cordoba Oro", AlphabeticCode:"NIO", NumericCode:"558"},
  { Currency:"Norwegian Krone", AlphabeticCode:"NOK", NumericCode:"578"},
  { Currency:"Nepalese Rupee", AlphabeticCode:"NPR", NumericCode:"524"},
  { Currency:"New Zealand Dollar", AlphabeticCode:"NZD", NumericCode:"554"},
  { Currency:"Rial Omani", AlphabeticCode:"OMR", NumericCode:"512"},
  { Currency:"Balboa", AlphabeticCode:"PAB", NumericCode:"590"},
  { Currency:"Sol", AlphabeticCode:"PEN", NumericCode:"604"},
  { Currency:"Kina", AlphabeticCode:"PGK", NumericCode:"598"},
  { Currency:"Philippine Peso", AlphabeticCode:"PHP", NumericCode:"608"},
  { Currency:"Pakistan Rupee", AlphabeticCode:"PKR", NumericCode:"586"},
  { Currency:"Zloty", AlphabeticCode:"PLN", NumericCode:"985"},
  { Currency:"Guarani", AlphabeticCode:"PYG", NumericCode:"600"},
  { Currency:"Qatari Rial", AlphabeticCode:"QAR", NumericCode:"634"},
  { Currency:"Romanian Leu", AlphabeticCode:"RON", NumericCode:"946"},
  { Currency:"Serbian Dinar", AlphabeticCode:"RSD", NumericCode:"941"},
  { Currency:"Russian Ruble", AlphabeticCode:"RUB", NumericCode:"643"},
  { Currency:"Rwanda Franc", AlphabeticCode:"RWF", NumericCode:"646"},
  { Currency:"Saudi Riyal", AlphabeticCode:"SAR", NumericCode:"682"},
  { Currency:"Solomon Islands Dollar", AlphabeticCode:"SBD", NumericCode:"90"},
  { Currency:"Seychelles Rupee", AlphabeticCode:"SCR", NumericCode:"690"},
  { Currency:"Sudanese Pound", AlphabeticCode:"SDG", NumericCode:"938"},
  { Currency:"Swedish Krona", AlphabeticCode:"SEK", NumericCode:"752"},
  { Currency:"Singapore Dollar", AlphabeticCode:"SGD", NumericCode:"702"},
  { Currency:"Saint Helena Pound", AlphabeticCode:"SHP", NumericCode:"654"},
  { Currency:"Leone", AlphabeticCode:"SLE", NumericCode:"925"},
  { Currency:"Leone", AlphabeticCode:"SLL", NumericCode:"694"},
  { Currency:"Somali Shilling", AlphabeticCode:"SOS", NumericCode:"706"},
  { Currency:"Surinam Dollar", AlphabeticCode:"SRD", NumericCode:"968"},
  { Currency:"South Sudanese Pound", AlphabeticCode:"SSP", NumericCode:"728"},
  { Currency:"Dobra", AlphabeticCode:"STN", NumericCode:"930"},
  { Currency:"El Salvador Colon", AlphabeticCode:"SVC", NumericCode:"222"},
  { Currency:"Syrian Pound", AlphabeticCode:"SYP", NumericCode:"760"},
  { Currency:"Lilangeni", AlphabeticCode:"SZL", NumericCode:"748"},
  { Currency:"Baht", AlphabeticCode:"THB", NumericCode:"764"},
  { Currency:"Somoni", AlphabeticCode:"TJS", NumericCode:"972"},
  { Currency:"Turkmenistan New Manat", AlphabeticCode:"TMT", NumericCode:"934"},
  { Currency:"Tunisian Dinar", AlphabeticCode:"TND", NumericCode:"788"},
  { Currency:"Pa’anga", AlphabeticCode:"TOP", NumericCode:"776"},
  { Currency:"Turkish Lira", AlphabeticCode:"TRY", NumericCode:"949"},
  { Currency:"Trinidad and Tobago Dollar", AlphabeticCode:"TTD", NumericCode:"780"},
  { Currency:"New Taiwan Dollar", AlphabeticCode:"TWD", NumericCode:"901"},
  { Currency:"Tanzanian Shilling", AlphabeticCode:"TZS", NumericCode:"834"},
  { Currency:"Hryvnia", AlphabeticCode:"UAH", NumericCode:"980"},
  { Currency:"Uganda Shilling", AlphabeticCode:"UGX", NumericCode:"800"},
  { Currency:"Uruguay Peso en Unidades Indexadas (UI)", AlphabeticCode:"UYI", NumericCode:"940"},
  { Currency:"Peso Uruguayo", AlphabeticCode:"UYU", NumericCode:"858"},
  { Currency:"Unidad Previsional", AlphabeticCode:"UYW", NumericCode:"927"},
  { Currency:"Uzbekistan Sum", AlphabeticCode:"UZS", NumericCode:"860"},
  { Currency:"Bolívar Soberano", AlphabeticCode:"VED", NumericCode:"926"},
  { Currency:"Bolívar Soberano", AlphabeticCode:"VES", NumericCode:"928"},
  { Currency:"Dong", AlphabeticCode:"VND", NumericCode:"704"},
  { Currency:"Vatu", AlphabeticCode:"VUV", NumericCode:"548"},
  { Currency:"Tala", AlphabeticCode:"WST", NumericCode:"882"},
  { Currency:"CFA Franc BEAC", AlphabeticCode:"XAF", NumericCode:"950"},
  { Currency:"East Caribbean Dollar", AlphabeticCode:"XCD", NumericCode:"951"},
  { Currency:"CFA Franc BCEAO", AlphabeticCode:"XOF", NumericCode:"952"},
  { Currency:"CFP Franc", AlphabeticCode:"XPF", NumericCode:"953"},
  { Currency:"Yemeni Rial", AlphabeticCode:"YER", NumericCode:"886"},
  { Currency:"Rand", AlphabeticCode:"ZAR", NumericCode:"710"},
  { Currency:"Zambian Kwacha", AlphabeticCode:"ZMW", NumericCode:"967"},
  { Currency:"Zimbabwe Dollar", AlphabeticCode:"ZWL", NumericCode:"932"},  
];

export const GetNewCurrencies = () => {
  let list = currencyList.map((item: any) => ({
    id: item.AlphabeticCode,
    name: `[${item.AlphabeticCode}] - ${item.Currency}`,
    numCode: parseInt(item.NumericCode),
  }));

  return list.filter((x, i, a) => a.indexOf(x) === i);
};

const currencyList = [
  { Currency: "Euro", AlphabeticCode: "EUR", NumericCode: "978" },
  { Currency: "Afghani", AlphabeticCode: "AFN", NumericCode: "971" },
  { Currency: "Lek", AlphabeticCode: "ALL", NumericCode: "8" },
  { Currency: "Algerian Dinar", AlphabeticCode: "DZD", NumericCode: "12" },
  { Currency: "US Dollar", AlphabeticCode: "USD", NumericCode: "840" },
  { Currency: "Kwanza", AlphabeticCode: "AOA", NumericCode: "973" },
  {
    Currency: "East Caribbean Dollar",
    AlphabeticCode: "XCD",
    NumericCode: "951",
  },
  { Currency: "Peso Argentino", AlphabeticCode: "ARP", NumericCode: "32" },
  { Currency: "Armenian Dram", AlphabeticCode: "AMD", NumericCode: "51" },
  { Currency: "Aruban Florin", AlphabeticCode: "AWG", NumericCode: "533" },
  { Currency: "Australian Dollar", AlphabeticCode: "AUD", NumericCode: "36" },
  { Currency: "Azerbaijan Manat", AlphabeticCode: "AZN", NumericCode: "944" },
  { Currency: "Bahamian Dollar", AlphabeticCode: "BSD", NumericCode: "44" },
  { Currency: "Bahraini Dinar", AlphabeticCode: "BHD", NumericCode: "48" },
  { Currency: "Taka", AlphabeticCode: "BDT", NumericCode: "50" },
  { Currency: "Barbados Dollar", AlphabeticCode: "BBD", NumericCode: "52" },
  { Currency: "Belarusian Ruble", AlphabeticCode: "BYR", NumericCode: "974" },
  { Currency: "Belize Dollar", AlphabeticCode: "BZD", NumericCode: "84" },
  { Currency: "CFA Franc BCEAO", AlphabeticCode: "XOF", NumericCode: "952" },
  { Currency: "Bermudian Dollar", AlphabeticCode: "BMD", NumericCode: "60" },
  { Currency: "Indian Rupee", AlphabeticCode: "INR", NumericCode: "356" },
  { Currency: "Peso boliviano", AlphabeticCode: "BOP", NumericCode: "68" },
  { Currency: "Convertible Mark", AlphabeticCode: "BAM", NumericCode: "977" },
  { Currency: "Pula", AlphabeticCode: "BWP", NumericCode: "72" },
  { Currency: "Brazilian Real", AlphabeticCode: "BRL", NumericCode: "986" },
  { Currency: "Brunei Dollar", AlphabeticCode: "BND", NumericCode: "96" },
  { Currency: "Bulgarian Lev", AlphabeticCode: "BGN", NumericCode: "975" },
  { Currency: "Burundi Franc", AlphabeticCode: "BIF", NumericCode: "108" },
  { Currency: "Riel", AlphabeticCode: "KHR", NumericCode: "116" },
  { Currency: "CFA Franc BEAC", AlphabeticCode: "XAF", NumericCode: "950" },
  { Currency: "Congolese Franc", AlphabeticCode: "CDF", NumericCode: "976" },
  { Currency: "Canadian Dollar", AlphabeticCode: "CAD", NumericCode: "124" },
  { Currency: "Cabo Verde Escudo", AlphabeticCode: "CVE", NumericCode: "132" },
  {
    Currency: "Cayman Islands Dollar",
    AlphabeticCode: "KYD",
    NumericCode: "136",
  },
  { Currency: "Chilean Peso", AlphabeticCode: "CLP", NumericCode: "152" },
  { Currency: "Yuan Renminbi", AlphabeticCode: "CNY", NumericCode: "156" },
  { Currency: "Colombian Peso", AlphabeticCode: "COP", NumericCode: "170" },
  { Currency: "Comorian Franc", AlphabeticCode: "KMF", NumericCode: "174" },
  { Currency: "New Zealand Dollar", AlphabeticCode: "NZD", NumericCode: "554" },
  { Currency: "Costa Rican Colon", AlphabeticCode: "CRC", NumericCode: "188" },
  { Currency: "Croatian Dinar", AlphabeticCode: "HRD", NumericCode: "191" },
  { Currency: "Cuban Peso", AlphabeticCode: "CUP", NumericCode: "192" },
  {
    Currency: "Netherlands Antillean Guilder",
    AlphabeticCode: "ANG",
    NumericCode: "532",
  },
  { Currency: "Czech Koruna", AlphabeticCode: "CZK", NumericCode: "203" },
  { Currency: "Danish Krone", AlphabeticCode: "DKK", NumericCode: "208" },
  { Currency: "Djibouti Franc", AlphabeticCode: "DJF", NumericCode: "262" },
  { Currency: "Dominican Peso", AlphabeticCode: "DOP", NumericCode: "214" },
  { Currency: "Egyptian Pound", AlphabeticCode: "EGP", NumericCode: "818" },
  { Currency: "Kuna", AlphabeticCode: "HRK", NumericCode: "191" },
  { Currency: "Nakfa", AlphabeticCode: "ERN", NumericCode: "232" },
  { Currency: "Ethiopian Birr", AlphabeticCode: "ETB", NumericCode: "230" },
  {
    Currency: "Falkland Islands Pound",
    AlphabeticCode: "FKP",
    NumericCode: "238",
  },
  { Currency: "Fiji Dollar", AlphabeticCode: "FJD", NumericCode: "242" },
  { Currency: "CFP Franc", AlphabeticCode: "XPF", NumericCode: "953" },
  { Currency: "Dalasi", AlphabeticCode: "GMD", NumericCode: "270" },
  { Currency: "Lari", AlphabeticCode: "GEL", NumericCode: "981" },
  { Currency: "Ghana Cedi", AlphabeticCode: "GHS", NumericCode: "936" },
  { Currency: "Gibraltar Pound", AlphabeticCode: "GIP", NumericCode: "292" },
  { Currency: "Quetzal", AlphabeticCode: "GTQ", NumericCode: "320" },
  { Currency: "Pound Sterling", AlphabeticCode: "GBP", NumericCode: "826" },
  { Currency: "Guinean Franc", AlphabeticCode: "GNF", NumericCode: "324" },
  { Currency: "Guyana Dollar", AlphabeticCode: "GYD", NumericCode: "328" },
  { Currency: "Lempira", AlphabeticCode: "HNL", NumericCode: "340" },
  { Currency: "Hong Kong Dollar", AlphabeticCode: "HKD", NumericCode: "344" },
  { Currency: "Forint", AlphabeticCode: "HUF", NumericCode: "348" },
  { Currency: "Gourde", AlphabeticCode: "HTG", NumericCode: "332" },
  { Currency: "Iceland Krona", AlphabeticCode: "ISK", NumericCode: "352" },
  { Currency: "Rupiah", AlphabeticCode: "IDR", NumericCode: "360" },
  { Currency: "Iranian Rial", AlphabeticCode: "IRR", NumericCode: "364" },
  { Currency: "Iraqi Dinar", AlphabeticCode: "IQD", NumericCode: "368" },
  { Currency: "New Israeli Sheqel", AlphabeticCode: "ILS", NumericCode: "376" },
  { Currency: "Jamaican Dollar", AlphabeticCode: "JMD", NumericCode: "388" },
  { Currency: "Yen", AlphabeticCode: "JPY", NumericCode: "392" },
  { Currency: "Jordanian Dinar", AlphabeticCode: "JOD", NumericCode: "400" },
  { Currency: "Pataca", AlphabeticCode: "MOP", NumericCode: "446" },
  { Currency: "Tenge", AlphabeticCode: "KZT", NumericCode: "398" },
  { Currency: "Kenyan Shilling", AlphabeticCode: "KES", NumericCode: "404" },
  { Currency: "Kuwaiti Dinar", AlphabeticCode: "KWD", NumericCode: "414" },
  { Currency: "Som", AlphabeticCode: "KGS", NumericCode: "417" },
  { Currency: "Pathet Lao Kip", AlphabeticCode: "LAJ", NumericCode: "418" },
  { Currency: "Lebanese Pound", AlphabeticCode: "LBP", NumericCode: "422" },
  { Currency: "Rand", AlphabeticCode: "ZAR", NumericCode: "710" },
  { Currency: "Liberian Dollar", AlphabeticCode: "LRD", NumericCode: "430" },
  { Currency: "Libyan Dinar", AlphabeticCode: "LYD", NumericCode: "434" },
  { Currency: "Swiss Franc", AlphabeticCode: "CHF", NumericCode: "756" },
  { Currency: "Denar", AlphabeticCode: "MKD", NumericCode: "807" },
  { Currency: "Malagasy Ariary", AlphabeticCode: "MGA", NumericCode: "969" },
  { Currency: "Malawi Kwacha", AlphabeticCode: "MWK", NumericCode: "454" },
  { Currency: "Malaysian Ringgit", AlphabeticCode: "MYR", NumericCode: "458" },
  { Currency: "Maldive Rupee", AlphabeticCode: "MVQ", NumericCode: "462" },
  { Currency: "Ouguiya", AlphabeticCode: "MRO", NumericCode: "478" },
  { Currency: "Mauritius Rupee", AlphabeticCode: "MUR", NumericCode: "480" },
  { Currency: "Mexican Peso", AlphabeticCode: "MXP", NumericCode: "484" },
  { Currency: "Moldovan Leu", AlphabeticCode: "MDL", NumericCode: "498" },
  { Currency: "Tugrik", AlphabeticCode: "MNT", NumericCode: "496" },
  { Currency: "Moroccan Dirham", AlphabeticCode: "MAD", NumericCode: "504" },
  { Currency: "Mozambique Metical", AlphabeticCode: "MZN", NumericCode: "943" },
  { Currency: "Kyat", AlphabeticCode: "BUK", NumericCode: "104" },
  { Currency: "Nepalese Rupee", AlphabeticCode: "NPR", NumericCode: "524" },
  { Currency: "Cordoba", AlphabeticCode: "NIC", NumericCode: "558" },
  { Currency: "Naira", AlphabeticCode: "NGN", NumericCode: "566" },
  { Currency: "North Korean Won", AlphabeticCode: "KPW", NumericCode: "408" },
  { Currency: "Norwegian Krone", AlphabeticCode: "NOK", NumericCode: "578" },
  { Currency: "Rial Omani", AlphabeticCode: "OMR", NumericCode: "512" },
  { Currency: "Pakistan Rupee", AlphabeticCode: "PKR", NumericCode: "586" },
  { Currency: "Kina", AlphabeticCode: "PGK", NumericCode: "598" },
  { Currency: "Guarani", AlphabeticCode: "PYG", NumericCode: "600" },
  { Currency: "Nuevo Sol", AlphabeticCode: "PEN", NumericCode: "604" },
  { Currency: "Philippine Peso", AlphabeticCode: "PHP", NumericCode: "608" },
  { Currency: "Zloty", AlphabeticCode: "PLN", NumericCode: "985" },
  { Currency: "Qatari Rial", AlphabeticCode: "QAR", NumericCode: "634" },
  { Currency: "New Romanian Leu", AlphabeticCode: "RON", NumericCode: "946" },
  { Currency: "Russian Ruble", AlphabeticCode: "RUB", NumericCode: "643" },
  { Currency: "Rwanda Franc", AlphabeticCode: "RWF", NumericCode: "646" },
  { Currency: "Tala", AlphabeticCode: "WST", NumericCode: "882" },
  { Currency: "Saudi Riyal", AlphabeticCode: "SAR", NumericCode: "682" },
  { Currency: "Serbian Dinar", AlphabeticCode: "RSD", NumericCode: "941" },
  { Currency: "Seychelles Rupee", AlphabeticCode: "SCR", NumericCode: "690" },
  { Currency: "Leone", AlphabeticCode: "SLL", NumericCode: "694" },
  { Currency: "Singapore Dollar", AlphabeticCode: "SGD", NumericCode: "702" },
  {
    Currency: "Solomon Islands Dollar",
    AlphabeticCode: "SBD",
    NumericCode: "90",
  },
  { Currency: "Somali Shilling", AlphabeticCode: "SOS", NumericCode: "706" },
  { Currency: "Won", AlphabeticCode: "KRW", NumericCode: "410" },
  {
    Currency: "South Sudanese Pound",
    AlphabeticCode: "SSP",
    NumericCode: "728",
  },
  { Currency: "Sri Lanka Rupee", AlphabeticCode: "LKR", NumericCode: "144" },
  { Currency: "Saint Helena Pound", AlphabeticCode: "SHP", NumericCode: "654" },
  { Currency: "Sudanese Pound", AlphabeticCode: "SDG", NumericCode: "938" },
  { Currency: "Surinam Dollar", AlphabeticCode: "SRD", NumericCode: "968" },
  { Currency: "Lilangeni", AlphabeticCode: "SZL", NumericCode: "748" },
  { Currency: "Swedish Krona", AlphabeticCode: "SEK", NumericCode: "752" },
  { Currency: "Syrian Pound", AlphabeticCode: "SYP", NumericCode: "760" },
  { Currency: "Dobra", AlphabeticCode: "STD", NumericCode: "678" },
  { Currency: "Somoni", AlphabeticCode: "TJS", NumericCode: "972" },
  { Currency: "Tanzanian Shilling", AlphabeticCode: "TZS", NumericCode: "834" },
  { Currency: "Baht", AlphabeticCode: "THB", NumericCode: "764" },
  { Currency: "Pa'anga", AlphabeticCode: "TOP", NumericCode: "776" },
  {
    Currency: "Trinidad and Tobago Dollar",
    AlphabeticCode: "TTD",
    NumericCode: "780",
  },
  { Currency: "Tunisian Dinar", AlphabeticCode: "TND", NumericCode: "788" },
  { Currency: "New Turkish Lira", AlphabeticCode: "TRY", NumericCode: "949" },
  {
    Currency: "Turkmenistan New Manat",
    AlphabeticCode: "TMT",
    NumericCode: "934",
  },
  { Currency: "Uganda Shilling", AlphabeticCode: "UGS", NumericCode: "800" },
  { Currency: "Hryvnia", AlphabeticCode: "UAH", NumericCode: "980" },
  { Currency: "UAE Dirham", AlphabeticCode: "AED", NumericCode: "784" },
  { Currency: "Uruguayan Peso", AlphabeticCode: "UYP", NumericCode: "858" },
  { Currency: "Uzbekistan Sum", AlphabeticCode: "UZS", NumericCode: "860" },
  { Currency: "Vatu", AlphabeticCode: "VUV", NumericCode: "548" },
  { Currency: "Bolivar", AlphabeticCode: "VEF", NumericCode: "937" },
  { Currency: "Dong", AlphabeticCode: "VND", NumericCode: "704" },
  { Currency: "Yemeni Rial", AlphabeticCode: "YER", NumericCode: "886" },
  { Currency: "Zambian Kwacha", AlphabeticCode: "ZMW", NumericCode: "967" },
  { Currency: "Zimbabwe Dollar", AlphabeticCode: "ZWL", NumericCode: "932" },
  { Currency: "Namibia Dollar", AlphabeticCode: "NAD", NumericCode: "516" },
  { Currency: "Bolívar Soberano", AlphabeticCode: "VES", NumericCode: "928" },
  { Currency: "Belarusian Ruble", AlphabeticCode: "BYN", NumericCode: "933" },
];