import { useState, useEffect } from "react";
import { CarrierIssuerAddress } from "../../../../models/enum/CarrierIssuerAddress";
import { IssuerStatementOptions } from "../../../../models/enum/CarrierIssuerStatement";
import { TCarrier } from "../../../../models/ICardVendor";

const Carrier = ({ carrierData, handleChanges }:  {carrierData:any, handleChanges:any}) => {
  const [carrier, setCarrier] = useState<TCarrier>(carrierData);  

  useEffect(() => {setCarrier(carrierData)}, [carrierData])  

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>) => {
      const { id, value, type} = e.currentTarget         
      handleChanges(id, value, type )      
  };
  
  const mapIssuerAddress = (id: string) => {
    if (!id) return "";
    return CarrierIssuerAddress.find(s => s.id === Number(id))?.name
  }
  
  const mapIssuerStatement = (id: string) => {
    if (!id) return "";
    return IssuerStatementOptions.find(s => s.id === Number(id))?.name
  }

  return (
    <div className="card-body">
      <div className="row">
        <div className="col-md-12">
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Issuer Address</label>
            <div className="col-sm-8">              
              <select
                title={mapIssuerAddress(carrier?.issuerAddress || "")}
                id="issuerAddress"
                name="issuerAddress"
                className="form-control form-control-sm overflow-ellipsis"
                value={carrier?.issuerAddress || ""}
                onChange={handleChange}
                required
              >
                <option value={""}>Select Issuer Address</option>
                {CarrierIssuerAddress.map((item: any) => (
                  <option key={item.id} value={item.id}>
                    {item.id} - {item.name}
                  </option>
                ))}
              </select>
            </div>            
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Issuer Statement</label>
            <div className="col-sm-8">             
              <select 
                title={mapIssuerStatement(carrier?.issuerStatement || "")}
                id="issuerStatement"
                name="issuerStatement"
                className="form-control form-control-sm overflow-ellipsis"
                value={carrier?.issuerStatement || ""}
                onChange={handleChange}
                
              >
                <option value={""} className="overflow-ellipsis">Select Issuer Statement</option>
                {IssuerStatementOptions.map((item: any) => (
                  <option key={item.id} value={item.id} title={item.name}>
                    {item.id} - {item.display}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Contact Number</label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="contactNumber"
                name="contactNumber"
                value={carrier?.contactNumber || ""} 
                onChange={handleChange}               
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-4 col-form-label">CCP Url</label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="ccpUrl"
                name="ccpUrl"
                value={carrier?.ccpUrl || ""} 
                onChange={handleChange}               
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carrier;
