import { Routes, Route, Navigate } from "react-router-dom"
import Accounts from "./pages/secured/accounts/Accounts"
import CardHolders from "./pages/secured/cardholders/CardHolders"
import CardMgt from "./pages/secured/cardmgt/CardMgt"
import Dashboard from "./pages/secured/dashboard/Dashboard"
import DropOff from "./pages/secured/dropoff/DropOff"
import ForexMgt from "./pages/secured/forexmgt/ForexMgt"
import NotFound from "./pages/errors/NotFound"
import Setup from "./pages/secured/setup/Setup"
import SetupDetails from "./pages/secured/setup/SetupDetails"
import SubSetup from "./pages/secured/sub-setup/SubSetup"
import TransactionMgt from "./pages/secured/transactions/TransactionMgt"
import SetupAddNew from "./pages/secured/setup/SetupAddNew"
import AccountDetails from "./pages/secured/accounts/AccountDetails"
import UpdateFx from "./pages/secured/forexmgt/UpdateFx"
import CardVendor from "./pages/secured/setup/cardvendors/CardVendor"

const AppRoutes = () => {
    return (        
        <Routes>
            <Route path='/' element={<Dashboard />}/>
            <Route path='/setup' element={<Setup />} />
            <Route path='/setup/:issuerId/new' element={<SetupAddNew />}  />
            <Route path='/setup/:issuerId/:setupId' element={<SetupDetails />} />
            <Route path='/setup/:issuerId/:setupId/vendors/:vendorSetupId' element={<CardVendor />} />
            <Route path='/setup/:issuerId/:setupId/vendors/create' element={<CardVendor />} />
            <Route path='/card-management' element={<CardMgt />}  />
            <Route path='/drop-off' element={<DropOff />}  />
            <Route path='/transaction-mgt' element={<TransactionMgt />}  />
            <Route path='/accounts' element={<Accounts />}  />
            <Route path='/accounts/:id' element={<AccountDetails />}  />
            <Route path='/forext-mgt' element={<ForexMgt />}  />
            <Route path='/forext-mgt/:source/:buying' element={<UpdateFx />}  />
            <Route path='/card-holders' element={<CardHolders />}  />
            <Route path='/sub-setup' element={<SubSetup />}  />            
            <Route path='/not-found' element={<NotFound />} />
            <Route path="/login" element={<Navigate to='/' replace />} />
            <Route path="*" element={<Navigate to='/not-found' replace />} />
        </Routes>        
    )
}

export default AppRoutes
