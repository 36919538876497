import React from "react";
import { Modal } from "react-bootstrap";
import "./Popup.css";

export interface IProps {
  title: string;
  openPopup: boolean;
  setOpenPopup: any;
  children: React.ReactNode;
  size?: "sm" | "lg" | "xl";
}

const Popup = (props: IProps) => {
  const { title, openPopup, setOpenPopup, children, size } = props;

  return (
    <Modal
      show={openPopup}
      onHide={() => setOpenPopup(false)}
      backdrop="static"
      size={size}
      className="center"
    >
      <Modal.Header closeButton>
        <Modal.Title className="group-field-label">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default Popup;
