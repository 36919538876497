import { useState,useEffect,useContext } from "react";
import Popup from "../../../../components/dialog/Popup";
import { HelpDescriptions } from "../../../../models/enum/helpDescriptions";
import GlobalLimitTableRow from "./GlobalLimitTableRow";
import UpdateGlobalLimit from "./UpdateGlobalLimit";
import UnauthorizedPage from "../../../errors/UnauthorizedPage";
import SecurityClassificationError from "../../../errors/SecurityClassification";


const GlobalLimitTable = (props: any) => {
    const { data, title, subtitle, issuerId, setupId, currencyCode, customerType, isExpanded,securityClassifications,canPageBeRendered,proccedAndLoad} = props;
    const [openPopup, setOpenPopup] = useState(false);
    const [periodicLimit, setPeriodicLimit] = useState('');

    const readAccess = securityClassifications?.includes("Read");
    const createAccess = securityClassifications?.includes("Create");
    const updateAccess = securityClassifications?.includes("Update");

    if(!proccedAndLoad || !readAccess)
    {
        return <UnauthorizedPage withWrapper={false} />;
    }

  return (
    <div className={`p-0 ${isExpanded ? '' : 'collapse'}`}>
        <table className="table table-hover table-bordered table-sm">
            <thead className="alert alert-success">
                <tr>
                    <th></th>                    
                    <th className="text-right">SingleTrn</th>
                    <th className="text-right">Daily</th>
                    <th className="text-right">Monthly</th>
                    <th className="text-right">Yearly</th>
                    <th className="text-right">Lifetime</th>
                </tr>
            </thead>

            {
                data !== null &&
                <tbody>
                        {
                            updateAccess && <GlobalLimitTableRow data={data.domestic} type="Domestic" setOpenPopup={setOpenPopup} setPeriodicLimit={setPeriodicLimit} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}/>
                        }
                        {
                            updateAccess && <GlobalLimitTableRow data={data.intranational} type="Intranational" setOpenPopup={setOpenPopup} setPeriodicLimit={setPeriodicLimit} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}/>
                        }
                        {
                            updateAccess && <GlobalLimitTableRow data={data.international} type="International" setOpenPopup={setOpenPopup} setPeriodicLimit={setPeriodicLimit} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}/>
                        }
                        {
                            updateAccess && <GlobalLimitTableRow data={data.globally} type="Globally" setOpenPopup={setOpenPopup} setPeriodicLimit={setPeriodicLimit} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}/>
                        }
                </tbody>
            }
        </table>
        
        {
            updateAccess && <Popup title={`${title} || ${subtitle} || ${periodicLimit}`} openPopup={openPopup} setOpenPopup={setOpenPopup}>
            <div className="description">{HelpDescriptions.CURRENCY_VALUE_LIMTS}</div><br/>
                <UpdateGlobalLimit
                    data={data[periodicLimit.toLowerCase()]}
                    issuerId={issuerId}
                    setupId={setupId} 
                    currencyCode={currencyCode}
                    customerType={customerType}
                    periodicLimit={periodicLimit}
                    messageReference={`${title} || ${subtitle} || ${periodicLimit}`}
                    securityClassifications={securityClassifications} 
                    canPageBeRendered={canPageBeRendered} 
                    proccedAndLoad={proccedAndLoad}                
                    setOpenPopup={setOpenPopup}/>
            </Popup>
        }
    </div>
)
}

export default GlobalLimitTable;
