import React, { Fragment } from "react";
import PeriodicFee from "./PeriodicFee";

const RegionalFee = (props: any) => {
  const {
    title,
    data,
    setupId,
    issuerId,
    customerType,
    currencyCode,
    feesType,
    securityClassifications,
    canPageBeRendered,
    proccedAndLoad 
  } = props;
  return (
    <Fragment>
      <tr>
        <td colSpan={4}>{title}</td>
      </tr>
      <PeriodicFee
        title="Domestic"
        data={data === null ? null : data.domestic}
        isParent={true}
        customerType={customerType}
        issuerId={issuerId}
        setupId={setupId}
        currencyCode={currencyCode}
        feesType={feesType}
        subType={"domestic"}
        securityClassifications={securityClassifications} 
        canPageBeRendered={canPageBeRendered} 
        proccedAndLoad={proccedAndLoad} 
      />
      <PeriodicFee
        customerType={customerType}
        issuerId={issuerId}
        setupId={setupId}
        currencyCode={currencyCode}
        feesType={feesType}
        subType={"intranational"}
        title="Intranational"
        data={data === null ? null : data.intranational}
        isParent={true}
        securityClassifications={securityClassifications} 
        canPageBeRendered={canPageBeRendered} 
        proccedAndLoad={proccedAndLoad} 
      />
      <PeriodicFee
        customerType={customerType}
        issuerId={issuerId}
        setupId={setupId}
        currencyCode={currencyCode}
        feesType={feesType}
        subType={"international"}
        title="International"
        data={data === null ? null : data.international}
        isParent={true}
        securityClassifications={securityClassifications} 
        canPageBeRendered={canPageBeRendered} 
        proccedAndLoad={proccedAndLoad} 
      />
    </Fragment>
  );
};

export default RegionalFee;
