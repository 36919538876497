import React, { SyntheticEvent, useContext, useState } from 'react'
import { toast } from 'react-toastify';
import SetupContext from '../../../../context/setup/SetupContext';
import { GetCurrencies } from '../../../../models/enum/currencies'
import { TPanConfig } from '../../../../models/TPanConfig';
import SecurityClassificationError from "../../../errors/SecurityClassification";

const initPanConfig: TPanConfig = {
    bin: "",
    subBin: "",
    length: 0,
    defaultCurrency: "",
    monthsToExpire: 0
}

const panLength = [16, 17, 18, 19];

const AddPanConfig = (props: any) => {

    const {setOpenPopup, issuerId, setupId,securityClassifications,canPageBeRendered,proccedAndLoad  } = props;
    const {addPanConfig } = useContext(SetupContext);
    const [panConfig, setPanConfig] = useState(props.item);

    const createAccess = securityClassifications?.includes("Create");

    const onChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        setPanConfig({ ...panConfig, [e.target.name]: e.target.value })
    }

    const onSubmit = (e: SyntheticEvent) => {
        e.preventDefault();
        if (panConfig.bin.length !== 6)
            toast.error(<div><i className="fas fa-times mr-2"></i>Bin length must be equal to 6 digits.</div>);
        else {
            addPanConfig(issuerId, setupId, panConfig);
            setOpenPopup(false);
        }
    }

    if(!proccedAndLoad || !createAccess)
    {
      return (<SecurityClassificationError title="Create Classification Denied" pageName="Pan Configuration" extraReference="add pan configuration" withWrapper={false}/>)
    }

    return (
        <form className="row" onSubmit={onSubmit}>
            <div className="col-sm-12">
                <div className="form-group row">
                    <label className="col-sm-4 col-form-label required-field">BIN</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="BIN"
                            value= {panConfig.bin}
                            name="bin"
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            autoComplete="off"
                            maxLength={6}
                            onChange={onChange}
                            required />
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-sm-4 col-form-label required-field">Sub BIN</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Sub BIN"
                            value= {panConfig.subBin}
                            name="subBin"
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            autoComplete="off"
                            onChange={onChange}
                            maxLength={4}
                            required />
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-sm-4 col-form-label required-field">PAN Length</label>
                    <div className="col-sm-8">
                        <select
                            required
                            name="length"
                            value= {panConfig.length}
                            className="form-control"                            
                            onChange={(e: any) => setPanConfig({ ...panConfig, length: parseInt(e.target.value) })}
                        >
                            <option value=''>Select PAN Length</option>
                            {
                                panLength.map((item: any, idx: number) => (
                                    <option key={idx} value={item}>{item}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-sm-4 col-form-label required-field">Currency</label>
                    <div className="col-sm-8">
                        <select
                            required
                            name="defaultCurrency"
                            value= {panConfig.defaultCurrency}
                            className="form-control"
                            onChange={(e: any) => onChange(e)}
                        >
                            <option value=''>Select Currency</option>
                            {
                                GetCurrencies().map((item: any) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-sm-4 col-form-label required-field">Months to Expire</label>
                    <div className="col-sm-8">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Months to Expire"
                        value= {panConfig.monthsToExpire}
                        name="monthsToExpire"
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            }
                        }}
                        autoComplete="off"
                        onChange={onChange}
                        maxLength={3}
                        required
                        />
                    </div>
                </div>
                <hr />
                <div className="form-group row">
                    <div className="float-right">
                        <button type="button" className="btn-secondary btn-sm m-1" onClick={() => setOpenPopup(false)}>
                            Cancel
                        </button >
                        <button type="reset" className="btn-warning btn-sm m-1" onClick={() => setPanConfig(initPanConfig)}>
                            Clear
                        </button>
                        {
                        createAccess && <button type="submit" className="btn-success btn-sm m-1">
                            Save
                        </button >
                        }
                    </div>

                </div>
            </div>
        </form>
    )
}

export default AddPanConfig
