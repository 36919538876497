export interface ICardVendor {
    cardVendor: TCardVendor
}

export const initCardVendor = {
    vendorSetupId: "",
    activeVendor: "",
    validFrom: false,
    fourthLineOverride: false,
    productInformation: {
        productCode: "",
        imageCode: null,
        logoCode: null,
        templateCode: null,
        persoTypeCode: null,
        language: "",
        cardReverse: {
            barCode: false,
            cardholderIdPosition: null,
            contactDetail: null,
            issuerStatement: null,
            barCodePropertyPosition: null,
            barCodeProperty: null,
        },
        carrier: {
            issuerAddress: null,
            issuerStatement: null,
            contactNumber: null,
            ccpUrl: null,
        },
        instantIssuePacks: {
            instantIssuePack: false,
            packInsert: false,
            dynamicBarcode: false,
            retailBarcode: false,
            retailBarcodeText: null,
            expiryOnPack: false,
        },
    },
    deliveryDetails: {
        method: null,
        bulkDelivery: false,
        bulk: {
            line1: null,
            line2: null,
            line3: null,
            city: null,
            postCode: null,
            country: null,
            contactName: null,
            contactNumber: null,
            bulkDeliveryCode: null,
        }
    },
    cronJob: null
};

export type TCardVendorKey = {
    vendorSetupId: string;
    activeVendor: string;
}

export type TCardVendor = {
    vendorSetupId: string | null;
    activeVendor: string | null;
    validFrom: boolean;
    fourthLineOverride: boolean;
    productInformation: TProductInformation;
    deliveryDetails: TDeliveryDetails;
    cronJob: string | null;
}

export type TProductInformation = {
    productCode: string | null;
    imageCode: string | null;
    logoCode: string | null;
    templateCode: string | null;
    persoTypeCode: string | null;
    language: string | null;
    cardReverse: TCardReverse;
    carrier: TCarrier;
    instantIssuePacks: TInstantIssuePacks;
}

export type TCardReverse = {
    barCode: boolean;
    cardholderIdPosition: string | null;
    contactDetail: string | null;
    issuerStatement: string | null;
    barCodePropertyPosition: string | null;
    barCodeProperty:string | null;
}

export type TCarrier = {
    issuerAddress: string | null;
    issuerStatement: string | null;
    contactNumber: string | null;
    ccpUrl: string | null;
}

export type TInstantIssuePacks = {
    instantIssuePack: boolean;
    packInsert: boolean;
    dynamicBarcode: boolean;
    retailBarcode: boolean;
    retailBarcodeText: string | null;
    expiryOnPack: boolean;
}

export type TDeliveryDetails = {
    method: string | null;
    bulkDelivery: boolean;
    bulk: TBulk;
}

export type TBulk = {
    line1: string | null;
    line2: string | null;
    line3: string | null;
    city: string | null;
    postCode: string | null;
    country: string | null;
    contactName: string | null;
    contactNumber: string | null;
    bulkDeliveryCode: string | null;
}