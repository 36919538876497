import React from 'react'
import MccLimitTableRow from './MccLimitTableRow';
import Spinner from '../../../../components/spinner/Spinner';
import UnauthorizedPage from "../../../errors/UnauthorizedPage";
import SecurityClassificationError from "../../../errors/SecurityClassification";

const MccLimit = (props: any) => {

    const { data, customerType, issuerId, setupId, currencyCode, isExpanded,securityClassifications,canPageBeRendered,proccedAndLoad } = props;

    const readAccess = securityClassifications?.includes("Read");
    const createAccess = securityClassifications?.includes("Create");
    const updateAccess = securityClassifications?.includes("Update");

    if(!proccedAndLoad || !readAccess)
    {
        return <UnauthorizedPage withWrapper={false} />;
    }

    return (
      <div className={`p-0 ${isExpanded ? '' : 'collapse'}`}>
        <table className="table table-hover table-bordered table-sm">
          <thead className="alert alert-success">
            <tr>
              <th className="text-center">MCCs</th>
              <th className="text-right">Drop-off Period</th>
              <th className="text-right">SingleTrn</th>
              <th className="text-right">Daily</th>
              <th className="text-right">Monthly</th>
              <th className="text-right">Yearly</th>
            </tr>
          </thead>
          <tbody>
            {
              <MccLimitTableRow
                data={data}
                issuerId={issuerId}
                setupId={setupId}
                customerType={customerType}
                currencyCode={currencyCode}
                securityClassifications={securityClassifications} 
                canPageBeRendered={canPageBeRendered} 
                proccedAndLoad={proccedAndLoad}
              />
            }
          </tbody>
        </table>
      </div>
    );
}

export default MccLimit
