import { Fragment } from 'react'
import LoadTable from './LoadLimitTable'
import RegionalLimit from './RegionalLimit'
import MccLimit from './MccLimit'
import GlobalLimitTable from './GlobalLimitTable'

const LimitsTree = (props: any) => {
    const { title, data, issuerId, setupId, currencyCode, customerType, isExpanded,securityClassifications,canPageBeRendered,proccedAndLoad } = props;

    return (
        <Fragment>
            <tr data-widget={isExpanded ? '' : "expandable-table"} aria-expanded={isExpanded ? "true" : "false"}>
                <td>
                    {isExpanded ? null : <i className="fas fa-caret-right fa-fw"/>}
                    {title}
                </td>
            </tr>
            <tr className="expandable-body">
                <td>
                    <div className={`p-0 ${isExpanded ? '' : 'collapse'}`}>
                        <table className="table table-sm">
                            <tbody>
                                <tr data-widget={isExpanded ? '' : "expandable-table"}  aria-expanded={isExpanded ? "true" : "false"}>
                                    <td>
                                        {isExpanded ? null : <i className="fas fa-caret-right fa-fw"/>}
                                            Load
                                    </td>
                                </tr>
                                <tr className="expandable-body">
                                    <td>
                                        <div className={`p-0 ${isExpanded ? '' : 'collapse'}`}>
                                            <LoadTable
                                                data={data.load}
                                                title={title}
                                                subtitle={'Load'}
                                                issuerId={issuerId}
                                                setupId={setupId}
                                                currencyCode={currencyCode}
                                                customerType={customerType}
                                                securityClassifications={securityClassifications} 
                                                canPageBeRendered={canPageBeRendered} 
                                                proccedAndLoad={proccedAndLoad}
                                                />
                                        </div>
                                    </td>
                                </tr>

                                <tr data-widget={isExpanded ? '' : "expandable-table"}  aria-expanded={isExpanded ? "true" : "false"}>
                                    <td>
                                        {isExpanded ? null : <i className="fas fa-caret-right fa-fw"/>}
                                            ATM
                                    </td>
                                </tr>
                                <tr className="expandable-body">
                                    <td>
                                        <RegionalLimit
                                            data={data.atm}
                                            title={title}
                                            subtitle={'ATM'}
                                            issuerId={issuerId}
                                            setupId={setupId}
                                            currencyCode={currencyCode}
                                            customerType={customerType}
                                            isExpanded={isExpanded}
                                            securityClassifications={securityClassifications} 
                                            canPageBeRendered={canPageBeRendered} 
                                            proccedAndLoad={proccedAndLoad}
                                         />
                                    </td>
                                </tr>

                                <tr data-widget={isExpanded ? '' : "expandable-table"}  aria-expanded={isExpanded ? "true" : "false"}>
                                    <td>
                                        {isExpanded ? null : <i className="fas fa-caret-right fa-fw"/>}
                                            POS
                                    </td>
                                </tr>
                                <tr className="expandable-body">
                                    <td>
                                        <RegionalLimit 
                                            data={data.pos}
                                            title={title}
                                            subtitle={'POS'}
                                            issuerId={issuerId}
                                            setupId={setupId}
                                            currencyCode={currencyCode}
                                            customerType={customerType}
                                            isExpanded={isExpanded}
                                            securityClassifications={securityClassifications} 
                                            canPageBeRendered={canPageBeRendered} 
                                            proccedAndLoad={proccedAndLoad}
                                        />
                                    </td>
                                </tr>

                                <tr data-widget={isExpanded ? '' : "expandable-table"}  aria-expanded={isExpanded ? "true" : "false"}>
                                    <td>
                                        {isExpanded ? null : <i className="fas fa-caret-right fa-fw"/>}
                                            E-Commerce
                                    </td>
                                </tr>
                                <tr className="expandable-body">
                                    <td>
                                        <RegionalLimit 
                                            data={data.eCommerce}
                                            title={title}
                                            subtitle={'E-Commerce'}
                                            issuerId={issuerId}
                                            setupId={setupId}
                                            currencyCode={currencyCode}
                                            customerType={customerType}
                                            isExpanded={isExpanded}
                                            securityClassifications={securityClassifications} 
                                            canPageBeRendered={canPageBeRendered} 
                                            proccedAndLoad={proccedAndLoad}
                                         />
                                    </td>
                                </tr>

                                <tr data-widget={isExpanded ? '' : "expandable-table"}  aria-expanded={isExpanded ? "true" : "false"}>
                                    <td>
                                        {isExpanded ? null : <i className="fas fa-caret-right fa-fw"/>}
                                            MOTO
                                        </td>
                                </tr>
                                <tr className="expandable-body">
                                    <td>
                                        <RegionalLimit 
                                            data={data.moto}
                                            title={title}
                                            subtitle={'MOTO'}
                                            issuerId={issuerId}
                                            setupId={setupId}
                                            currencyCode={currencyCode}
                                            customerType={customerType}
                                            isExpanded={isExpanded}
                                            securityClassifications={securityClassifications} 
                                            canPageBeRendered={canPageBeRendered} 
                                            proccedAndLoad={proccedAndLoad} 
                                         />
                                    </td>
                                </tr>

                                <tr data-widget={isExpanded ? '' : "expandable-table"}  aria-expanded={isExpanded ? "true" : "false"}>
                                    <td>
                                        {isExpanded ? null : <i className="fas fa-caret-right fa-fw"/>}
                                            Global
                                        </td>
                                </tr>
                                <tr className="expandable-body">
                                    <td>
                                        <GlobalLimitTable 
                                            data={data.global}
                                            title={title}
                                            subtitle={'Global'}
                                            issuerId={issuerId}
                                            setupId={setupId}
                                            currencyCode={currencyCode}
                                            customerType={customerType}
                                            isExpanded={isExpanded}
                                            securityClassifications={securityClassifications} 
                                            canPageBeRendered={canPageBeRendered} 
                                            proccedAndLoad={proccedAndLoad}
                                         />
                                    </td>
                                </tr>

                                <tr data-widget={isExpanded ? '' : "expandable-table"}  aria-expanded={isExpanded ? "true" : "false"}>
                                    <td>
                                        {isExpanded ? null : <i className="fas fa-caret-right fa-fw"/>}
                                            MCC
                                        </td>
                                </tr>
                                <tr className="expandable-body">
                                    <td>
                                        <MccLimit
                                            data={data.mcc}
                                            issuerId={issuerId}
                                            setupId={setupId}
                                            customerType={customerType}
                                            currencyCode={currencyCode}
                                            isExpanded={isExpanded}
                                            securityClassifications={securityClassifications} 
                                            canPageBeRendered={canPageBeRendered} 
                                            proccedAndLoad={proccedAndLoad}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </td>
            </tr>
        </Fragment>
    )
}

export default LimitsTree
