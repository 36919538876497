import { AccountsInitState } from "../../models/IAccounts";
import { CLEAR_ERRORS, GET_ACCOUNT, GET_ACCOUNTS } from "../types";

const AccountsReducer = (state: AccountsInitState, action: any) => {
  switch (action.type) {
    case GET_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
        totalRows: action.payload.paging.totalRecordCount,
        loading: false,
        pageSize: action.payload.paging.pageSize,
        pageNo: action.payload.paging.pageNo,
      };

    case GET_ACCOUNT:
      return {
        ...state,
        accountDetails: action.payload,
        loading: false
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export default AccountsReducer;
