import React, { useContext, useState} from 'react'
import Popup from '../../../../components/dialog/Popup';
import Spinner from '../../../../components/spinner/Spinner';
import SetupContext from '../../../../context/setup/SetupContext';
import { PANConfigsColumns } from '../../../../models/enum/columns';
import AddPanConfig from './AddPanConfig';
import UnauthorizedPage from "../../../errors/UnauthorizedPage";
import SecurityClassificationError from "../../../errors/SecurityClassification";



const PANConfig = (props: any) => {

    const initPanConfig = {
        bin: "",
        subBin: 0,
        length: 0,
        defaultCurrency: "",
        monthsToExpire: ""

    };
    const { issuerId, setupId,securityClassifications,canPageBeRendered,proccedAndLoad } = props;
    const [openPopup, setOpenPopup] = useState(false);
        
    const { panConfigs, loading} = useContext(SetupContext);

    const [panConfigDetails, setPanConfigDetails] = useState(initPanConfig);
   

    const readAccess = securityClassifications?.includes("Read");
    const createAccess = securityClassifications?.includes("Create");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const updateAccess = securityClassifications?.includes("Update");

    if(!canPageBeRendered)
    {
        return (<div className="card-body" >< Spinner /> </div>)
    }

    if(!proccedAndLoad)
    {
        return <UnauthorizedPage withWrapper={false} />;
    }

    if(!readAccess){
        return (<SecurityClassificationError title="Read Classification Denied" pageName="Pan Configuration" extraReference='Pan Configuration' withWrapper={false}/>)
    }
    
    function onEditPanConfigClicked(item : any) {
        setPanConfigDetails( {
            bin: item.bin,
            subBin: item.subBin,
            length: item.panLength,
            defaultCurrency: item.currencyName,
            monthsToExpire: item.monthsToExpire
           
        });
        setOpenPopup(true);
    }

    function onNewPanConfigClicked(item : any) {
        setPanConfigDetails(initPanConfig);
        setOpenPopup(true);
    }

    return (
        !loading ?
            <div>
                <span>
                        {
                            createAccess && <button type="button" className="btn btn-round btn-success btn-sm mb-1" onClick={onNewPanConfigClicked}>
                                <i className="fa fa-plus p-1" />
                                <span>Add New Configuration</span>
                            </button>
                        }
                    </span>

                    <div className="space-4"></div>

                    <table id="table-data" className="table table-bordered table-hover table-sm" role="grid">
                        <thead className="table-header">
                            <tr role="row">
                            <th></th>
                                {
                                    PANConfigsColumns.map((c: any) => (
                                        <th key={c.id}>{c.name}</th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {

                                panConfigs.length > 0 ? (
                                    panConfigs.map(
                                        (item: any) => (
                                            <tr key={item.bin + item.subBin}>
                                                <td>
                                                    <a href="#!">
                                                        <i className="fas fa-edit text-warning" onClick={() => onEditPanConfigClicked(item)} />
                                                    </a>
                                                </td>
                                                <td>{item.bin}</td>
                                                <td>{item.subBin}</td>
                                                <td>{item.panLength}</td>
                                                <td>{item.currencyName}</td>
                                                <td>{item.monthsToExpire}</td>
                                                <td>{item.created}</td>
                                            </tr>
                                        )
                                    )
                                ) : (
                                    <tr><td className="text-center text-danger" colSpan={5}>No data available in table</td></tr>
                                )
                            }
                        </tbody>
                    </table>
                    {
                    createAccess && <Popup title="Add PAN Configuration" openPopup={openPopup} setOpenPopup={setOpenPopup} >                  
                        <AddPanConfig item={panConfigDetails} setOpenPopup={setOpenPopup} issuerId={issuerId} setupId={setupId} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />
                    </Popup>
                    }
            </div>
            : <Spinner />
    )
}

export default PANConfig
