import React, { useContext,useEffect } from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import axios from 'axios';
import { loginRequest } from './authConfig';
import  RoleBasedAccessControlContext  from "../src/context/rolebasedaccesscontrol/RoleBasedAccessControlContext"

interface RequestInterceptorProps {
    children: JSX.Element,
}

const RequestInterceptor: React.FC<RequestInterceptorProps> = ({ children }: RequestInterceptorProps) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const { areConfigurationsSet,synchRoleBasedAccessControl }  = useContext(RoleBasedAccessControlContext); 
 
  /* eslint-disable no-param-reassign */
  axios.interceptors.request.use(async (config) => {
    if (!account) {
      throw Error('No active account! Verify a user has been signed in.');
    }

    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      account,
    });

    const bearer = `Bearer ${response.accessToken}`;
    config.headers.Authorization = bearer;
 
    return config;
  });
  /* eslint-enable no-param-reassign */

  const refreshToken = async () => {
    try {
      const account = accounts[0];
      
      if (!account) {
        throw Error('No active account! Verify a user has been signed in.');
      }
      
      await instance.acquireTokenSilent({
        ...loginRequest,
        account,
      });
    } catch (error) {
      // handle error
    }
  };

  useEffect(() => { 
    refreshToken();
    synchRoleBasedAccessControl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[areConfigurationsSet]);

  if(areConfigurationsSet as boolean === true){
    return (
      <>
        {children}
      </>
    );
  }
  else
  {
    return (
      <>
        {}
      </>
    );
   }
};

export default RequestInterceptor;