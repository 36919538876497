import React, { SyntheticEvent, useContext, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Switch from "../../../components/switch";
import RoleBasedAccessControlContext from "../../../context/rolebasedaccesscontrol/RoleBasedAccessControlContext";
import SetupContext from "../../../context/setup/SetupContext";
import { BalanceMode } from "../../../models/enum/BalanceMode";
import { LegalEntity } from "../../../models/enum/LegalEntity";
import { GetCountries } from "../../../models/enum/countries";
import { NegativeFeeMode } from "../../../models/enum/NegativeFeeMode";
import { initSetup } from "../../../models/Setup";
import Wrapper from "../../Wrapper";
import Spinner from "../../../components/spinner/Spinner";
import UnauthorizedPage from "../../errors/UnauthorizedPage";
import SecurityClassificationError from "../../errors/SecurityClassification";

const SetupAddNew: React.FC = () => {
  const {
    securityClassifications,
    getSecurityClassification,
    retrievePagePermission,
  } = useContext(RoleBasedAccessControlContext);
  const [proccedAndLoad, setUserAccess] = useState<boolean>(false);
  const [canPageBeRendered, canInvokePageContent] = useState(false);

  useEffect(() => {
    retrievePagePermission("setup")
      .then((isGranted: boolean) => {
        canInvokePageContent(true);
        setUserAccess(isGranted);
        // Do something with menuItems
      })
      .catch((error: any) => {
        canInvokePageContent(false);
        setUserAccess(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSecurityClassification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { issuerId } = useParams();

  const [values, setValues] = useState(initSetup);
  const { assignNewSetupToIssuer } = useContext(SetupContext);

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    assignNewSetupToIssuer(issuerId, values);
  };

  const isFormValid =
    values.setupId &&
    values.setupName &&
    values.setupCountry &&
    values.balanceMode > -1 &&
    values.legalEntity > -1 &&
    values.negativeFeeMode > -1;

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLSelectElement>
  ) => {
    setValues({ ...values, [e.currentTarget.name]: e.currentTarget.value });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const readAccess = securityClassifications?.includes("Read");
  const createAccess = securityClassifications?.includes("Create");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateAccess = securityClassifications?.includes("Update");

  if (!canPageBeRendered) {
    return (
      <Wrapper title="Setup Management" subTitle="Add New Setup">
        {" "}
        <Spinner />{" "}
      </Wrapper>
    );
  }

  if (!proccedAndLoad) {
    return <UnauthorizedPage />;
  }

  if (!createAccess) {
    return (
      <SecurityClassificationError
        title="Create Classification Denied"
        pageName="Add New Setup"
        extraReference="Add New Setup"
        withWrapper={true}
      />
    );
  }

  return (
    <Wrapper title="Setup Management" subTitle="Add New Setup">
      <div className="card-body">
        <h4 className="group-field-label pb-4">
          Programme Core Set Up Details
        </h4>

        <form className="row" onSubmit={onSubmit}>
          <div className="col-md-6">
            <div className="form-group row">
              <label className="col-sm-3 col-form-label">Issuer Id</label>
              <div className="col-sm-8">
                <input
                  type="input"
                  className="form-control"
                  name="issuerId"
                  value={issuerId}
                  disabled
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label required-field">
                Setup Id
              </label>
              <div className="col-sm-8">
                <input
                  type="input"
                  className="form-control"
                  id="setupId"
                  placeholder="Setup Id"
                  name="setupId"
                  onChange={onChange}
                  required
                  autoFocus
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-3 col-form-label required-field">
                Programme Name
              </label>
              <div className="col-sm-8">
                <input
                  type="input"
                  className="form-control"
                  id="setupName"
                  placeholder="Programme Setup Name"
                  name="setupName"
                  onChange={onChange}
                  required
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-3 col-form-label required-field">
                Country
              </label>
              <div className="col-sm-8">
                <select
                  name="setupCountry"
                  id="setupCountry"
                  className="form-control"
                  value={values.setupCountry}
                  onChange={(e: any) => onChange(e)}
                  required
                >
                  <option value={""}>Select Country</option>
                  {GetCountries().map((item: any) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-3 col-form-label required-field">
                Balance Mode
              </label>
              <div className="col-sm-8">
                <select
                  name="balanceMode"
                  className="form-control"
                  onChange={(e) =>
                    setValues({
                      ...values,
                      balanceMode: parseInt(e.currentTarget.value),
                    })
                  }
                  required
                >
                  <option value={""}>Select Balance Mode</option>
                  {BalanceMode.map((item: any) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-3 col-form-label required-field">
                Legal Entity
              </label>
              <div className="col-sm-8">
                <select
                  name="legalEntity"
                  className="form-control"
                  onChange={(e) =>
                    setValues({
                      ...values,
                      legalEntity: parseInt(e.currentTarget.value),
                    })
                  }
                  required
                >
                  <option value={""}>Select Entity</option>
                  {LegalEntity.map((item: any) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-3 col-form-label required-field">
                Fee Mode
              </label>
              <div className="col-sm-8">
                <select
                  name="negativeFeeMode"
                  className="form-control"
                  onChange={(e) =>
                    setValues({
                      ...values,
                      negativeFeeMode: parseInt(e.currentTarget.value),
                    })
                  }
                  required
                >
                  <option value={""}>Select Fee Mode</option>
                  {NegativeFeeMode.map((item: any) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Cashback</label>
              <div
                className="col-sm-4"
                title="Cashback Transaction will be allowed"
              >
                <Switch
                  id="isCashbackAllowed"
                  isOn={values.isCashbackAllowed}
                  handleToggle={() =>
                    setValues({
                      ...values,
                      isCashbackAllowed: !values.isCashbackAllowed,
                    })
                  }
                  str1="Enable"
                  str2="Disable"
                />
              </div>

              <label className="col-sm-2 col-form-label">ATM Block</label>
              <div
                className="col-sm-4 pt-1"
                title="ATM Transaction will be blocked"
              >
                <Switch
                  id="isAtmBlocked"
                  isOn={values.isAtmBlocked}
                  handleToggle={() =>
                    setValues({ ...values, isAtmBlocked: !values.isAtmBlocked })
                  }
                  str1="Unblocked"
                  str2="Blocked"
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">
                Bal. Enquiry Block
              </label>
              <div
                className="col-sm-4 pt-1"
                title="ATM Balance Enquiry will be blocked"
              >
                <Switch
                  id="isBalanceEnquiryBlocked"
                  isOn={values.isBalanceEnquiryBlocked}
                  handleToggle={() =>
                    setValues({
                      ...values,
                      isBalanceEnquiryBlocked: !values.isBalanceEnquiryBlocked,
                    })
                  }
                  str1="Unblocked"
                  str2="Blocked"
                />
              </div>

              <label className="col-sm-2 col-form-label">
                PIN Change Block
              </label>
              <div
                className="col-sm-4 pt-1"
                title="ATM PIN Change will be blocked"
              >
                <Switch
                  id="isPinChangedBlocked"
                  isOn={values.isPinChangedBlocked}
                  handleToggle={() =>
                    setValues({
                      ...values,
                      isPinChangedBlocked: !values.isPinChangedBlocked,
                    })
                  }
                  str1="Unblocked"
                  str2="Blocked"
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Ecommerce Block</label>
              <div
                className="col-sm-4 pt-1"
                title="E-commerce transaction will be blocked"
              >
                <Switch
                  id="isEcommerceBlocked"
                  isOn={values.isEcommerceBlocked}
                  handleToggle={() =>
                    setValues({
                      ...values,
                      isEcommerceBlocked: !values.isEcommerceBlocked,
                    })
                  }
                  str1="Unblocked"
                  str2="Blocked"
                />
              </div>

              <label className="col-sm-2 col-form-label">MOTO Block</label>
              <div
                className="col-sm-4 pt-1"
                title="MOTO Transaction will be blocked"
              >
                <Switch
                  id="isMotoBlocked"
                  isOn={values.isMotoBlocked}
                  handleToggle={() =>
                    setValues({
                      ...values,
                      isMotoBlocked: !values.isMotoBlocked,
                    })
                  }
                  str1="Unblocked"
                  str2="Blocked"
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">POS Block</label>
              <div
                className="col-sm-4 pt-1"
                title="POS Transaction will be blocked"
              >
                <Switch
                  id="isPosBlocked"
                  isOn={values.isPosBlocked}
                  handleToggle={() =>
                    setValues({ ...values, isPosBlocked: !values.isPosBlocked })
                  }
                  str1="Unblocked"
                  str2="Blocked"
                />
              </div>

              <label className="col-sm-2 col-form-label">
                Contactless Block
              </label>
              <div
                className="col-sm-4 pt-1"
                title="Contactless POS Transaction will be blocked"
              >
                <Switch
                  id="isContactlessBlocked"
                  isOn={values.isContactlessBlocked}
                  handleToggle={() =>
                    setValues({
                      ...values,
                      isContactlessBlocked: !values.isContactlessBlocked,
                    })
                  }
                  str1="Unblocked"
                  str2="Blocked"
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Acquirer Fee</label>
              <div
                className="col-sm-4 pt-1"
                title="Acquirer Fees will be applied"
              >
                <Switch
                  id="isAcquirerFeesApplied"
                  isOn={values.isAcquirerFeesApplied}
                  handleToggle={() =>
                    setValues({
                      ...values,
                      isAcquirerFeesApplied: !values.isAcquirerFeesApplied,
                    })
                  }
                  str1="Enable"
                  str2="Disable"
                />
              </div>

              <label className="col-sm-2 col-form-label">MDES</label>
              <div
                className="col-sm-4 pt-1"
                title="Setup will allow MDES tokenization"
              >
                <Switch
                  id="isMdesEnrolled"
                  isOn={values.isMdesEnrolled}
                  handleToggle={() =>
                    setValues({
                      ...values,
                      isMdesEnrolled: !values.isMdesEnrolled,
                    })
                  }
                  str1="Enable"
                  str2="Disable"
                />
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <h5 className="group-field-label">Callout</h5>
            <div className="form-group row">
              <label className="col-sm-1 col-form-label">Authorization</label>
              <div className="col-sm-2 " title="Authorization Callout">
                <Switch
                  id="isAuthorizationCalledout"
                  isOn={values.isAuthorizationCalledout}
                  handleToggle={() =>
                    setValues({
                      ...values,
                      isAuthorizationCalledout:
                        !values.isAuthorizationCalledout,
                    })
                  }
                  str1="Unblocked"
                  str2="Blocked"
                />
              </div>

              <label className="col-sm-1 col-form-label">Settlement</label>
              <div className="col-sm-2 pt-1" title="Settlement Callout">
                <Switch
                  id="isSettlementCalledout"
                  isOn={values.isSettlementCalledout}
                  handleToggle={() =>
                    setValues({
                      ...values,
                      isSettlementCalledout: !values.isSettlementCalledout,
                    })
                  }
                  str1="Unblocked"
                  str2="Blocked"
                />
              </div>

              <label className="col-sm-1 col-form-label">Bal. Enquiry</label>
              <div className="col-sm-2 pt-1" title="Bal. Enquiry Callout">
                <Switch
                  id="isBalanceEnquiryCalledout"
                  isOn={values.isBalanceEnquiryCalledout}
                  handleToggle={() =>
                    setValues({
                      ...values,
                      isBalanceEnquiryCalledout:
                        !values.isBalanceEnquiryCalledout,
                    })
                  }
                  str1="Unblocked"
                  str2="Blocked"
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            {createAccess && (
              <input
                type="submit"
                value="Save & Continue"
                className="btn button-control-warning float-right"
                disabled={!isFormValid}
              />
            )}
            <Link
              to="/setup"
              className="btn button-control-secondary float-right"
            >
              Cancel
            </Link>
          </div>
        </form>
      </div>
    </Wrapper>
  );
};

export default SetupAddNew;
