import { useContext, useMemo, useState } from "react";
import SetupContext from "../../../../context/setup/SetupContext";
import { cardVendorColumns } from "../../../../models/enum/columns";
import Spinner from "../../../../components/spinner/Spinner";
import { Link } from "react-router-dom";
import { TableHeaders } from "../../../../components/table";
import { ConfirmDialogInitValues } from "../../../../models/ConfirmDialogInitValues";
import ConfirmDialog from "../../../../components/dialog/ConfirmDialog";
import UnauthorizedPage from "../../../errors/UnauthorizedPage";
import SecurityClassificationError from "../../../errors/SecurityClassification";

const CardVendors = (props: any) => {
  const { issuerId, setupId,securityClassifications,canPageBeRendered,proccedAndLoad  } = props;

  const { cardVendorDetail, loading, deleteCardVendor } = useContext(SetupContext);
  const [confirmDialog, setConfirmDialog] = useState(ConfirmDialogInitValues);
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const cardVendorsData = useMemo(() => {
    let vendorDetails = cardVendorDetail;

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      vendorDetails = vendorDetails.sort(
        (a: any, b: any) =>
          reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    return vendorDetails;
  }, [cardVendorDetail, sorting]);

  const handleDelete = (vendorSetupId: string, activeVendor: string) => {
    deleteCardVendor(issuerId, setupId, { vendorSetupId: vendorSetupId, activeVendor: activeVendor });    
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };  

  const actions = (vendorSetupId: string, activeVendor: string) => {
    return (
      <div className="btn-group mr-2">
        <a
          href="#!"
          onClick={() => {
            setConfirmDialog({
              isOpen: true,
              title: "Are you sure you want to delete this record?",
              subTitle: "You cannot undo this operation.",
              onConfirm: () => handleDelete(vendorSetupId, activeVendor),
              onCancel: () =>
                setConfirmDialog({ ...confirmDialog, isOpen: false }),
            });
          }}
        >
          <i className="far fa-trash-alt m-1 text-danger"></i>
        </a>
      </div>
    );
  };

  const readAccess = securityClassifications?.includes("Read");
  const createAccess = securityClassifications?.includes("Create");
  const updateAccess = securityClassifications?.includes("Update");
  const deleteAccess = securityClassifications?.includes("Delete");

  if(!proccedAndLoad)
  {
      return <UnauthorizedPage withWrapper={false} />;
  }

  if(!readAccess){
      return (<SecurityClassificationError title="Read Classification Denied" pageName="Vednor Details" extraReference='Vednor Details' withWrapper={false}/>)
  }
  
  return !loading ? (
    <div>
      <span>
        { createAccess && <Link to={`/setup/${issuerId}/${setupId}/vendors/create`} className="btn btn-round btn-success ml-2">
          <i className="fa fa-plus p-1" />
          <span>Add New Card Style</span>
        </Link>
        }
      </span>

      <div className="space-4"></div>

      <table
        id="table-data"
        className="table table-bordered table-hover table-sm"
        role="grid"
      >
        <TableHeaders
          headers={cardVendorColumns}
          onSorting={(field: string, order: string) => {
            setSorting({ field, order });
          }}
        />
        <tbody>
          {cardVendorsData && cardVendorsData.length ? (
            cardVendorsData.map((item: any) => (
              <tr key={item.vendorSetupId}>
                <td>
                  { 
                  updateAccess && <Link
                    to={`/setup/${issuerId}/${setupId}/vendors/${item.vendorSetupId}`}
                  >
                    <i className="fas fa-edit text-warning"></i>
                  </Link>
                  }
                </td>
                <td>{item.vendorSetupId}</td>
                <td>{item.activeVendor}</td>
                <td style={{ width: "10px" }}>
                  {
                    deleteAccess && actions(item.vendorSetupId, item.activeVendor)
                  }
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="text-center text-danger" colSpan={4}>
                No card vendors found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      { deleteAccess && <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        action="delete"
      />
      }
    </div>
  ) : (
    <Spinner />
  );
};

export default CardVendors;
