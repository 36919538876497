import React from 'react'
import { Modal } from 'react-bootstrap'

const ConfirmDialog = (props: any) => {
    const { confirmDialog, setConfirmDialog, action } = props;
    const actionLabels: Record<string, string> = {
        delete: "Delete",
        approve: "Approve",
        decline: "Decline",
        update: "Update",
      };

      const actionButtonClasses:Record<string, string> = {
        
        delete: 'btn-danger btn-sm',
        approve: 'btn-warning btn-sm',
        decline: 'btn-warning btn-sm',
        update: 'btn-warning btn-sm',
    };
    
    return (
        <Modal show={confirmDialog.isOpen} size="sm" onHide={() => setConfirmDialog({ ...confirmDialog, isOpen: false })} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {confirmDialog.title}         
            </Modal.Body>

            <Modal.Footer>
                <button className="btn-secondary btn-sm" onClick={confirmDialog.onCancel}>
                    Cancel
                </button >
                <button
                className={actionButtonClasses[action]}
                onClick={confirmDialog.onConfirm}
                >
                Confirm {actionLabels[action]}
                </button >
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmDialog
