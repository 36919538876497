export const NegativeFeeMode = [
    {
        id: 0,
        name: "Decline"
    },
    {
        id: 1,
        name: "Go Negative"
    },
    {
        id: 2,
        name: "Stay Positive"
    },
]