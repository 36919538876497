import { useState, useEffect } from "react";
import { GetCountries } from "../../../../models/enum/countries";
import { TBulk } from "../../../../models/ICardVendor";

const Bulk = ({ bulkData, handleChanges }: {bulkData: any, handleChanges: any}) => {  
  const [bulk, setBulk] = useState<TBulk>(bulkData);  
  const countries = GetCountries();

  useEffect(() => {setBulk(bulkData)}, [bulkData])  

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLSelectElement>
  ) => {
    const { id, value, type} = e.currentTarget       
    handleChanges(id, value, type )       
  };  

  return (
    <div className="card-body">
      <div className="row">
        <div className="col-md-6">
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Line 1</label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="line1"
                name="line1"
                value={bulk?.line1 || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Line 2</label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="line2"
                name="line2"
                value={bulk?.line2 || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Line 3</label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="line3"
                name="line3"
                value={bulk?.line3 || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">City</label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="city"
                name="city"
                value={bulk?.city || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">
              Bulk Delivery Code
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="bulkDeliveryCode"
                name="bulkDeliveryCode"
                value={bulk?.bulkDeliveryCode || ""}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Post Code</label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="postCode"
                name="postCode"
                value={bulk?.postCode || ""}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Country</label>
            <div className="col-sm-8">
              <select
                id="country"
                name="country"
                className="form-control form-control-sm"
                value={bulk?.country || ""}
                onChange={handleChange}
              >
                <option value={""}>Select Country</option>
                {countries.map((item: any) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Contact Name</label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="contactName"
                name="contactName"
                value={bulk?.contactName || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Contact Number</label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="contactNumber"
                name="contactNumber"
                value={bulk?.contactNumber || ""}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bulk;
