import {TransactionMgtInitState}  from "../../models/ITransactionMgt";
import { GET_TRANSACTIONS, HANDLE_TRANSACTIONS } from "../types";

const TransactionMgtReducer = (state: TransactionMgtInitState, action: any) => {
    switch (action.type) {
      case GET_TRANSACTIONS:
        return {
          ...state,
          transactions: action.payload,
          totalRows: action.payload.paging.totalRecordCount,
          loading: false,
          pageSize: action.payload.paging.pageSize,
          pageNo: action.payload.paging.pageNo,
        };  
  
        case HANDLE_TRANSACTIONS:
          return {
            ...state,
            error: action.payload,
          };
  
      default:
        return state;
    }
};
  
export default TransactionMgtReducer;
  