import { SyntheticEvent, useContext, useState } from "react";
import SetupContext from "../../../../context/setup/SetupContext";
import { formatStringIfNullOrEmpty, checkInputPattern } from "../../../../models/Helper";
import SecurityClassificationError from "../../../errors/SecurityClassification";

const UpdateFees = (props: any) => {
  const { data, setOpenPopup, issuerId, setupId, currencyCode, customerType, feesType, subType,securityClassifications,canPageBeRendered,proccedAndLoad } = props;
  const { updateFees } = useContext(SetupContext);
  const setDefaultVal = (val: number | null) => {
    return !val ? null : Number(formatStringIfNullOrEmpty(val));
  };

  const setPayload = (val: number | null, multiplier: number) => {
    return !val ? null : val * multiplier;
  };

  const [fees, setFees] = useState({
    fixedFee: setDefaultVal(data['fixedFee'] / 100),
    percentileMarkup: setDefaultVal(data['percentileMarkup'] * 100),
    minimumFee: setDefaultVal(data['minimumFee'] / 100),
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFees({ ...fees, [e.target.name]: Number(e.target.value) })
  }

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const payload = {
      fixedFee: setPayload(fees['fixedFee'],100),
      percentileMarkup: fees['percentileMarkup'] ? fees['percentileMarkup'] /100 : null,
      minimumFee: setPayload(fees['minimumFee'],100),
    };
    updateFees(issuerId, setupId, currencyCode, payload, customerType, feesType, subType);
    setOpenPopup(false);
  };

  const updateAccess = securityClassifications?.includes("Update");

  if(!proccedAndLoad || !updateAccess)
  {
      return (<SecurityClassificationError title="" pageName="" extraReference={"Update Currency Fees"} withWrapper={false}/>)
  }

  return (
    <form
      className="row"
        onSubmit={onSubmit}
    >
      <div className="col-sm-12">
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">Fixed</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              placeholder="Fixed"
              name="fixedFee"
              onKeyPress={(event) => {
                if (checkInputPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={fees['fixedFee'] || ''}
              onChange={onChange}              
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">(%)</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              placeholder="Percentile Markup"
              name="percentileMarkup"
              onKeyPress={(event) => {
                if (checkInputPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={fees['percentileMarkup'] || ''}
              onChange={onChange}              
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">Min Fee</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              placeholder="Minimum Fee"
              name="minimumFee"
              onKeyPress={(event) => {
                if (checkInputPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={fees['minimumFee'] || ''}
              onChange={onChange}              
            />
          </div>
        </div>
        <hr />
        <div className="form-group row">
          <div className="float-right">
            <button
              type="button"
              className="btn-secondary btn-sm m-1"
              onClick={() => setOpenPopup(false)}
            >
              Cancel
            </button>
            { 
              updateAccess && <button type="submit" className="btn-success btn-sm m-1">
                Save
              </button>
            }
          </div>
        </div>
      </div>
    </form>
  );
};

export default UpdateFees;
