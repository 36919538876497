import React, { SyntheticEvent, useContext, useEffect, useState } from 'react'
import RoleBasedAccessControlContext from '../../../context/rolebasedaccesscontrol/RoleBasedAccessControlContext';
import ForexMgtContext from '../../../context/forexmgt/ForexMgtContext';
import SecurityClassificationError from "../../errors/SecurityClassification";

const UpdateFx = (props: any) => {

    const {getPromisedSecurityClassification}  = useContext(RoleBasedAccessControlContext); 
    const [updateAccess, setCrudAccess] = useState<boolean>(false);
  
    useEffect(() => {
      getPromisedSecurityClassification()
          .then((classification: string[]) => {
              setCrudAccess(classification?.includes("Update"));
          })
          .catch((error: any) => {
              setCrudAccess(false);
          });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    const { fxDetails, loading } = useContext(ForexMgtContext);
    const { setOpenPopup } = props;
    const [fxDetailsNew, setfxDetailsNew] = useState(fxDetails);

    useEffect(() => {        
        setfxDetailsNew(fxDetails)
    }, [fxDetails])

    const onChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        setfxDetailsNew({ ...fxDetailsNew, [e.target.name]: e.target.value });        
    }

    const onSubmit = (e: SyntheticEvent) => {
        e.preventDefault();
        setOpenPopup(false);
    }

    if(!updateAccess)
    {
        return (<SecurityClassificationError title="Update Classification Denied" pageName="Foreign Exchange Management" extraReference="Foreign Exchange Management" withWrapper={false} />)
    }

    return (
        <form className="row" onSubmit={onSubmit}>
            {
                !loading && fxDetailsNew &&
                <div className="col-sm-12">
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Source Currency</label>
                        <div className="col-sm-8">
                            <span className="form-control">{fxDetails.sourceCurrency}</span>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Buying Currency</label>
                        <div className="col-sm-8">
                            <span className="form-control">{fxDetails.buyingCurrency}</span>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Exchange Rate</label>
                        <div className="col-sm-8">                            
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Exchange Rate"
                                name="exchangeRate"                               
                                autoComplete="off"
                                onKeyPress={(event) => {
                                    if (!/^\d*\.?\d*$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                value={fxDetailsNew.exchangeRate}
                                onChange={onChange}
                                required />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Provider</label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Provider"
                                name="provider"                               
                                autoComplete="off"
                                value={fxDetailsNew.provider}
                                onChange={onChange}
                                required />
                        </div>
                    </div>
                </div>
            }

            <hr />
            <div className="form-group row">
                <div className="float-right p-2">
                    <button type="button" className="btn-secondary btn-sm m-1" onClick={() => setOpenPopup(false)}>
                        Cancel
                    </button >
                    { updateAccess &&<button type="submit" hidden className="btn-success btn-sm m-1">
                            Save
                        </button >
                    }
                </div>

            </div>
        </form>
    )
}

export default UpdateFx
