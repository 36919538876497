import React from "react";

export const currencyFormat = (num: number) => {  
  return num.toFixed(2);
};

export const formatStringIfNullOrEmpty = (str: number) => {   
  return !str ? "-" : currencyFormat(str);
};

export const formatString = (str: number) => {   
  return str === 0 ? 0 : currencyFormat(str);
};

export const checkInputPattern = (event:React.KeyboardEvent<HTMLInputElement>) => {
  const val = event.currentTarget.value + event.key;
  const index = val.indexOf('.') !== -1;
  const key = !/[0-9,.]/.test(event.key);
  return ((!/^[0-9]*\.?[0-9]*$/.test(val) && index) || key);
}

export const isWholeNumberPattern = (event:React.KeyboardEvent<HTMLInputElement>) => {
  const val = event.currentTarget.value + event.key;
  const regex = /^[0-9\b]+$/;    
  return (val === '' || regex.test(val))
}

export const formatInput = (e:React.KeyboardEvent<HTMLInputElement>) => {
  // Prevent characters that are not numbers ("e", ".", "+" & "-") 
  let checkIfNum;
  if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum = e.key === "e" || e.key === "+" || e.key === "-"; // || e.key === "." 
  } else if (e.key !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum = e.key === 69 || e.key === 187 || e.key === 189; // || e.keyCode === 190 
  }
  return checkIfNum && e.preventDefault();
}