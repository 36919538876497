import React, { Fragment, useState } from 'react'
import RegionalCounts from './RegionalCounts';
import MccCounts from './MccCounts';
import PeriodicCounts from './PeriodicCounts';
import { Link } from "react-router-dom";
import AddMccCount from "./AddMccCount";
import Popup from '../../../../components/dialog/Popup';
import { HelpDescriptions } from '../../../../models/enum/helpDescriptions';

const CountsTree = (props: any) => {
    const { title, data, issuerId, setupId, customerType, isExpanded,securityClassifications,canPageBeRendered,proccedAndLoad } = props;
    const [openPopup, setOpenPopup] = useState(false);
    
    return (
        <Fragment>
            <tr data-widget={isExpanded ? "" : "expandable-table"} aria-expanded="false">
                <td colSpan={4}>
                    {isExpanded ? null : <i className="fas fa-caret-right fa-fw"/>}
                    <span className="text-primary">{title}</span>
                </td>
            </tr>

            <tr className="expandable-body">
                <td>
                    <div className={`p-1 ${isExpanded ? "": "collapse"}`}>
                        <table className="table table-bordered table-hover table-sm">
                            <thead className="table-header">
                                <tr>
                                    <th style={{ width: '40%' }}>TYPE</th>
                                    <th className="text-right">Daily</th>
                                    <th className="text-right">Monthly</th>
                                    <th className="text-right">Yearly</th>
                                </tr>
                            </thead>
                            <tbody>
                                <PeriodicCounts 
                                    title={title}
                                    subtitle={'Load'}
                                    data={data.load}
                                    isParent={true}
                                    issuerId={issuerId}
                                    setupId={setupId}
                                    customerType={customerType}
                                    type={null}
                                    securityClassifications={securityClassifications} 
                                    canPageBeRendered={canPageBeRendered} 
                                    proccedAndLoad={proccedAndLoad}/>
                                
                                <tr className="tr-border-bottom">
                                    <td colSpan={4} className="px-4" >
                                        ATM
                                    </td>
                                </tr>
                                <RegionalCounts 
                                    data={data.atm}
                                    title={title}
                                    subtitle={'ATM'}
                                    issuerId={issuerId}
                                    setupId={setupId}
                                    customerType={customerType}
                                    securityClassifications={securityClassifications} 
                                    canPageBeRendered={canPageBeRendered} 
                                    proccedAndLoad={proccedAndLoad}/>

                                <tr className="tr-border-bottom">
                                    <td colSpan={4} className="px-4" >
                                        E-Commerce
                                    </td>
                                </tr>
                                <RegionalCounts 
                                    data={data.eCommerce}
                                    title={title}
                                    subtitle={'E-Commerce'}
                                    issuerId={issuerId}
                                    setupId={setupId}
                                    customerType={customerType}
                                    securityClassifications={securityClassifications} 
                                    canPageBeRendered={canPageBeRendered} 
                                    proccedAndLoad={proccedAndLoad}/>

                                <tr className="tr-border-bottom">
                                    <td colSpan={4} className="px-4" >
                                        POS
                                    </td>
                                </tr>
                                <RegionalCounts 
                                    data={data.pos}
                                    title={title}
                                    subtitle={'POS'}
                                    issuerId={issuerId}
                                    setupId={setupId}
                                    customerType={customerType}
                                    securityClassifications={securityClassifications} 
                                    canPageBeRendered={canPageBeRendered} 
                                    proccedAndLoad={proccedAndLoad}/>

                                <tr className="tr-border-bottom">
                                    <td colSpan={4} className="px-4" >
                                        MOTO
                                    </td>
                                </tr>
                                <RegionalCounts data={data.moto}
                                    title={title}
                                    subtitle={'MOTO'}
                                    issuerId={issuerId}
                                    setupId={setupId}
                                    customerType={customerType}
                                    securityClassifications={securityClassifications} 
                                    canPageBeRendered={canPageBeRendered} 
                                    proccedAndLoad={proccedAndLoad}/>

                                
                                <tr className="tr-border-bottom">
                                    <td colSpan={4} className="px-4" >
                                        MCC &emsp;
                                        <Link
                                            to="#"
                                            onClick={() => setOpenPopup(true)}
                                            title={'Add MCC'}
                                        >
                                            <i className="fas fa-plus-square" />
                                        </Link>
                                        <Popup title={"Add MCC Count"} setOpenPopup={setOpenPopup} openPopup={openPopup} size="lg">
                                        <div className="description">{HelpDescriptions.TRANSACTION_COUNT_LIMTS}</div><br/>  
                                        <AddMccCount 
                                            data={data.mcc}
                                            issuerId={issuerId}
                                            setupId={setupId}
                                            customerType={customerType}
                                            securityClassifications={securityClassifications} 
                                            canPageBeRendered={canPageBeRendered} 
                                            proccedAndLoad={proccedAndLoad}
                                            setOpenPopup={setOpenPopup}
                                            />
                                        </Popup>
                                    </td>
                                </tr>
                                <MccCounts 
                                    data={data.mcc} 
                                    issuerId={issuerId}
                                    setupId={setupId}
                                    customerType={customerType}
                                    securityClassifications={securityClassifications} 
                                    canPageBeRendered={canPageBeRendered} 
                                    proccedAndLoad={proccedAndLoad}/>

                            </tbody>
                        </table>
                    </div>
                </td>
            </tr>


        </Fragment>
    )
}

export default CountsTree
