
import { Link } from 'react-router-dom'
import * as menu from './menu';
import React, { useContext, useEffect,useState } from 'react';
import RoleBasedAccessControlContext from '../../context/rolebasedaccesscontrol/RoleBasedAccessControlContext';

const Menu = () => {

    const {navigationItems,getAllowedNavigationItems}  = useContext(RoleBasedAccessControlContext); 
    const {roles,getUserRoles}  = useContext(RoleBasedAccessControlContext); 

    useEffect(() => { 
        getAllowedNavigationItems();
        getUserRoles();
    },[]);
    if(navigationItems === undefined || navigationItems.length == 0) 
    {
        return (<aside className="main-sidebar sidebar-dark-primary elevation-4"> </aside>)
    }
    
    return (        
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            {/* Brand Logo */}
            <a href="/" className="brand-link">
                <img src={`${process.env.PUBLIC_URL}/dist/img/TraceLogo.png`} alt="Trace Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
                <span className="brand-text font-weight-strong">TRACE</span>
            </a>
            {/* Sidebar */}
            <div className="sidebar">               
                {/* Sidebar Menu */}
                <nav className="mt-2">
                {navigationItems.length > 0 && (

                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false"> 
                        <li className="nav-header">{roles}</li>
                        {
                            menu.SidebarAdminMenus.filter((item) => navigationItems.includes(item.name.toLowerCase())).map((item, idx: number) => {
                                    return (                               
                                    <li className="nav-item" key={idx} >
                                        <Link to={item.path} className="nav-link">
                                            <i className={`nav-icon ${item.icon}`}/>
                                            <p>
                                                {item.name}
                                            </p>
                                        </Link>
                                    </li>
                                    );
                                })}
                    </ul>
                )}
                </nav>
            </div>
        </aside>
    )
}

export default Menu
