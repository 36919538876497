import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import RoleBasedAccessControlContext from '../../../../context/rolebasedaccesscontrol/RoleBasedAccessControlContext';
import SetupContext from "../../../../context/setup/SetupContext";
import { initCardVendor, TCardVendor } from "../../../../models/ICardVendor";
import Wrapper from "../../../Wrapper";
import CardVendorDetail from "./CardVendorDetail";
import UnauthorizedPage from "../../../errors/UnauthorizedPage";
import SecurityClassificationError from "../../../errors/SecurityClassification";

const CardVendor = () => {
  const {securityClassifications,getSecurityClassification,retrievePagePermission}  = useContext(RoleBasedAccessControlContext);
  const [proccedAndLoad, setUserAccess] = useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [canPageBeRendered, canInvokePageContent] = useState(false); 

  useEffect(() => {
      retrievePagePermission("setup").then((isGranted: boolean) => {
          canInvokePageContent(true);
          setUserAccess(isGranted);
      }).catch((error: any) => {
          canInvokePageContent(false);
          setUserAccess(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
      getSecurityClassification();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const readAccess = securityClassifications?.includes("Read");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const createAccess = securityClassifications?.includes("Create");
  const updateAccess = securityClassifications?.includes("Update");

  const { vendorSetupId, issuerId, setupId } = useParams();
  const [vendorDetail, setVendorDetail] = useState<TCardVendor>(initCardVendor);
  const { cardVendorDetail, getSetupDetails } = useContext(SetupContext);
  
  useEffect(() => {
    if (!cardVendorDetail && vendorSetupId) {
      getSetupDetails(issuerId, setupId);
    }    
    const detail = cardVendorDetail?.find(
      (d: TCardVendor) => d.vendorSetupId === vendorSetupId
    );     
    if (detail)
      setVendorDetail(detail);
  }, [vendorSetupId, cardVendorDetail, getSetupDetails, issuerId, setupId]);  
  

  if(!proccedAndLoad)
  {
      return <UnauthorizedPage withWrapper={true} />;
  }

  if(!updateAccess){
    return (<SecurityClassificationError title="Update Classification Denied" pageName="Card Styles" extraReference='Card Styles' withWrapper={true}/>)
  }

  return (
    <Wrapper title="Card Styles" subTitle="Card Style Details">
      <div className="col-md-12 ml-3">
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">
            <Link to={`/setup/${issuerId}/${setupId}`} className="link" state={{ hash: "#tab-cardVendors"}}>
              <i className="nav-icon fas fa-backward mr-2" />
              Back To Card Style List
            </Link>           
          </label>
        </div>
      </div>
      <CardVendorDetail cardVendor={vendorDetail}  />
    </Wrapper>
  );
};

export default CardVendor;
