import { SyntheticEvent, useContext, useState } from "react";
import SetupContext from "../../../../context/setup/SetupContext";
import { formatStringIfNullOrEmpty, checkInputPattern } from "../../../../models/Helper";
import SecurityClassificationError from "../../../errors/SecurityClassification";

const UpdateGlobalLimit = (props: any) => {
  const { data, setOpenPopup, issuerId, setupId, currencyCode, customerType, periodicLimit,messageReference, securityClassifications, proccedAndLoad, canPageBeRendered } = props;
  const { updateGlobalLimit } = useContext(SetupContext);
  const setDefaultVal = (val: number | null) => {
    return !val ? null : Number(formatStringIfNullOrEmpty(val));
  };

  const setPayload = (val: number | null, multiplier: number) => {
    return !val ? null : val * multiplier;
  };

  const [globalLimit, setGlobalLimit] = useState({
    maxTransactionAmount: setDefaultVal(data['maxTransactionAmount'] / 100),    
    daily: setDefaultVal(data['daily'] / 100),
    monthly: setDefaultVal(data['monthly'] / 100),
    yearly: setDefaultVal(data['yearly'] / 100),
    lifetime: setDefaultVal(data['lifetime'] / 100),
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGlobalLimit({ ...globalLimit, [e.target.name]: Number(e.target.value) })
  }

  const onReset = () => {
    setGlobalLimit({
        maxTransactionAmount: setDefaultVal(data['maxTransactionAmount'] / 100),    
        daily: setDefaultVal(data['daily'] / 100),
        monthly: setDefaultVal(data['monthly'] / 100),
        yearly: setDefaultVal(data['yearly'] / 100),
        lifetime: setDefaultVal(data['lifetime'] / 100),
    })
  }

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const payload = {      
      maxTransactionAmount: setPayload(globalLimit['maxTransactionAmount'],100),      
      daily: setPayload(globalLimit['daily'],100),
      monthly: setPayload(globalLimit['monthly'],100),
      yearly: setPayload(globalLimit['yearly'],100),
      lifetime: setPayload(globalLimit['lifetime'],100),
    };
    updateGlobalLimit(issuerId, setupId, payload, currencyCode, customerType, periodicLimit);
    setOpenPopup(false);
  };

  const updateAccess = securityClassifications?.includes("Update");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const createAccess = securityClassifications?.includes("Create");

  if(!proccedAndLoad || !updateAccess)
  {
      return (<SecurityClassificationError title="" pageName="" extraReference={`${messageReference}`} withWrapper={false}/>)
  }

  return (
    <form
      className="row"
        onSubmit={onSubmit}
        onReset={onReset}
    >
      <div className="col-sm-12">
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">SingleTrn</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              placeholder="Max Transaction Amount"
              name="maxTransactionAmount"
              onKeyPress={(event) => {
                if (checkInputPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={globalLimit['maxTransactionAmount'] || ''}
              onChange={onChange}              
            />
          </div>
        </div>        
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">Daily</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              placeholder="Daily"
              name="daily"
              onKeyPress={(event) => {
                if (checkInputPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={globalLimit['daily'] || ''}
              onChange={onChange}              
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">Monthly</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              placeholder="Monthly"
              name="monthly"
              onKeyPress={(event) => {
                if (checkInputPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={globalLimit['monthly'] || ''}
              onChange={onChange}              
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">Yearly</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              placeholder="Yearly"
              name="yearly"
              onKeyPress={(event) => {
                if (checkInputPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={globalLimit['yearly'] || ''}
              onChange={onChange}              
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">Lifetime</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              placeholder="Lifetime"
              name="lifetime"
              onKeyPress={(event) => {
                if (checkInputPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={globalLimit['lifetime'] || ''}
              onChange={onChange}              
            />
          </div>
        </div>
        <hr />
        <div className="form-group row">
          <div className="float-right">
            <button
              type="button"
              className="btn-secondary btn-sm m-1"
              onClick={() => setOpenPopup(false)}
            >
              Cancel
            </button>
            <button
              type="reset"
              className="btn-warning btn-sm m-1"              
            >
              Reset
            </button>
            {
              updateAccess && 
              <button type="submit" className="btn-success btn-sm m-1">
              Save
            </button>
            }
          </div>
        </div>
      </div>
    </form>
  );
};

export default UpdateGlobalLimit;
