import { Link } from "react-router-dom";
import SetupContext from "../../../../context/setup/SetupContext";
import Popup from "../../../../components/dialog/Popup";
import { useState, useContext } from "react";

const MccCounts = (props: any) => {
  const { data, issuerId, setupId, customerType,securityClassifications,canPageBeRendered,proccedAndLoad } = props;
  const [openPopup, setOpenPopup] = useState(false);
  const { removeMccCount } = useContext(SetupContext);
  const [arrNum, setArrNum] = useState([]);

  const setDisplay = (val: number | null) => {
    return val === null ? '-' : val;
  };

  const removeMCC = (mcc: Array<Number>) => {
    removeMccCount(issuerId, setupId, mcc, customerType);
    setOpenPopup(false);
  }

  const updateAccess = securityClassifications?.includes("Update");
  const deleteAccess = securityClassifications?.includes("Delete");

  return data.map((mccData: any, idx: number) => (
    <tr key={idx} style={{ borderBottom: "1px solid #dee2e6" }}>
      <td className="px-5">
        {
          deleteAccess && <Link
          to="#"
          onClick={() => {
            setOpenPopup(true);
            setArrNum(mccData["mcc"])
          }}
          style={{ color: "red" }}
        >
          <i className="fas fa-trash" />
        </Link>
        }
        &emsp;
        {mccData["mcc"]?.join()}
      </td>
      <td className="text-right" style={{ width: "20%" }}>
        {setDisplay(mccData?.daily)}        
      </td>
      <td className="text-right" style={{ width: "20%" }}>
        {setDisplay(mccData?.monthly)}
      </td>
      <td className="text-right" style={{ width: "20%" }}>
        {setDisplay(mccData?.yearly)}
      </td>
      <Popup
        title={"CONFIRMATION"}
        setOpenPopup={setOpenPopup}
        openPopup={openPopup}
        size="sm"
      >
        <span>Are you sure you wanted to remove this MCC Count ? </span>
        <hr />
        <div>
          <div className="float-right">
            <button
              type="button"
              className="btn-secondary btn-sm m-1"
              onClick={() => setOpenPopup(false)}
            >
              Cancel
            </button>
            {
              deleteAccess && <button 
                type="button" 
                className="btn-success btn-sm m-1"
                onClick={() => {
                  removeMCC(arrNum)
                }}
                >
              Remove
            </button>
            }
          </div>
        </div>
      </Popup>
    </tr>
  ));
};

export default MccCounts;
