import React, {Fragment} from 'react';
import FeesTree from './FeesTree';

const ConsolidatedView = (props: any) => {
    
    const { data, issuerId, setupId,securityClassifications,canPageBeRendered,proccedAndLoad } = props;

    return (
        <div className="consolidated-wrapper">  <table className="table table-borderless table-sm">
        <tbody>
            {
                 data.map((item: any, idx: number) => (   
                    <Fragment key={idx}>
                        <tr>
                            <td>
                                <div className="form-inline">
                                    Currency<h5 className="pl-2 pt-2"><span className="badge bg-info">{item.currencyName}</span></h5>
                                </div>
                            </td>
                        </tr>

                        {/* <tr data-widget="expandable-table" aria-expanded="true">
                            <td>
                                <i className="fas fa-caret-right fa-fw"></i>
                                <span className="text-primary">Fees</span>
                            </td>
                        </tr> */}
                        {/* <tr className="expandable-body"> */}
                        <tr>
                            <td>
                                <div className="p-0">
                                    <table className="table table-borderless table-sm">
                                        <tbody>
                                            <FeesTree data={item.fees.kyc} title="KYC Customers" customerType={'kyc'} issuerId={issuerId} setupId={setupId} currencyCode={item.currencyCode} isExpanded={true} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}/>

                                            <FeesTree data={item.fees.kycLite} title="KYC Lite Customers" customerType={'kyclite'} issuerId={issuerId} setupId={setupId} currencyCode={item.currencyCode} isExpanded={true} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}/>

                                            <FeesTree data={item.fees.sdd} title="SDD Customers" customerType={'sdd'} issuerId={issuerId} setupId={setupId} currencyCode={item.currencyCode} isExpanded={true} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}/>

                                            <FeesTree data={item.fees.anonymous} title="Anonymous Customers" customerType={'anonymous'} issuerId={issuerId} setupId={setupId} currencyCode={item.currencyCode} isExpanded={true} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}/>

                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </Fragment>
                ))
            }
        </tbody>
    </table>
        </div>
    )
}

export default ConsolidatedView
