import React, { useContext, useEffect,useState, useRef } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import RoleBasedAccessControlContext from '../../../context/rolebasedaccesscontrol/RoleBasedAccessControlContext';
import SetupContext from "../../../context/setup/SetupContext";
import Wrapper from "../../Wrapper";
import PANConfig from "./pan/PANConfig";
import DetailedInformation from "./DetailedInformation";
import ContactLessLimits from "./contactless/ContactLessLimits";
import CurrencyLimits from "./limits/CurrencyLimits";
import TransactionCounts from "./counts/TransactionCounts";
import CurrencyFees from "./fees/CurrencyFees";
import MccBlocks from "./mccblocks/MccBlocks";
import CardVendors from "./cardvendors/CardVendors";
import Spinner from "../../../components/spinner/Spinner";
import UnauthorizedPage from "../../errors/UnauthorizedPage";
import SecurityClassificationError from "../../errors/SecurityClassification";

const SetupDetails: React.FC = () => {

    const {securityClassifications,getSecurityClassification,retrievePagePermission}  = useContext(RoleBasedAccessControlContext);
    const [proccedAndLoad, setUserAccess] = useState<boolean>(false);
    const [canPageBeRendered, canInvokePageContent] = useState(false); 

    useEffect(() => {
        retrievePagePermission("setup").then((isGranted: boolean) => {
            canInvokePageContent(true);
            setUserAccess(isGranted);
        }).catch((error: any) => {
            canInvokePageContent(false);
            setUserAccess(false);
          });
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        getSecurityClassification();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

  const readAccess = securityClassifications?.includes("Read");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const createAccess = securityClassifications?.includes("Create");
  const updateAccess = securityClassifications?.includes("Update");

  const { issuerId, setupId } = useParams();
  const location = useLocation();
  const mainRef = useRef<null | HTMLAnchorElement>(null);
  const cardVendorsRef = useRef<null | HTMLAnchorElement>(null);
  const { hash } = (location.state as any) || {};

  const { setupDetails, loading, getSetupDetails } = useContext(SetupContext);    

  const scrollToComponent = () => {
    if (
        hash === "#tab-cardVendors" &&
      cardVendorsRef &&
      cardVendorsRef.current
    ) {
      cardVendorsRef.current?.click();
      window.history.replaceState(null, '')
    } else {
        mainRef?.current?.click();
    }
  };

  useEffect(() => {
    scrollToComponent();
    // eslint-disable-next-line
  }, [hash]);

  useEffect(() => {
    if (setupDetails === null) getSetupDetails(issuerId, setupId);
  }, [getSetupDetails, setupDetails, issuerId, setupId]);


  if(!canPageBeRendered)
  {
      return (<div className="card-body" >< Spinner /> </div>)
  }

  if(!proccedAndLoad)
  {
      return <UnauthorizedPage withWrapper={true} />;
  }

  if(!readAccess){
    return (<SecurityClassificationError title="Read Classification Denied" pageName="Setup Management Details" extraReference='Setup Management Detailed Information' withWrapper={true} />)       
  }

  /*if(!updateAccess){
    return (<SecurityClassificationError title="Update Classification Denied" pageName="Setup Management Details" extraReference='Setup Management Detailed Information' withWrapper={true} />)       
  }*/

  return (
    <Wrapper title="Setup Management" subTitle="Setup Detailed Information">
      <div className="col-md-12 ml-3">
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">
            <Link to="/setup" className="link">
              <i className="nav-icon fas fa-backward mr-2" />
              Back To List
            </Link>
          </label>
        </div>
      </div>
      <ul className="nav nav-pills p-3" id="setup-details-nav" role="tablist">
        <li className="nav-item">
          <a
            ref={cardVendorsRef}
            className="nav-link active"
            data-toggle="pill"
            href="#tab-detailedInfo"
            role="tab"
            aria-controls="tab-detailedInfo"
            aria-selected="true"
          >
            Detailed Information
          </a>
        </li>

        <li className="nav-item">
          <a
            className="nav-link"
            data-toggle="pill"
            href="#tab-panConfigs"
            role="tab"
            aria-controls="tab-panConfigs"
            aria-selected="false"
          >
            PAN Configuration
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            data-toggle="pill"
            href="#tab-currencyLimits"
            role="tab"
            aria-controls="tab-currencyLimits"
            aria-selected="false"
          >
            Currency Limits
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            data-toggle="pill"
            href="#tab-transactionCounts"
            role="tab"
            aria-controls="tab-transactionCounts"
            aria-selected="false"
          >
            Transaction Counts
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            data-toggle="pill"
            href="#tab-currencyFees"
            role="tab"
            aria-controls="tab-currencyFees"
            aria-selected="false"
          >
            Currency Fees
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            data-toggle="pill"
            href="#tab-contactlessLimits"
            role="tab"
            aria-controls="tab-contactlessLimits"
            aria-selected="false"
          >
            Contactless Limits
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            data-toggle="pill"
            href="#tab-mccBlocks"
            role="tab"
            aria-controls="tab-mccBlocks"
            aria-selected="false"
          >
            MCC Blocks
          </a>
        </li>
        <li className="nav-item">
          <a
            ref={cardVendorsRef}
            className="nav-link"
            data-toggle="pill"
            href="#tab-cardVendors"
            role="tab"
            aria-controls="tab-cardVendors"
            aria-selected="false"
          >
            Vendor Details
          </a>
        </li>
      </ul>

      <div className="card-body">
        {!loading && setupDetails ? (
          <div className="row">
            <div className="col-md-12">
              <div className="tab-content" id="custom-tabs-one-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="tab-detailedInfo"
                  role="tabpanel"
                  aria-labelledby="tab-detailedInfo"
                >
                  <DetailedInformation issuerId={issuerId} setupId={setupId} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />
                </div>
                <div
                  className="tab-pane fade show"
                  id="tab-panConfigs"
                  role="tabpanel"
                  aria-labelledby="tab-panConfigs"
                >
                  <PANConfig issuerId={issuerId} setupId={setupId} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />
                </div>
                <div
                  className="tab-pane fade"
                  id="tab-currencyLimits"
                  role="tabpanel"
                  aria-labelledby="tab-currencyLimits"
                >
                  <CurrencyLimits issuerId={issuerId} setupId={setupId} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />
                </div>
                <div
                  className="tab-pane fade"
                  id="tab-transactionCounts"
                  role="tabpanel"
                  aria-labelledby="tab-transactionCounts"
                >
                  <TransactionCounts issuerId={issuerId} setupId={setupId} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />
                </div>
                <div
                  className="tab-pane fade"
                  id="tab-currencyFees"
                  role="tabpanel"
                  aria-labelledby="tab-currencyFees"
                >
                  <CurrencyFees issuerId={issuerId} setupId={setupId} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />
                </div>
                <div
                  className="tab-pane fade"
                  id="tab-contactlessLimits"
                  role="tabpanel"
                  aria-labelledby="tab-contactlessLimits"
                >
                  <ContactLessLimits issuerId={issuerId} setupId={setupId} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />
                </div>
                <div
                  className="tab-pane fade"
                  id="tab-mccBlocks"
                  role="tabpanel"
                  aria-labelledby="tab-mccBlocks"
                >
                  <MccBlocks issuerId={issuerId} setupId={setupId} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />
                </div>
                <div
                  className="tab-pane fade"
                  id="tab-cardVendors"
                  role="tabpanel"
                  aria-labelledby="tab-cardVendors"
                >
                  <CardVendors issuerId={issuerId} setupId={setupId} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />
                </div>
              </div>
            </div>
          </div>
        ) : <Spinner />}
      </div>
    </Wrapper>
  );
};

export default SetupDetails;
