import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';

const TablePaginator = (props: any) => {
    const { total, itemsPerPage, currentPage, onPageChange, onPageSizeChange } = props;

    const [totalPages, setTotalPages] = useState(0)

    useEffect(() => {
        if (total > 0 && itemsPerPage > 0)
            setTotalPages(Math.ceil(total / itemsPerPage))
    }, [total, itemsPerPage]);

    const paginationItems = useMemo(() => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            if (i >= (currentPage) && i < (currentPage + 1)) {
                pages.push(
                    <li key={i} className={i === currentPage ? 'page-item active' : 'page-item'}>
                        <Link className="page-link" to="#!" onClick={() => onPageChange(i)}> 
                            <strong>{i}</strong>{` of `} <strong>{totalPages}</strong> {`Entr${totalPages > 1 ? "ies" : "y"}`}
                        </Link>
                    </li>
                );
            }
        }
        return pages;
    }, [totalPages, currentPage, onPageChange])

    if (totalPages === 0)
        return null;

    return (
        <div className="row">
            {
                total > 0 &&
                <Fragment>
                    <div className="col-sm-12 col-md-5">
                        <div className="dataTables_length" id="example1_paginate">

                            <label><span className="pr-2">Page Size: </span>
                                <select className="form-control form-control2"
                                    value={itemsPerPage}
                                    onChange={e => {
                                        onPageSizeChange(Number(e.target.value));
                                    }}
                                >
                                    {[10, 25, 50].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>
                                            {pageSize}
                                        </option>
                                    ))}
                                </select>

                                <span className="ml-3">Showing <strong>{
                                    (currentPage * itemsPerPage) - itemsPerPage + 1
                                }-
                                    {
                                        currentPage * itemsPerPage > total
                                            ? total
                                            : currentPage * itemsPerPage
                                    }
                                </strong> of <strong>{total}</strong> Results</span>

                            </label>



                        </div>
                    </div >

                    <div className="col-sm-12 col-md-7">
                        <div className="dataTables_paginate paging_simple_numbers" id="example1_paginate">
                            <ul className="pagination link-paginator">
                                <li
                                    className={currentPage === 1 ? "page-item disabled" : "page-item"}>
                                    <Link
                                        className="page-link"
                                        to="#!"
                                        onClick={() => onPageChange(1)}>
                                        First
                                    </Link>
                                </li>
                                <li
                                    className={currentPage === 1 ? "page-item disabled" : "page-item"}>
                                    <Link
                                        className="page-link"
                                        to="#!"
                                        onClick={() => onPageChange(currentPage - 1)}>
                                        Previous
                                    </Link>
                                </li>
                                {paginationItems}
                                <li
                                    className={currentPage === totalPages ? "page-item disabled" : "page-item"}>
                                    <Link
                                        className="page-link"
                                        to="#!" onClick={() => onPageChange(currentPage + 1)}>
                                        Next
                                    </Link>
                                </li>
                                <li
                                    className={currentPage === totalPages ? "page-item disabled" : "page-item"}>
                                    <Link
                                        className="page-link"
                                        to="#!"
                                        onClick={() => onPageChange(totalPages)}>
                                        Last
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Fragment>
            }

        </div >
    )
}

export default TablePaginator
