import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  TableInputSearch,
  TablePaginator,
  TableHeaders,
} from "../../../components/table";
import IssuerContext from "../../../context/issuer/IssuerContext";
import SetupContext from "../../../context/setup/SetupContext";
import RoleBasedAccessControlContext from "../../../context/rolebasedaccesscontrol/RoleBasedAccessControlContext";
import { setupColumns } from "../../../models/enum/columns";
import Wrapper from "../../Wrapper";
import TableSelect from "../../../components/table/TableSelect";
import Spinner from "../../../components/spinner/Spinner";
import { Link } from "react-router-dom";
import useDebounce from "../../../hooks/useDebounce";
import { useLocation } from "react-router-dom";
import UnauthorizedPage from "../../errors/UnauthorizedPage";
import SecurityClassificationError from "../../errors/SecurityClassification";

const Setup: React.FC = () => {
  const {
    issuersForSelect,
    defaultIssuerId,
    getIssuersForSelect,
    setCurrentIssuer,
  } = useContext(IssuerContext);
  const { setups, pageSize, pageNo, totalRows, loading, getSetups } =
    useContext(SetupContext);

  const [page, setPage] = useState(pageNo);
  const [search, setSearch] = useDebounce("", 750);
  const [issuerId, setIssuerId] = useState(defaultIssuerId);
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [itemsPerPage, setItemsSize] = useState(pageSize);

  useEffect(() => {
    if (!issuersForSelect.length) getIssuersForSelect();
  });

  useEffect(() => {
    getSetups(search, itemsPerPage, page, issuerId);
  }, [page, search, itemsPerPage, issuerId]); // eslint-disable-line react-hooks/exhaustive-deps

  const setIssuerIdForList = (value: string) => {
    setPage(1);
    setIssuerId(value);
    const selectedIssuer =
      issuersForSelect.find((i: any) => i.id === value) || null;
    setCurrentIssuer(selectedIssuer);
  };

  const setupData = useMemo(() => {
    let computedSetups = setups.data;

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedSetups = computedSetups.sort(
        (a: any, b: any) =>
          reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    return computedSetups;
  }, [setups, sorting]);

  const {
    securityClassifications,
    getSecurityClassification,
    retrievePagePermission,
  } = useContext(RoleBasedAccessControlContext);
  const [proccedAndLoad, setUserAccess] = useState<boolean>(false);
  const [canPageBeRendered, canInvokePageContent] = useState(false);
  const location = useLocation();

  useEffect(() => {
    let pageName = location?.pathname?.split("/").pop()?.toLowerCase();
    retrievePagePermission(pageName)
      .then((isGranted: boolean) => {
        canInvokePageContent(true);
        setUserAccess(isGranted);
        // Do something with menuItems
      })
      .catch((error: any) => {
        canInvokePageContent(false);
        setUserAccess(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSecurityClassification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const readAccess = securityClassifications?.includes("Read");
  const createAccess = securityClassifications?.includes("Create");
  const updateAccess = securityClassifications?.includes("Update");

  if (!canPageBeRendered) {
    return (
      <Wrapper title="Setup Management" subTitle="List of Setups">
        {" "}
        <Spinner />{" "}
      </Wrapper>
    );
  }

  if (!readAccess) {
    return (
      <SecurityClassificationError
        title="Read Classification Denied"
        pageName="Setup Management"
        extraReference="Setup Management"
        withWrapper={true}
      />
    );
  }

  if (!proccedAndLoad) {
    return <UnauthorizedPage />;
  }

  return (
    <Wrapper title="Setup Management" subTitle="List of Setups">
      {!loading ? (
        <div className="card-body">
          <div className="dataTables_wrapper dt-bootstrap4">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                {createAccess ? (
                  <TableSelect
                    options={issuersForSelect}
                    setIssuerId={setIssuerIdForList}
                    defaultLabel="Select Issuer"
                    hasAddButton={issuerId != null}
                    addButtonTitle="Setup a Programme"
                    addButtonLink={`/setup/${issuerId}/new`}
                    selectedIssuerId={defaultIssuerId}
                  />
                ) : (
                  <TableSelect
                    options={issuersForSelect}
                    setIssuerId={setIssuerIdForList}
                    defaultLabel="Select Issuer"
                    selectedIssuerId={defaultIssuerId}
                  />
                )}
              </div>

              <div className="col-sm-12 col-md-6">
                <div className="dataTables_filter">
                  <TableInputSearch
                    onSearch={(value: string) => {
                      setSearch(value);
                      setPage(1);
                    }}
                    disabled={!issuerId}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <table
                  id="table-data"
                  className="table table-striped dataTable dtr-inline table-sm table-bordered"
                  role="grid"
                >
                  <TableHeaders
                    headers={setupColumns}
                    onSorting={(field: string, order: string) => {
                      setSorting({ field, order });
                    }}
                  />
                  <tbody>
                    {setupData !== undefined &&
                    Object.keys(setupData).length !== 0 &&
                    setupData.constructor !== Object ? (
                      setupData.map((setup: any) => (
                        <tr key={setup.setupId}>
                          <td>
                            {readAccess && (
                              <Link to={`/setup/${issuerId}/${setup.setupId}`}>
                                <i className="fas fa-eye text-warning"></i>
                              </Link>
                            )}
                          </td>
                          <td>{setup.setupId}</td>
                          <td>{setup.setupName}</td>
                          <td>{setup.setupCountry}</td>
                          <td>{setup.negativeFeeMode}</td>
                          <td>{setup.balanceMode}</td>
                          <td>{setup.legalEntity}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="text-center text-danger" colSpan={7}>
                          No data available in table
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <TablePaginator
              total={totalRows}
              itemsPerPage={pageSize}
              currentPage={pageNo}
              onPageChange={(page: any) => setPage(page)}
              onPageSizeChange={(size: number) => setItemsSize(size)}
            />
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </Wrapper>
  );
};

export default Setup;
