import React, { useContext } from 'react'
import Spinner from '../../../../components/spinner/Spinner';
import SetupContext from '../../../../context/setup/SetupContext';
import MccBlocksTable from './MccBlocksTable';

const MccBlocks: React.FC<any> = (props) => {
    const { issuerId, setupId,securityClassifications,canPageBeRendered,proccedAndLoad } = props;
    const { blockedMCCs, loading } = useContext(SetupContext);

    return (
        !loading ?
        <MccBlocksTable data={blockedMCCs} issuerId={issuerId} setupId={setupId} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />
        : <Spinner />
    )
}

export default MccBlocks
