import { ForextMgtInitState } from "../../models/IForextMgt";
import { GET_FX, GET_FX_DETAILS } from "../types";

const ForexMgtReducer = (state: ForextMgtInitState, action: any) => {
  switch (action.type) {
    case GET_FX:
      return {
        ...state,
        fx: action.payload,
        totalRows: action.payload.paging.totalRecordCount,
        loading: false,
        pageSize: action.payload.paging.pageSize,
        pageNo: action.payload.paging.pageNo,
      };  

      case GET_FX_DETAILS:
        return {
          ...state,
          fxDetails: action.payload,
          loading: false
        }

    default:
      return state;
  }
};

export default ForexMgtReducer;
