export const BalanceMode = [
    {
        id: 0,
        name: "Debit"
    },
    {
        id: 1,
        name: "Credit"
    }
]

