import React, { SyntheticEvent, useContext, useState } from 'react'
import SetupContext from '../../../../context/setup/SetupContext';
import { GetCurrencies } from '../../../../models/enum/currencies';
import UnauthorizedPage from "../../../errors/UnauthorizedPage";
import SecurityClassificationError from "../../../errors/SecurityClassification";

const EditContactLessLimit = (props: any) => {
    const { setOpenPopup, issuerId, setupId = false,securityClassifications,canPageBeRendered,proccedAndLoad } = props;
    const [contactlessLimit, setContactlessLimit] = useState(props.limit);
    
    const { masterUpdateSetup} = useContext(SetupContext);

    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setContactlessLimit({ ...contactlessLimit, [e.target.name]: e.target.value })
    }

    const onChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
        setContactlessLimit({ ...contactlessLimit, [e.target.name]: parseInt( e.target.value) })
    }

    const onSubmit = (e: SyntheticEvent) => {
        e.preventDefault();
        masterUpdateSetup(issuerId, setupId, "ContactlessLimit", {
            ...contactlessLimit
        });
        setOpenPopup(false);  
    }

    const updateAccess = securityClassifications?.includes("Update");
    const createAccess = securityClassifications?.includes("Create");

    if(!proccedAndLoad || !updateAccess)
    {
        return (<SecurityClassificationError title="" pageName="" extraReference={`Edit ContactLess Limit`} withWrapper={false}/>)
    }

    return (
        <form className="row" onSubmit={onSubmit}>
            <div className="col-sm-12">
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Currency</label>
                    <div className="col-sm-8">
                        <select
                            name="Currency"
                            value={contactlessLimit.Currency}
                            className="form-control"
                            onChange={(e: any) => onChange(e)} required
                        >
                            <option value="">Select Currency</option>
                            {
                                GetCurrencies().map((item: any) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Transaction Limit</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            value={contactlessLimit.TransactionLimit}
                            className="form-control"
                            placeholder="Transaction Limit"
                            name="TransactionLimit"
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            autoComplete="off"
                            maxLength={5}
                            onChange={onChange2}
                            required />
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-sm-3 col-form-label">PIN Required</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            value={contactlessLimit.TransactionValue}
                            className="form-control"
                            placeholder="PIN Required"
                            name="TransactionValue"
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            autoComplete="off"
                            maxLength={5}
                            onChange={onChange2}
                            required />
                    </div>
                </div>

                <hr />
                <div className="form-group row">
                    <div className="float-right">
                        <button type="button" className="btn-secondary btn-sm m-1" onClick={() => setOpenPopup(false)}>
                            Cancel
                        </button>
                        {
                            updateAccess && <button type="submit" className="btn-success btn-sm m-1">
                            Save
                        </button>
                        }
                    </div>

                </div>
            </div>
        </form>
    )
}

export default EditContactLessLimit
