const CarrierIssuerStatement = [
    {
        id: 1,        
        name: "This Prepaid Mastercard is issued by Prepaid Financial Services Limited pursuant to a license from Mastercard. Prepaid Financial Services Limited is authorised by the Financial Conduct Authority under the Electronic Money Regulations 2011, firm reference number 900036, for the issuance of electronic money and provision of payment services. Registered Office: 4th Floor, 35 Great St Helen’s, London, EC3A 6AP. Company Registration number: 06337638"
    },
    {
        id: 2,
        name: "This Account is issued by Prepaid Financial Services Limited pursuant to a license from Mastercard. Prepaid Financial Services Limited is authorised by the Financial Conduct Authority under the Electronic Money Regulations 2011, firm reference number 900036, for the issuance of electronic money and provision of payment services. Registered Office: 4th Floor, 35 Great St Helen’s, London, EC3A 6AP. Company Registration number: 06337638"
    },
    {
        id: 3,
        name: "This Prepaid Mastercard is issued by PFS Card Services Ireland Limited pursuant to a license from Mastercard. PFS Card Services Ireland Limited, trading as PCSIL, is regulated by the Central Bank of Ireland"
    },
    {
        id: 4,
        name: "This Account is issued by PFS Card Services Ireland Limited pursuant to a license from Mastercard. PFS Card Services Ireland Limited, trading as PCSIL, is regulated by the Central Bank of Ireland"
    },    
]

export const IssuerStatementOptions = CarrierIssuerStatement.map(s => ({ ...s, display: s.name.substring(0,100).concat("...")}))