import React, { Fragment, useContext, useState } from 'react'
import Spinner from '../../../../components/spinner/Spinner';
import Popup from '../../../../components/dialog/Popup';
import SetupContext from '../../../../context/setup/SetupContext';
import FeesTree from './FeesTree';
import AddCurrencyFees from './AddCurrencyFees';
import ConsolidatedView from './ConsolidatedView';
import UnauthorizedPage from "../../../errors/UnauthorizedPage";
import SecurityClassificationError from "../../../errors/SecurityClassification";

const CurrencyFees: React.FC<any> = (props) => {
    const { issuerId, setupId,securityClassifications,canPageBeRendered,proccedAndLoad  } = props;
    const { currencyFees, loading } = useContext(SetupContext);
    const [openPopup, setOpenPopup] = useState(false);
    const [consolidatedView, setConsolidatedView] = useState(false);

    const readAccess = securityClassifications?.includes("Read");
    const createAccess = securityClassifications?.includes("Create");
    const updateAccess = securityClassifications?.includes("Update");

    if(!canPageBeRendered)
    {
        return (<div className="card-body" >< Spinner /> </div>)
    }

    if(!proccedAndLoad)
    {
        return <UnauthorizedPage withWrapper={false} />;
    }

    if(!readAccess){
        return (<SecurityClassificationError title="Read Classification Denied" pageName="Currency Fee" extraReference='Currency Fee' withWrapper={false}/>)
    }

    return (
        !loading ?
        <Fragment>
            <span>
                {
                    createAccess && <button type="button" className="btn btn-sm btn-success mb-1  mr-1" onClick={() => setOpenPopup(true)}>
                    <i className="fa fa-plus p-1" />
                    <span>Add New Currency Fees</span>
                </button>
                }

                { 
                    currencyFees && currencyFees.length > 0 && readAccess &&
                        <button type="button" className="btn button-control-warning btn-sm mb-1" onClick={() => setConsolidatedView(true)}>
                        <i className="fas fa-info-circle p-1" />
                        <span>Consolidated View</span>
                    </button>}
            </span>
            <table className="table table-borderless table-sm">
                <tbody>
                    {
                         currencyFees.map((item: any, idx: number) => (   
                            <Fragment key={idx}>
                                <tr>
                                    <td>
                                        <div className="form-inline">
                                            Currency<h5 className="pl-2 pt-2"><span className="badge bg-info">{item.currencyName}</span></h5>
                                        </div>
                                    </td>
                                </tr>

                                <tr data-widget="expandable-table" aria-expanded="false">

                                    <td>
                                        <i className="fas fa-caret-right fa-fw"></i>
                                        <span className="text-primary">Fees</span>
                                    </td>
                                </tr>
                                <tr className="expandable-body">
                                    <td>

                                        <div className="p-0 collapse">
                                            <table className="table table-hover table-borderless table-sm">
                                                <tbody>
                                                    <FeesTree data={item.fees.kyc} title="KYC Customers" customerType={'kyc'} issuerId={issuerId} setupId={setupId} currencyCode={item.currencyCode} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />

                                                    <FeesTree data={item.fees.kycLite} title="KYC Lite Customers" customerType={'kyclite'} issuerId={issuerId} setupId={setupId} currencyCode={item.currencyCode} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />

                                                    <FeesTree data={item.fees.sdd} title="SDD Customers" customerType={'sdd'} issuerId={issuerId} setupId={setupId} currencyCode={item.currencyCode} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />

                                                    <FeesTree data={item.fees.anonymous} title="Anonymous Customers" customerType={'anonymous'} issuerId={issuerId} setupId={setupId} currencyCode={item.currencyCode} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />

                                                </tbody>
                                            </table>
                                        </div>



                                    </td>
                                </tr>
                            </Fragment>
                        ))
                    }
                </tbody>

                {
                    createAccess && <Popup title="Add Currency Fees" openPopup={openPopup} setOpenPopup={setOpenPopup}>
                        <AddCurrencyFees setOpenPopup={setOpenPopup} issuerId={issuerId} setupId={setupId} currencyFees={currencyFees} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />
                    </Popup>
                }

                {
                    updateAccess && <Popup title="Currency Fees Consolidated View" openPopup={consolidatedView} setOpenPopup={setConsolidatedView} size='lg'>
                    <ConsolidatedView data={currencyFees} issuerId={issuerId} setupId={setupId} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}  />
                </Popup>
                }
            </table>
        </Fragment>
            : <Spinner />
    )
}

export default CurrencyFees
