import { AuthInitState } from "../../models/Auth";
import { AUTH_USER, CLEAR_ERRORS, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, SET_DISABLED } from "../types";

const AuthReducer = (state: AuthInitState, action: any) => {
  switch (action.type) {
    case LOGIN_SUCCESS:      
      localStorage.setItem("user", JSON.stringify(action.payload));      
      return {
        ...state,
        isAuthenticated: true,
        user: null,
        loading: false,
      };

    case AUTH_USER:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
        loading: false,        
      };

    case LOGIN_FAIL:
      localStorage.removeItem("user");
      return {
        ...state,
        user: null,
        token: null,
        isAuthenticated: false,
        loading: false,
        error: action.payload.error,
        disabled: false
      }
    case LOGOUT:
      localStorage.removeItem("user");
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        loading: false,
        token: null
      };
    
    case SET_DISABLED:
      return {
        ...state,
        disabled: true
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export default AuthReducer;
