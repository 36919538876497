import { Fragment } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../layout/Header";
import Menu from "../layout/sidebar/Sidebar";

const Wrapper = (props: any) => {       
  
        
  return (
    <Fragment>
      <Header />
      <Menu />
      {/* <Menu /> */}
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <span className="h4 pull-left" style={{ color: "#0367A5" }}>
                  {props.title}
                </span>
                <span style={{ color: "#288ECD" }}>
                  {props.subTitle && (
                    <i className="fas fa-angle-double-right m-2"></i>
                  )}
                  {props.subTitle}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card background">{props.children}</div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="top-right" />
      </div>
    </Fragment>
  );
};

export default Wrapper;
