import React, { useContext, useEffect,useState } from 'react';
import Wrapper from '../../Wrapper'
import * as data from './dashboardData'
import { Card, Container, Row, Col } from "react-bootstrap"
import RoleBasedAccessControlContext from '../../../context/rolebasedaccesscontrol/RoleBasedAccessControlContext';
import NoPermissionsPage from "../../errors/NoPermissions";
import Spinner from '../../../components/spinner/Spinner';

const Dashboard = () => {

   const {getPromisedAllowedNavigationItems}  = useContext(RoleBasedAccessControlContext); 
   const [menuItems, setMenuItems] = useState<string[]>([]);
   const [menuItemsLoaded, setMenuItemsLoaded] = useState(false); 

   useEffect(() => {
        getPromisedAllowedNavigationItems().then((result: string[]) => {
            setMenuItemsLoaded(true);
            setMenuItems(result);
        }).catch((error: any) => {
            setMenuItemsLoaded(false);
          });
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    if (!menuItemsLoaded) 
    {
        return (<Wrapper title="Home Page">
            <Container fluid>
                <Spinner />
            </Container>
        </Wrapper>);
    }

    const notAvailNavigations : string[] = [];

    if(menuItems === undefined || menuItems.length === 0) 
    {
        return (<NoPermissionsPage />);
    }
    else
    {
        const mappedItems : string[] = [];

        data.DashboardCards.forEach((element) => {
            mappedItems.push(element.accessMapper.toLowerCase());     
        });

        menuItems.forEach((element) => {
            if (!mappedItems.includes(element) && element !== "home" && element !== "issuer") {
                notAvailNavigations.push(element);
            }
        });

        if(menuItems.length === 1)
        { 
            if(menuItems[0] === "home" && notAvailNavigations.length === 0)
            {
                return (<NoPermissionsPage />);
            }
        }
    }
   
    return (
        <Wrapper title="Home Page">
            <Container fluid>
                <Row >
                {        
                        data.DashboardCards.filter((item) => menuItems.includes(item.accessMapper.toLowerCase())).map((card, index: number) => (
                        <Col lg="3" sm="6" className="card-col" key={index} onClick={()=>window.location.href = card.url} >
                            <Card className="card-stats">
                                <Card.Body>
                                    <Row>
                                        <Col xs="5">
                                            <div className="icon-big text-center icon-warning dash-icon">
                                                <i className={card.icons.join(" ")}></i>
                                            </div>
                                        </Col>
                                        <Col xs="7">
                                            <div className="numbers">
                                                <p className="card-category">{card.name}</p>
                                                <Card.Title as="h4">{card.value}</Card.Title>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer>
                                    <hr></hr>
                                    <div className="stats">
                                        <i className="fas fa-arrow-circle-right mr-1" onClick={()=>window.location.href = card.url}></i>
                                        {card.action}
                                    </div>
                                </Card.Footer>
                            </Card>
                        </Col>
                        ))
                    }
                    {        
                        notAvailNavigations.map((card, index: number) => (
                        <Col lg="3" sm="6" key={index} className="card-col">
                            <Card className="card-stats">
                                <Card.Body>
                                    <Row>
                                        <Col xs="5">
                                            <div className="icon-big text-center icon-warning dash-icon">
                                                <i className="fas fa-solid fa-clipboard"></i>
                                            </div>
                                        </Col>
                                        <Col xs="7">
                                            <div className="numbers">
                                                <p className="card-category">{card.toLocaleUpperCase()}</p>
                                                <Card.Title as="h4">{"Not Avaliable"}</Card.Title>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer>
                                    <hr></hr>
                                    <div className="stats">
                                        <i className="fas fa-arrow-circle-right mr-1"></i>
                                        {"Coming Soon"}
                                    </div>
                                </Card.Footer>
                            </Card>
                        </Col>
                        ))
                    }
                </Row>
            </Container>
        </Wrapper>
    )
}

export default Dashboard

