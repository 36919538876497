export const accountsColumns = [
  {
    id: 1,
    name: "Account Id",
    selector: "accountId",
    sortable: true,
  },
  {
    id: 2,
    name: "Account Name",
    selector: "accountName",
    sortable: true,
  },
  {
    id: 3,
    name: "Setup Id",
    selector: "setupId",
    sortable: true,
  },
  {
    id: 4,
    name: "Date Created (dd-MM-yyyy)",
    selector: "created",
    sortable: true,
  },
];

export const fxColumns = [
  {
    id: 1,
    name: "Source Currency",
    selector: "sourceCurrency",
    sortable: true,
  },
  {
    id: 2,
    name: "Buying Currency",
    selector: "buyingCurrency",
    sortable: true,
  },
  {
    id: 3,
    name: "Exchange Rate",
    selector: "exchangeRate",
    sortable: false,
  },
  {
    id: 4,
    name: "Provider",
    selector: "provider",
    sortable: true,
  },
];

export const setupColumns = [
  {
    id: 1,
    name: "Setup ID",
    selector: "setupId",
    sortable: true,
  },
  {
    id: 2,
    name: "Setup Name",
    selector: "setupName",
    sortable: true,
  },
  {
    id: 3,
    name: "Country",
    selector: "setupCountry",
    sortable: true,
  },
  {
    id: 4,
    name: "Fee Mode",
    selector: "negativeFeeMode",
    sortable: true,
  },
  {
    id: 5,
    name: "Balance Mode",
    selector: "balanceMode",
    sortable: true,
  },
  {
    id: 6,
    name: "Legal Entity",
    selector: "legalEntity",
    sortable: true,
  },
];

export const issuerColumns = [
  {
    id: 1,
    name: "Issuer ID",
    selector: "issuerId",
    sortable: true,
  },
  {
    id: 2,
    name: "Issuer Name",
    selector: "issuerName",
    sortable: true,
  },
  {
    id: 3,
    name: "Date Created (dd-MM-yyyy)",
    selector: "created",
    sortable: true,
  },
];

export const ContactlessLimitsColumns = [
  {
    id: 1,
    name: "Currency",
    selector: "currency",
    sortable: true,
    thClass: "",
  },
  {
    id: 2,
    name: "Transaction Limit",
    selector: "transactionLimit",
    sortable: true,
    thClass: "text-right",
  },
  {
    id: 2,
    name: "PIN Required",
    selector: "pinRequired",
    sortable: true,
    thClass: "text-right",
  },
];

export const PANConfigsColumns = [
  {
    id: 1,
    name: "BIN",
    selector: "bin",
    sortable: true,
  },
  {
    id: 2,
    name: "Sub BIN",
    selector: "subBin",
    sortable: true,
  },
  {
    id: 3,
    name: "PAN Length",
    selector: "panLength",
    sortable: true,
  },
  {
    id: 4,
    name: "Currency",
    selector: "defaultCurrency",
    sortable: true,
  },
  {
    id: 5,
    name: "Months to Expire",
    selector: "monthsToExpire",
    sortable: true,
  },
  {
    id: 6,
    name: "Date Created (dd-MM-yyyy)",
    selector: "created",
    sortable: true,
  },
];

export const cardHolderColumns = [
  {
    id: 1,
    name: "Address",
    selector: "avsAddress",
    sortable: false,
  },
  {
    id: 2,
    name: "Postcode",
    selector: "avsPostcode",
    sortable: false,
  },
  {
    id: 3,
    name: "ID",
    selector: "cardholderId",
    sortable: false,
  },
  {
    id: 4,
    name: "Card Holders",
    selector: "cards",
    sortable: false,
  },
  {
    id: 5,
    name: "Date Created (dd-MM-yyyy)",
    selector: "created",
    sortable: false,
  },
  {
    id: 6,
    name: "Email",
    selector: "email",
    sortable: false,
  },
  {
    id: 7,
    name: "Mobile #",
    selector: "mobileNumber",
    sortable: false,
  },
  {
    id: 8,
    name: "Name",
    selector: "name",
    sortable: false,
  },
  {
    id: 9,
    name: "Risk Level #",
    selector: "riskLevel",
    sortable: false,
  },
];

export const cardColumns = [
  {
    id: 1,
    name: "Status",
    selector: "cardStatus",
    sortable: false,
  },
  {
    id: 2,
    name: "Type",
    selector: "cardType",
    sortable: false,
  },
  {
    id: 3,
    name: "Name",
    selector: "printName",
    sortable: false,
  },
  {
    id: 4,
    name: "Date Created",
    selector: "created",
    sortable: false,
  },
  {
    id: 5,
    name: "Pan",
    selector: "pan",
    sortable: false,
  },
  {
    id: 6,
    name: "Wallet Id",
    selector: "defaultWalletId",
    sortable: false,
  },
  {
    id: 7,
    name: "Card Id",
    selector: "cardId",
    sortable: false,
  },
  {
    id: 8,
    name: "CVV",
    selector: "cvv",
    sortable: false,
  },
];

export const cardVendorColumns = [
  {
    id: 1,
    name: "Card Style",
    selector: "vendorSetupId",
    sortable: true,
  },
  {
    id: 2,
    name: "Vendor Name",
    selector: "activeVendor",
    sortable: true,
  },
  {
    id: 3,
    name: "", // delete column
    selector: "",
    sortable: false,
  }
];


export const transactionColumns = [
  {
    id: 1,
    name: "Transaction Id",
    selector: "transactionId",
    sortable: true,
  },
  {
    id: 2,
    name: "Transaction Date",
    selector: "transactionDate",
    sortable: true,
  },
  {
    id: 3,
    name: "Auth Response Id",
    selector: "authResponseId",
    sortable: true,
  },
  
  {
    id: 4,
    name: "Transaction Type",
    selector: "transactionType",
    sortable: true,
  },
  {
    id: 5,
    name: "Authorization Status",
    selector: "authorizationStatus",
    sortable: true,
  },
  {
    id: 6,
    name: "Amount",
    selector: "amount",
    sortable: true,
  },
  {
    id: 7,
    name: "Currency",
    selector: "currency",
    sortable: true,
  },
  {
    id: 8,
    name: "Merchant Name",
    selector: "merchantName",
    sortable: true,
  },
  {
    id: 9,
    name: "Cardholder Id",
    selector: "issuerCardId",
    sortable: true,
  },
  {
    id: 10,
    name: "Masked Pan",
    selector: "maskedPan",
    sortable: true,
  },
  {
    id: 11,
    name: "Currency Alpha Code",
    selector: "currencyAlphaCode",
    sortable: true,
  },
  {
    id: 12,
    name: "",
    selector: "",
    sortable: false,
  },
  {
    id: 13,
    name: "",
    selector: "",
    sortable: false,
  },
];
