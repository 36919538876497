import React, { Fragment, useContext, useState,useEffect } from 'react'
import Popup from '../../../../components/dialog/Popup';
import Spinner from '../../../../components/spinner/Spinner';
import RoleBasedAccessControlContext from '../../../../context/rolebasedaccesscontrol/RoleBasedAccessControlContext';
import SetupContext from '../../../../context/setup/SetupContext';
import AddCurrencyLimit from './AddCurrencyLimit';
import ConsolidatedView from './ConsolidatedView';
import LimitsTree from './LimitsTree';
import UnauthorizedPage from "../../../errors/UnauthorizedPage";
import SecurityClassificationError from "../../../errors/SecurityClassification";

const CurrencyLimits = (props: any) => {
    const { issuerId, setupId,securityClassifications,canPageBeRendered,proccedAndLoad } = props;
    const { currencyLimits, loading } = useContext(SetupContext);
    const [openPopup, setOpenPopup] = useState(false);
    const [consolidatedView, setConsolidatedView] = useState(false);

    const readAccess = securityClassifications?.includes("Read");
    const createAccess = securityClassifications?.includes("Create");

    if(!canPageBeRendered)
    {
        return (<div className="card-body" >< Spinner /> </div>)
    }

    if(!proccedAndLoad)
    {
        return <UnauthorizedPage withWrapper={false} />;
    }

    if(!readAccess){
        return (<SecurityClassificationError title="Read Classification Denied" pageName="Curreny Limits" extraReference='Curreny Limits' withWrapper={false}/>)
    }
    
    return (
        !loading ?
            <Fragment>
                <span>
                    {
                        createAccess && <button type="button" className="btn btn-sm btn-success mb-1  mr-1" onClick={() => setOpenPopup(true)}>
                        <i className="fa fa-plus p-1" />
                        <span>Add New Currency Limit</span>
                        </button>
                    }
                    { currencyLimits && currencyLimits.length > 0 && readAccess &&
                    <button type="button" className="btn button-control-warning btn-sm mb-1" onClick={() => setConsolidatedView(true)}>
                        <i className="fas fa-info-circle p-1" />
                        <span>Consolidated View</span>
                    </button>}
                </span>
                <table className="table table-borderless table-sm">
                    <tbody>

                        {
                            currencyLimits.map((item: any, idx: number) => (
                                <Fragment key={idx}>
                                    <tr>
                                        <td>
                                            <div className="form-inline">
                                                Currency<h5 className="pl-2 pt-2"><span className="badge bg-info">{item.currencyName}</span></h5>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr data-widget="expandable-table" aria-expanded="false">
                                        <td>
                                            <i className="fas fa-caret-right fa-fw"></i>
                                            Limits
                                        </td>
                                    </tr>
                                    <tr className="expandable-body">
                                        <td>
                                            <div className="p-0 collapse">
                                                <table className="table table-hover table-borderless table-sm">
                                                    <tbody>
                                                        <LimitsTree data={item.limits.kyc} title="KYC Customers" customerType={'kyc'} issuerId={issuerId} setupId={setupId} currencyCode={item.currencyCode} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />

                                                        <LimitsTree data={item.limits.kycLite} title="KYC Lite Customers" customerType={'kyclite'} issuerId={issuerId} setupId={setupId} currencyCode={item.currencyCode} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}/>

                                                        <LimitsTree data={item.limits.sdd} title="SDD Customers" customerType={'sdd'} issuerId={issuerId} setupId={setupId} currencyCode={item.currencyCode} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}/>

                                                        <LimitsTree data={item.limits.anonymous} title="Anonymous Customers" customerType={'anonymous'} issuerId={issuerId} setupId={setupId} currencyCode={item.currencyCode} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>
                            ))
                        }
                    </tbody>

                    {
                        createAccess && <Popup title="Add Currency Limit" openPopup={openPopup} setOpenPopup={setOpenPopup}>
                            <AddCurrencyLimit setOpenPopup={setOpenPopup} issuerId={issuerId} setupId={setupId} currencyLimits={currencyLimits} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}/>
                        </Popup>
                    }
                    {
                        readAccess && <Popup title="Currency Limits Consolidated View" openPopup={consolidatedView} setOpenPopup={setConsolidatedView} size="lg">
                            <ConsolidatedView data={currencyLimits} issuerId={issuerId} setupId={setupId} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />
                        </Popup>
                    }
                </table>
            </Fragment>
            : <Spinner />
    )
}

export default CurrencyLimits
