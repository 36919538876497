import { Fragment, useState } from 'react';
import { formatStringIfNullOrEmpty } from '../../../../models/Helper';
import PeriodicFee from './PeriodicFee';
import RegionalFee from './RegionalFee';
import { Link } from "react-router-dom";
import Popup from '../../../../components/dialog/Popup';
import UpdateFees from './UpdateFees';

const FeesTree = (props: any) => {
    const { title, data, issuerId, setupId, customerType, currencyCode, isExpanded,securityClassifications,canPageBeRendered,proccedAndLoad  } = props;
    const [openPopup, setOpenPopup] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [feesType, setFeesType] = useState('');

    const updateAccess = securityClassifications?.includes("Update");

    return (
        <Fragment>
            <tr data-widget={isExpanded ? "" : "expandable-table"} aria-expanded={isExpanded ? "true" : "false"}>
                <td>
                    {isExpanded ? null : <i className="fas fa-caret-right fa-fw"/>}
                    <span className="text-primary">{title}</span>
                </td>
            </tr>

            <tr className="expandable-body">
                <td style={{ width: "40%" }}>
                    <div className={`p-0  ${isExpanded ? 'col-md-12' : 'collapse col-md-6'}`} >
                        <table className="table table-bordered table-hover table-sm" >
                            <thead className="table-header">
                                <tr>
                                    <th>TYPE</th>
                                    <th className="text-right">Fixed</th>
                                    <th className="text-right">(%)</th>
                                    <th className="text-right">Min Fee</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td >
                                    { updateAccess && <Link
                                        to="#"
                                        onClick={() => {
                                            setOpenPopup(true);
                                            setSelectedData(data['decline']);
                                            setFeesType('decline');
                                        }}
                                        >
                                        <i className="fas fa-pen-square" />
                                    </Link>
                                    }
                                        &emsp; Decline
                                    </td>
                                    <td className="text-right">{
                                        !data?.decline ? 0 : (
                                            !data?.decline.fixedFee ? 0 : formatStringIfNullOrEmpty(data.decline.fixedFee / 100)
                                        )}
                                    </td>
                                    <td className="text-right">{
                                        !data?.decline  ? 0 : (
                                            !data?.decline.percentileMarkup  ? 0 : data.decline.percentileMarkup
                                        )}
                                    </td>
                                    <td className="text-right">{
                                        !data?.decline ? 0 : (
                                            !data?.decline.minimumFee  ? 0 : formatStringIfNullOrEmpty(data.decline.minimumFee / 100)
                                        )}
                                    </td>
                                </tr>

                                <tr >
                                    <td>
                                    { updateAccess && <Link
                                        to="#"
                                        onClick={() => {
                                            setOpenPopup(true);
                                            setSelectedData(data['insufficientFunds']);
                                            setFeesType('insufficientFunds');
                                        }}
                                        >
                                        <i className="fas fa-pen-square" />
                                    </Link>
                                    }
                                        &emsp; Insufficient Funds
                                    </td>
                                    <td className="text-right">{
                                        !data?.insufficientFunds  ? 0 : (
                                            !data?.insufficientFunds.fixedFee  ? 0 : formatStringIfNullOrEmpty(data.insufficientFunds.fixedFee / 100)
                                        )}
                                    </td>
                                    <td className="text-right">{
                                        !data?.insufficientFunds ? 0 : (
                                            !data?.insufficientFunds.percentileMarkup  ? 0 : data.insufficientFunds.percentileMarkup
                                        )}
                                    </td>
                                    <td className="text-right">{
                                        !data?.insufficientFunds ? 0 : (
                                            !data?.insufficientFunds.minimumFee  ? 0 : formatStringIfNullOrEmpty(data.insufficientFunds.minimumFee / 100)
                                        )}
                                    </td>                                    
                                </tr>

                                <tr >
                                    <td>
                                    { updateAccess && <Link
                                        to="#"
                                        onClick={() => {
                                            setOpenPopup(true);
                                            setSelectedData(data['refund']);
                                            setFeesType('refund');
                                        }}
                                        >
                                        <i className="fas fa-pen-square" />
                                    </Link>
}
                                        &emsp; Refund
                                    </td>
                                    <td className="text-right">{
                                        !data?.refund  ? 0 : (
                                            !data?.refund.fixedFee  ? 0 : formatStringIfNullOrEmpty(data.refund.fixedFee / 100)
                                        )}
                                    </td>
                                    <td className="text-right">{
                                        !data?.refund ? 0 : (
                                            !data?.refund.percentileMarkup  ? 0 : data.refund.percentileMarkup 
                                        )}
                                    </td>
                                    <td className="text-right">{
                                        !data?.refund ? 0 : (
                                            !data?.refund.minimumFee ? 0 : formatStringIfNullOrEmpty(data.refund.minimumFee / 100)
                                        )}
                                    </td>                                    
                                </tr>

                                <tr >
                                    <td>
                                    { updateAccess && <Link
                                        to="#"
                                        onClick={() => {
                                            setOpenPopup(true);
                                            setSelectedData(data['foreignExchange']);
                                            setFeesType('fx');
                                        }}
                                        >
                                        <i className="fas fa-pen-square" />
                                    </Link>
}
                                        &emsp; Foreign Exchange
                                    </td>
                                    <td className="text-right">{
                                        !data?.foreignExchange  ? 0 : (
                                            !data?.foreignExchange.fixedFee ? 0 : formatStringIfNullOrEmpty(data.foreignExchange.fixedFee / 100)
                                        )}
                                    </td>
                                    <td className="text-right">{
                                        !data?.foreignExchange  ? 0 : (
                                            !data?.foreignExchange.percentileMarkup ? 0 : data.foreignExchange.percentileMarkup
                                        )}
                                    </td>
                                    <td className="text-right">{
                                        !data?.foreignExchange ? 0 : (
                                            !data?.foreignExchange.minimumFee ? 0 : formatStringIfNullOrEmpty(data.foreignExchange.minimumFee / 100)
                                        )}
                                    </td> 
                                </tr>

                                <tr >
                                    <td>
                                    { updateAccess && <Link
                                        to="#"
                                        onClick={() => {
                                            setOpenPopup(true);
                                            setSelectedData(data['chargeback']);
                                            setFeesType('chargedback');
                                        }}
                                        >
                                        <i className="fas fa-pen-square" />
                                    </Link>
}
                                        &emsp; Charge Back
                                    </td>
                                    <td className="text-right">{
                                        !data?.chargeback  ? 0 : (
                                            !data?.chargeback.fixedFee  ? 0 : formatStringIfNullOrEmpty(data.chargeback.fixedFee / 100)
                                        )}
                                    </td>
                                    <td className="text-right">{
                                        !data?.chargeback  ? 0 : (
                                            !data?.chargeback.percentileMarkup  ? 0 : data.chargeback.percentileMarkup
                                        )}
                                    </td>
                                    <td className="text-right">{
                                        !data?.chargeback? 0 : (
                                            !data?.chargeback.minimumFee  ? 0 : formatStringIfNullOrEmpty(data.chargeback.minimumFee / 100)
                                        )}
                                    </td> 
                                </tr>

                                <tr >
                                    <td colSpan={4}>
                                        Money Send
                                    </td>
                                </tr>
                                <PeriodicFee title="Incoming"
                                    customerType={customerType} issuerId={issuerId} setupId={setupId} currencyCode={currencyCode} feesType={'moneysend'} subType={'incoming'}
                                    data={
                                    data.moneySend !== null ? 
                                    data.moneySend.incoming : null
                                } securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}/>
                                <PeriodicFee title="Outgoing"
                                    customerType={customerType} issuerId={issuerId} setupId={setupId} currencyCode={currencyCode} feesType={'moneysend'} subType={'outgoing'}
                                     data={
                                    data.moneySend !== null ? 
                                    data.moneySend.outgoing : null
                                } securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />
                                                                
                                <RegionalFee title="ATM" data={data.atm} customerType={customerType} issuerId={issuerId} setupId={setupId} currencyCode={currencyCode} feesType={'atm'} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />
                                <RegionalFee title="Balance Inquiry" data={data.balanceInquiry} customerType={customerType} issuerId={issuerId} setupId={setupId} currencyCode={currencyCode} feesType={'balanceinquiry'} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />
                                <RegionalFee title="PIN Change" data={data.pinChange} customerType={customerType} issuerId={issuerId} setupId={setupId} currencyCode={currencyCode} feesType={'pinchange'} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />
                                <RegionalFee title="POS" data={data.pos} customerType={customerType} issuerId={issuerId} setupId={setupId} currencyCode={currencyCode} feesType={'pos'} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}/>
                                <RegionalFee title="MOTO" data={data.moto} customerType={customerType} issuerId={issuerId} setupId={setupId} currencyCode={currencyCode} feesType={'moto'} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}/>
                                <RegionalFee title="E-Commerce" data={data.eCommerce} customerType={customerType} issuerId={issuerId} setupId={setupId} currencyCode={currencyCode} feesType={'ecommerce'} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}/>
                                <RegionalFee title="Cash Advance" data={data.cashAdvance} customerType={customerType} issuerId={issuerId} setupId={setupId} currencyCode={currencyCode} feesType={'cashadvance'} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />
                                <RegionalFee title="Quasi" data={data.quasi} customerType={customerType} issuerId={issuerId} setupId={setupId} currencyCode={currencyCode} feesType={'quasi'} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}/>
                                <RegionalFee title=" Cash Back" data={data.cashback} customerType={customerType} issuerId={issuerId} setupId={setupId} currencyCode={currencyCode} feesType={'cashback'} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}/>

                            </tbody>
                            { updateAccess && <Popup title="Update Currency Fees" openPopup={openPopup} setOpenPopup={setOpenPopup}>
                                <UpdateFees setOpenPopup={setOpenPopup} data={selectedData} customerType={customerType} issuerId={issuerId} setupId={setupId} currencyCode={currencyCode} feesType={feesType} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}/>
                            </Popup>
                            }
                        </table>
                    </div>
                </td>
            </tr>
        </Fragment>
    )
}

export default FeesTree
