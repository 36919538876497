import { useState, useEffect } from "react";
import Switch from "../../../../components/switch";
import { vendorTypes } from "../../../../models/enum/vendorTypes";
import { TInstantIssuePacks } from "../../../../models/ICardVendor";

const InstantIssuePacks = ({ instantIssuePacksData, handleChanges }:  {instantIssuePacksData:any, handleChanges:any}) => {
  const [instantIssuePacks, setinstantIssuePacks] =
    useState<TInstantIssuePacks>(instantIssuePacksData);  

  useEffect(() => {
    setinstantIssuePacks(instantIssuePacksData);
  }, [instantIssuePacksData]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      const { id, value} = e.currentTarget         
      handleChanges(id, value, vendorTypes.INSTANT_ISSUE_PACKS )         
  }; 
  
  const handleToggle = (id: string, value: boolean) => {        
    handleChanges(id, value, vendorTypes.INSTANT_ISSUE_PACKS )      
  };

  return (
    <div className="card-body">
      <div className="row">
        <div className="col-md-12">
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">
              Instant Issue Pack
            </label>
            <div className="col-sm-6 pt-1" title="Instant Issuer Pack">
              <Switch
                id="instantIssuePack"
                isOn={instantIssuePacks?.instantIssuePack || false}
                handleToggle={handleToggle}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Pack Insert</label>
            <div className="col-sm-6 pt-1" title="Pack Insert">
              <Switch
                id="packInsert"
                isOn={instantIssuePacks?.packInsert || false}
                handleToggle={handleToggle}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Dynamic Barcode</label>
            <div className="col-sm-6 pt-1" title="Dynamic Barcode">
              <Switch
                id="dynamicBarcode"
                isOn={instantIssuePacks?.dynamicBarcode || false}
                handleToggle={handleToggle}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Retail Barcode</label>
            <div className="col-sm-6 pt-1" title=" Retail Barcode">
              <Switch
                id="retailBarcode"
                isOn={instantIssuePacks?.retailBarcode || false}
                handleToggle={handleToggle}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">
              Retail Barcode Text
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="retailBarcodeText"
                name="retailBarcodeText"
                value={instantIssuePacks?.retailBarcodeText || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Expiry On Pack</label>
            <div className="col-sm-6 pt-1" title="Expiry Date tagging">
              <Switch
                id="expiryOnPack"
                isOn={instantIssuePacks?.expiryOnPack || false}
                handleToggle={handleToggle}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstantIssuePacks;
