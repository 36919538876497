export const LegalEntity = [
    {
        id: 0,
        name: "PFSL"
    },
    {
        id: 1,
        name: "PCSIL"
    },
    {
        id: 2,
        name: "Internal"
    }
]

