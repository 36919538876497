import React from 'react';
import Wrapper from '../Wrapper'


interface UnauthorizedPageProps {
    withWrapper?: boolean;
  }
  
  const UnauthorizedPage: React.FC<UnauthorizedPageProps> = ({
    withWrapper = true,
  }) => {

    if(!withWrapper){
        return (<div className="card-body">
            <div className="error-page">
                <h2 className="headline text-warning"> 403</h2>
                <div className="error-content">
                    <h3><i className="fas fa-exclamation-triangle text-warning" /> Unauthorized Access.</h3>
                    <p>You are not authorized to access this content. </p>
                    <p>If you believe this is a mistake, please contact the system administrator or the support team for further assistance.</p>
                </div>
            </div>
        </div>
        )
    }
    else{
        return (
            <Wrapper title="Error Page" subTitle="Unauthorized Access">
                <div className="card-body">
                    <div className="error-page">
                        <h2 className="headline text-warning"> 403</h2>
                        <div className="error-content">
                            <h3><i className="fas fa-exclamation-triangle text-warning" /> Unauthorized Access.</h3>
                            <p>You are not authorized to access this page</p>
                            <p>If you believe this is a mistake, please contact the system administrator or the support team for further assistance.</p>
                            <p>Thank you for your understanding <a href="/">return to Home Page</a>.</p>
                        </div>
                    </div>
                </div>
            </Wrapper>
        )
    }
}

export default UnauthorizedPage;