export const msalConfig = {    
  auth: {
    clientId: window.__RUNTIME_CONFIG__.REACT_APP_AZURE_CLIENT_ID || '',
    authority: "https://login.microsoftonline.com/" + window.__RUNTIME_CONFIG__.REACT_APP_AZURE_TENANT_ID, 
    redirectUri: window.location.origin + "/blank.html",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["api://trace-portal/user_impersonation"],
};
