import { useAccount, useMsal } from "@azure/msal-react";
import React, { Fragment, useContext, useEffect, useState } from "react";
import RoleBasedAccessControlContext from "../context/rolebasedaccesscontrol/RoleBasedAccessControlContext";

const Header = (props: any) => {  
  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0]);  
  const [isAccountPressed, setIsAccountPressed] = useState(false);
  const [isLogoutPressed, setIsLogoutPressed] = useState(false);
 
  useEffect(() => { 
    if (isLogoutPressed === true) { 
      instance.logout();
    }
  },[isLogoutPressed]);

  return (
    <Fragment>
      {/* Navbar */}
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="pushmenu"
              href="#!"
              role="button"
            >
              <i className="fas fa-bars" />
            </a>
          </li>
        </ul>
        {/* SEARCH FORM
                <form className="form-inline ml-3">
                    <div className="input-group input-group-sm">
                        <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                        <div className="input-group-append">
                            <button className="btn btn-navbar" type="submit">
                                <i className="fas fa-search" />
                            </button>
                        </div>
                    </div>
                </form> */}
        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown">
            <a
              className="nav-link"
              href="#!"
              id="navbarDropdownMenuLink"
              aria-expanded="false"
              onClick={() =>setIsAccountPressed(!isAccountPressed)}
            >
              { `Welcome, ${account?.name}`}
            </a>
            {isAccountPressed ? <a id="logout" className='logout-link' href="#!" onClick={() =>setIsLogoutPressed(!isLogoutPressed)}>Logout</a> : ''  }
            {/*<div className="dropdown-menu p-0 m-0" aria-labelledby="navbarDropdownMenuLink">
                             <a className="dropdown-item" href="#!"><i className="fas fa-user pr-1" />My Profile</a>
                            <div className="dropdown-divider m-0" /> 
                            <a className="dropdown-item" href="/login" onClick={() => logOut()}><i className="fas fa-power-off pr-1" /> Log-out</a>
                        </div>*/}
          </li>
          <li className="nav-item">
            <img
              src={`${process.env.PUBLIC_URL}/dist/img/User.png`}
              className="img-circle elevation-2 user-image"
              alt="User"
            />
          </li>
        </ul>
      </nav>
      {/* /.navbar */}
    </Fragment>
  );
};

export default Header;
