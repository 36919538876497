export const ActiveVendor = [
    {
        name: "Internal"
    },
    {
        name: "Thames"
    },
    {
        name: "Thales"
    },
    {
        name: "TagSystems"
    }
]

