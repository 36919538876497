export const GetCountryName = (countryCode: string) => {
  return ISO3166.filter((item) => item.alpha_3 === countryCode)[0].name;
};

export const GetCountries = () => {
  let countryList = ISO3166.map((item: any) => ({
    id: item.alpha_3,
    name: item.name,
  }));

  return countryList;
};

const ISO3166 = [
  { name: "Ireland", alpha_3: "IRL", country_code: "372" },
  { name: "Afghanistan", alpha_3: "AFG", country_code: "004" },
  { name: "Aland Islands", alpha_3: "ALA", country_code: "248" },
  { name: "Albania", alpha_3: "ALB", country_code: "008" },
  { name: "Algeria", alpha_3: "DZA", country_code: "012" },
  { name: "American Samoa", alpha_3: "ASM", country_code: "016" },
  { name: "Andorra", alpha_3: "AND", country_code: "020" },
  { name: "Angola", alpha_3: "AGO", country_code: "024" },
  { name: "Anguilla", alpha_3: "AIA", country_code: "660" },
  { name: "Antarctica", alpha_3: "ATA", country_code: "010" },
  { name: "Antigua and Barbuda", alpha_3: "ATG", country_code: "028" },
  { name: "Argentina", alpha_3: "ARG", country_code: "032" },
  { name: "Armenia", alpha_3: "ARM", country_code: "051" },
  { name: "Aruba", alpha_3: "ABW", country_code: "533" },
  { name: "Australia", alpha_3: "AUS", country_code: "036" },
  { name: "Austria", alpha_3: "AUT", country_code: "040" },
  { name: "Azerbaijan", alpha_3: "AZE", country_code: "031" },
  { name: "Bahamas", alpha_3: "BHS", country_code: "044" },
  { name: "Bahrain", alpha_3: "BHR", country_code: "048" },
  { name: "Bangladesh", alpha_3: "BGD", country_code: "050" },
  { name: "Barbados", alpha_3: "BRB", country_code: "052" },
  { name: "Belarus", alpha_3: "BLR", country_code: "112" },
  { name: "Belgium", alpha_3: "BEL", country_code: "056" },
  { name: "Belize", alpha_3: "BLZ", country_code: "084" },
  { name: "Benin", alpha_3: "BEN", country_code: "204" },
  { name: "Bermuda", alpha_3: "BMU", country_code: "060" },
  { name: "Bhutan", alpha_3: "BTN", country_code: "064" },
  {
    name: "Bolivia (Plurinational State of)",
    alpha_3: "BOL",
    country_code: "068",
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    alpha_3: "BES",
    country_code: "535",
  },
  { name: "Bosnia and Herzegovina", alpha_3: "BIH", country_code: "070" },
  { name: "Botswana", alpha_3: "BWA", country_code: "072" },
  { name: "Bouvet Island", alpha_3: "BVT", country_code: "074" },
  { name: "Brazil", alpha_3: "BRA", country_code: "076" },
  {
    name: "British Indian Ocean Territory",
    alpha_3: "IOT",
    country_code: "086",
  },
  { name: "Brunei Darussalam", alpha_3: "BRN", country_code: "096" },
  { name: "Bulgaria", alpha_3: "BGR", country_code: "100" },
  { name: "Burkina Faso", alpha_3: "BFA", country_code: "854" },
  { name: "Burundi", alpha_3: "BDI", country_code: "108" },
  { name: "Cabo Verde", alpha_3: "CPV", country_code: "132" },
  { name: "Cambodia", alpha_3: "KHM", country_code: "116" },
  { name: "Cameroon", alpha_3: "CMR", country_code: "120" },
  { name: "Canada", alpha_3: "CAN", country_code: "124" },
  { name: "Cayman Islands", alpha_3: "CYM", country_code: "136" },
  { name: "Central African Republic", alpha_3: "CAF", country_code: "140" },
  { name: "Chad", alpha_3: "TCD", country_code: "148" },
  { name: "Chile", alpha_3: "CHL", country_code: "152" },
  { name: "China", alpha_3: "CHN", country_code: "156" },
  { name: "Christmas Island", alpha_3: "CXR", country_code: "162" },
  { name: "Cocos (Keeling) Islands", alpha_3: "CCK", country_code: "166" },
  { name: "Colombia", alpha_3: "COL", country_code: "170" },
  { name: "Comoros", alpha_3: "COM", country_code: "174" },
  { name: "Congo", alpha_3: "COG", country_code: "178" },
  {
    name: "Congo, Democratic Republic of the",
    alpha_3: "COD",
    country_code: "180",
  },
  { name: "Cook Islands", alpha_3: "COK", country_code: "184" },
  { name: "Costa Rica", alpha_3: "CRI", country_code: "188" },
  { name: "Côte d'Ivoire", alpha_3: "CIV", country_code: "384" },
  { name: "Croatia", alpha_3: "HRV", country_code: "191" },
  { name: "Cuba", alpha_3: "CUB", country_code: "192" },
  { name: "Curaçao", alpha_3: "CUW", country_code: "531" },
  { name: "Cyprus", alpha_3: "CYP", country_code: "196" },
  { name: "Czechia", alpha_3: "CZE", country_code: "203" },
  { name: "Denmark", alpha_3: "DNK", country_code: "208" },
  { name: "Djibouti", alpha_3: "DJI", country_code: "262" },
  { name: "Dominica", alpha_3: "DMA", country_code: "212" },
  { name: "Dominican Republic", alpha_3: "DOM", country_code: "214" },
  { name: "Ecuador", alpha_3: "ECU", country_code: "218" },
  { name: "Egypt", alpha_3: "EGY", country_code: "818" },
  { name: "El Salvador", alpha_3: "SLV", country_code: "222" },
  { name: "Equatorial Guinea", alpha_3: "GNQ", country_code: "226" },
  { name: "Eritrea", alpha_3: "ERI", country_code: "232" },
  { name: "Estonia", alpha_3: "EST", country_code: "233" },
  { name: "Eswatini", alpha_3: "SWZ", country_code: "748" },
  { name: "Ethiopia", alpha_3: "ETH", country_code: "231" },
  { name: "Falkland Islands (Malvinas)", alpha_3: "FLK", country_code: "238" },
  { name: "Faroe Islands", alpha_3: "FRO", country_code: "234" },
  { name: "Fiji", alpha_3: "FJI", country_code: "242" },
  { name: "Finland", alpha_3: "FIN", country_code: "246" },
  { name: "France", alpha_3: "FRA", country_code: "250" },
  { name: "French Guiana", alpha_3: "GUF", country_code: "254" },
  { name: "French Polynesia", alpha_3: "PYF", country_code: "258" },
  { name: "French Southern Territories", alpha_3: "ATF", country_code: "260" },
  { name: "Gabon", alpha_3: "GAB", country_code: "266" },
  { name: "Gambia", alpha_3: "GMB", country_code: "270" },
  { name: "Georgia", alpha_3: "GEO", country_code: "268" },
  { name: "Germany", alpha_3: "DEU", country_code: "276" },
  { name: "Ghana", alpha_3: "GHA", country_code: "288" },
  { name: "Gibraltar", alpha_3: "GIB", country_code: "292" },
  { name: "Greece", alpha_3: "GRC", country_code: "300" },
  { name: "Greenland", alpha_3: "GRL", country_code: "304" },
  { name: "Grenada", alpha_3: "GRD", country_code: "308" },
  { name: "Guadeloupe", alpha_3: "GLP", country_code: "312" },
  { name: "Guam", alpha_3: "GUM", country_code: "316" },
  { name: "Guatemala", alpha_3: "GTM", country_code: "320" },
  { name: "Guernsey", alpha_3: "GGY", country_code: "831" },
  { name: "Guinea", alpha_3: "GIN", country_code: "324" },
  { name: "Guinea-Bissau", alpha_3: "GNB", country_code: "624" },
  { name: "Guyana", alpha_3: "GUY", country_code: "328" },
  { name: "Haiti", alpha_3: "HTI", country_code: "332" },
  {
    name: "Heard Island and McDonald Islands",
    alpha_3: "HMD",
    country_code: "334",
  },
  { name: "Holy See", alpha_3: "VAT", country_code: "336" },
  { name: "Honduras", alpha_3: "HND", country_code: "340" },
  { name: "Hong Kong", alpha_3: "HKG", country_code: "344" },
  { name: "Hungary", alpha_3: "HUN", country_code: "348" },
  { name: "Iceland", alpha_3: "ISL", country_code: "352" },
  { name: "India", alpha_3: "IND", country_code: "356" },
  { name: "Indonesia", alpha_3: "IDN", country_code: "360" },
  { name: "Iran (Islamic Republic of)", alpha_3: "IRN", country_code: "364" },
  { name: "Iraq", alpha_3: "IRQ", country_code: "368" },  
  { name: "Isle of Man", alpha_3: "IMN", country_code: "833" },
  { name: "Israel", alpha_3: "ISR", country_code: "376" },
  { name: "Italy", alpha_3: "ITA", country_code: "380" },
  { name: "Jamaica", alpha_3: "JAM", country_code: "388" },
  { name: "Japan", alpha_3: "JPN", country_code: "392" },
  { name: "Jersey", alpha_3: "JEY", country_code: "832" },
  { name: "Jordan", alpha_3: "JOR", country_code: "400" },
  { name: "Kazakhstan", alpha_3: "KAZ", country_code: "398" },
  { name: "Kenya", alpha_3: "KEN", country_code: "404" },
  { name: "Kiribati", alpha_3: "KIR", country_code: "296" },
  {
    name: "Korea (Democratic People's Republic of)",
    alpha_3: "PRK",
    country_code: "408",
  },
  { name: "Korea, Republic of", alpha_3: "KOR", country_code: "410" },
  { name: "Kuwait", alpha_3: "KWT", country_code: "414" },
  { name: "Kyrgyzstan", alpha_3: "KGZ", country_code: "417" },
  {
    name: "Lao People's Democratic Republic",
    alpha_3: "LAO",
    country_code: "418",
  },
  { name: "Latvia", alpha_3: "LVA", country_code: "428" },
  { name: "Lebanon", alpha_3: "LBN", country_code: "422" },
  { name: "Lesotho", alpha_3: "LSO", country_code: "426" },
  { name: "Liberia", alpha_3: "LBR", country_code: "430" },
  { name: "Libya", alpha_3: "LBY", country_code: "434" },
  { name: "Liechtenstein", alpha_3: "LIE", country_code: "438" },
  { name: "Lithuania", alpha_3: "LTU", country_code: "440" },
  { name: "Luxembourg", alpha_3: "LUX", country_code: "442" },
  { name: "Macao", alpha_3: "MAC", country_code: "446" },
  { name: "Madagascar", alpha_3: "MDG", country_code: "450" },
  { name: "Malawi", alpha_3: "MWI", country_code: "454" },
  { name: "Malaysia", alpha_3: "MYS", country_code: "458" },
  { name: "Maldives", alpha_3: "MDV", country_code: "462" },
  { name: "Mali", alpha_3: "MLI", country_code: "466" },
  { name: "Malta", alpha_3: "MLT", country_code: "470" },
  { name: "Marshall Islands", alpha_3: "MHL", country_code: "584" },
  { name: "Martinique", alpha_3: "MTQ", country_code: "474" },
  { name: "Mauritania", alpha_3: "MRT", country_code: "478" },
  { name: "Mauritius", alpha_3: "MUS", country_code: "480" },
  { name: "Mayotte", alpha_3: "MYT", country_code: "175" },
  { name: "Mexico", alpha_3: "MEX", country_code: "484" },
  {
    name: "Micronesia (Federated States of)",
    alpha_3: "FSM",
    country_code: "583",
  },
  { name: "Moldova, Republic of", alpha_3: "MDA", country_code: "498" },
  { name: "Monaco", alpha_3: "MCO", country_code: "492" },
  { name: "Mongolia", alpha_3: "MNG", country_code: "496" },
  { name: "Montenegro", alpha_3: "MNE", country_code: "499" },
  { name: "Montserrat", alpha_3: "MSR", country_code: "500" },
  { name: "Morocco", alpha_3: "MAR", country_code: "504" },
  { name: "Mozambique", alpha_3: "MOZ", country_code: "508" },
  { name: "Myanmar", alpha_3: "MMR", country_code: "104" },
  { name: "Namibia", alpha_3: "NAM", country_code: "516" },
  { name: "Nauru", alpha_3: "NRU", country_code: "520" },
  { name: "Nepal", alpha_3: "NPL", country_code: "524" },
  { name: "Netherlands", alpha_3: "NLD", country_code: "528" },
  { name: "New Caledonia", alpha_3: "NCL", country_code: "540" },
  { name: "New Zealand", alpha_3: "NZL", country_code: "554" },
  { name: "Nicaragua", alpha_3: "NIC", country_code: "558" },
  { name: "Niger", alpha_3: "NER", country_code: "562" },
  { name: "Nigeria", alpha_3: "NGA", country_code: "566" },
  { name: "Niue", alpha_3: "NIU", country_code: "570" },
  { name: "Norfolk Island", alpha_3: "NFK", country_code: "574" },
  { name: "North Macedonia", alpha_3: "MKD", country_code: "807" },
  { name: "Northern Mariana Islands", alpha_3: "MNP", country_code: "580" },
  { name: "Norway", alpha_3: "NOR", country_code: "578" },
  { name: "Oman", alpha_3: "OMN", country_code: "512" },
  { name: "Pakistan", alpha_3: "PAK", country_code: "586" },
  { name: "Palau", alpha_3: "PLW", country_code: "585" },
  { name: "Palestine, State of", alpha_3: "PSE", country_code: "275" },
  { name: "Panama", alpha_3: "PAN", country_code: "591" },
  { name: "Papua New Guinea", alpha_3: "PNG", country_code: "598" },
  { name: "Paraguay", alpha_3: "PRY", country_code: "600" },
  { name: "Peru", alpha_3: "PER", country_code: "604" },
  { name: "Philippines", alpha_3: "PHL", country_code: "608" },
  { name: "Pitcairn", alpha_3: "PCN", country_code: "612" },
  { name: "Poland", alpha_3: "POL", country_code: "616" },
  { name: "Portugal", alpha_3: "PRT", country_code: "620" },
  { name: "Puerto Rico", alpha_3: "PRI", country_code: "630" },
  { name: "Qatar", alpha_3: "QAT", country_code: "634" },
  { name: "Réunion", alpha_3: "REU", country_code: "638" },
  { name: "Romania", alpha_3: "ROU", country_code: "642" },
  { name: "Russian Federation", alpha_3: "RUS", country_code: "643" },
  { name: "Rwanda", alpha_3: "RWA", country_code: "646" },
  { name: "Saint Barthélemy", alpha_3: "BLM", country_code: "652" },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    alpha_3: "SHN",
    country_code: "654",
  },
  { name: "Saint Kitts and Nevis", alpha_3: "KNA", country_code: "659" },
  { name: "Saint Lucia", alpha_3: "LCA", country_code: "662" },
  { name: "Saint Martin (French part)", alpha_3: "MAF", country_code: "663" },
  { name: "Saint Pierre and Miquelon", alpha_3: "SPM", country_code: "666" },
  {
    name: "Saint Vincent and the Grenadines",
    alpha_3: "VCT",
    country_code: "670",
  },
  { name: "Samoa", alpha_3: "WSM", country_code: "882" },
  { name: "San Marino", alpha_3: "SMR", country_code: "674" },
  { name: "Sao Tome and Principe", alpha_3: "STP", country_code: "678" },
  { name: "Saudi Arabia", alpha_3: "SAU", country_code: "682" },
  { name: "Senegal", alpha_3: "SEN", country_code: "686" },
  { name: "Serbia", alpha_3: "SRB", country_code: "688" },
  { name: "Seychelles", alpha_3: "SYC", country_code: "690" },
  { name: "Sierra Leone", alpha_3: "SLE", country_code: "694" },
  { name: "Singapore", alpha_3: "SGP", country_code: "702" },
  { name: "Sint Maarten (Dutch part)", alpha_3: "SXM", country_code: "534" },
  { name: "Slovakia", alpha_3: "SVK", country_code: "703" },
  { name: "Slovenia", alpha_3: "SVN", country_code: "705" },
  { name: "Solomon Islands", alpha_3: "SLB", country_code: "090" },
  { name: "Somalia", alpha_3: "SOM", country_code: "706" },
  { name: "South Africa", alpha_3: "ZAF", country_code: "710" },
  {
    name: "South Georgia and the South Sandwich Islands",
    alpha_3: "SGS",
    country_code: "239",
  },
  { name: "South Sudan", alpha_3: "SSD", country_code: "728" },
  { name: "Spain", alpha_3: "ESP", country_code: "724" },
  { name: "Sri Lanka", alpha_3: "LKA", country_code: "144" },
  { name: "Sudan", alpha_3: "SDN", country_code: "729" },
  { name: "Suriname", alpha_3: "SUR", country_code: "740" },
  { name: "Svalbard and Jan Mayen", alpha_3: "SJM", country_code: "744" },
  { name: "Sweden", alpha_3: "SWE", country_code: "752" },
  { name: "Switzerland", alpha_3: "CHE", country_code: "756" },
  { name: "Syrian Arab Republic", alpha_3: "SYR", country_code: "760" },
  { name: "Taiwan, Province of China", alpha_3: "TWN", country_code: "158" },
  { name: "Tajikistan", alpha_3: "TJK", country_code: "762" },
  { name: "Tanzania, United Republic of", alpha_3: "TZA", country_code: "834" },
  { name: "Thailand", alpha_3: "THA", country_code: "764" },
  { name: "Timor-Leste", alpha_3: "TLS", country_code: "626" },
  { name: "Togo", alpha_3: "TGO", country_code: "768" },
  { name: "Tokelau", alpha_3: "TKL", country_code: "772" },
  { name: "Tonga", alpha_3: "TON", country_code: "776" },
  { name: "Trinidad and Tobago", alpha_3: "TTO", country_code: "780" },
  { name: "Tunisia", alpha_3: "TUN", country_code: "788" },
  { name: "Turkey", alpha_3: "TUR", country_code: "792" },
  { name: "Turkmenistan", alpha_3: "TKM", country_code: "795" },
  { name: "Turks and Caicos Islands", alpha_3: "TCA", country_code: "796" },
  { name: "Tuvalu", alpha_3: "TUV", country_code: "798" },
  { name: "Uganda", alpha_3: "UGA", country_code: "800" },
  { name: "Ukraine", alpha_3: "UKR", country_code: "804" },
  { name: "United Arab Emirates", alpha_3: "ARE", country_code: "784" },
  {
    name: "United Kingdom of Great Britain",
    alpha_3: "GBR",
    country_code: "826",
  },
  { name: "United States of America", alpha_3: "USA", country_code: "840" },
  {
    name: "United States Minor Outlying Islands",
    alpha_3: "UMI",
    country_code: "581",
  },
  { name: "Uruguay", alpha_3: "URY", country_code: "858" },
  { name: "Uzbekistan", alpha_3: "UZB", country_code: "860" },
  { name: "Vanuatu", alpha_3: "VUT", country_code: "548" },
  {
    name: "Venezuela (Bolivarian Republic of)",
    alpha_3: "VEN",
    country_code: "862",
  },
  { name: "Viet Nam", alpha_3: "VNM", country_code: "704" },
  { name: "Virgin Islands (British)", alpha_3: "VGB", country_code: "092" },
  { name: "Virgin Islands (U.S.)", alpha_3: "VIR", country_code: "850" },
  { name: "Wallis and Futuna", alpha_3: "WLF", country_code: "876" },
  { name: "Western Sahara", alpha_3: "ESH", country_code: "732" },
  { name: "Yemen", alpha_3: "YEM", country_code: "887" },
  { name: "Zambia", alpha_3: "ZMB", country_code: "894" },
  { name: "Zimbabwe", alpha_3: "ZWE", country_code: "716" },
];
