import React, { Fragment, useContext, useState } from 'react'
import Popup from '../../../../components/dialog/Popup';
import Spinner from '../../../../components/spinner/Spinner';
import SetupContext from '../../../../context/setup/SetupContext';
import { currencyFormat } from '../../../../models/Helper';
import EditContactLessLimit from './EditContactLessLimit';
import { Tooltip } from 'react-tooltip';
import UnauthorizedPage from "../../../errors/UnauthorizedPage";
import SecurityClassificationError from "../../../errors/SecurityClassification";

let popupTitle: string = "";

const ContactLessLimits = (props: any) => {

    const initContactlessLimit = {
        Currency: "",
        TransactionLimit: 0,
        TransactionValue: 0
    };

    const { issuerId, setupId,securityClassifications,canPageBeRendered,proccedAndLoad  } = props;
    const { contactlessLimits, loading, setupDetails, masterUpdateSetup } = useContext(SetupContext);
    const [ contactlessEntriesCount, setContactlessEntriesCount ] = useState({
        pinRequiredAfterContactlessEntriesCount: setupDetails.pinRequiredAfterContactlessEntriesCount
    });
    const [openPopup, setOpenPopup] = useState(false);
    const [limitDetails, setLimitDetails] = useState(initContactlessLimit);

    const readAccess = securityClassifications?.includes("Read");
    const createAccess = securityClassifications?.includes("Create");
    const updateAccess = securityClassifications?.includes("Update");

    const handleChange = (event: any) => {
        const isNumeric = /^[\d\b]+$/.test(event.target.value);
        if((isNumeric && event.target.value < 256) || event.target.value === ""){
            setContactlessEntriesCount({
                pinRequiredAfterContactlessEntriesCount:event.target.value});
        }
      };  

      const onSubmit = (e: any) => {
        e.preventDefault(); 
        masterUpdateSetup(issuerId, setupId,  "PinRequiredAfterContactlessEntriesCount", contactlessEntriesCount.pinRequiredAfterContactlessEntriesCount);
    }

    function onEditLimitClicked(limit : any) {
        if(updateAccess){
            setLimitDetails( {
                Currency: limit.currencyName,
                TransactionLimit: limit.transactionLimit,
                TransactionValue: limit.pinRequiredAfterContactlessEntriesValue 
            });
            popupTitle = "Edit Contactless Limit";
            setOpenPopup(true);
        }
    }

    function onNewLimitClicked(limit : any) {
        if(createAccess){
            setLimitDetails(initContactlessLimit);
            popupTitle = "Add Contactless Limit";
            setOpenPopup(true);
        }
    }

    function countChanged() {
        return contactlessEntriesCount.pinRequiredAfterContactlessEntriesCount != setupDetails.pinRequiredAfterContactlessEntriesCount;
    }

    if(!canPageBeRendered)
    {
        return (<div className="card-body" >< Spinner /> </div>)
    }

    if(!proccedAndLoad)
    {
        return <UnauthorizedPage withWrapper={false} />;
    }

    if(!readAccess){
        return (<SecurityClassificationError title="Read Classification Denied" pageName="Contactless Limit" extraReference='Contactless Limit' withWrapper={false}/>)
    }

    return (    
        !loading ?
            <Fragment>
                    <span>
                        {
                            createAccess && <button type="button" className="btn btn-round btn-sm btn-success mb-1" onClick={onNewLimitClicked}>
                            <i className="fa fa-plus p-1" />
                            <span>Add New Limit</span>
                        </button>
                        }
                    </span>

                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Max Contactless Entries Before Pin Required&nbsp;
                            <i className="fas fa-info-circle" id="pinRequiredAfterContactlessEntriesCountLabel" data-tooltip-variant="info" aria-hidden="true"></i>
                        </label>
                        <Tooltip anchorId="pinRequiredAfterContactlessEntriesCountLabel" className='tooltip'>
                            <div>
                                <h5>Maximum Contactless Entries Before Pin Is Required</h5>
                                <p>Specifies the number of consecutive contactless transactions that can be made before the terminal requires the customer to enter their PIN. </p>                               
                            </div>
                        </Tooltip>
                        <div className="col-sm-1 align-self-center">
                            <input
                                type="input"
                                className="form-control text-center"
                                id="pinRequiredAfterContactlessEntriesCount"
                                placeholder="0"
                                name="pinRequiredAfterContactlessEntriesCount"
                                value={contactlessEntriesCount.pinRequiredAfterContactlessEntriesCount}
                                onChange={handleChange} />
                        </div>
                        <div className="col-sm-1 my-auto d-flex">
                            {   updateAccess && <input  onClick={onSubmit}
                                type="submit" 
                                value="Update"
                                disabled={!countChanged()}
                                className="btn button-control-warning float-right" />
                            }
                        </div>
                    </div>  

                    <div className="space-4"></div>

                    <table id="table-data" className="table table-bordered table-hover table-sm" role="grid">
                        <thead className="table-header">
                            <tr role="row">
                                <th></th>
                                <th>Currency</th>
                                <th className="text-right">Transaction Limit</th>
                                <th className="text-right">PIN Required</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                contactlessLimits.length > 0 ? (
                                    contactlessLimits.map(
                                        (limit: any, idx: number) => (
                                            <tr key={idx}>
                                                <td>
                                                    { updateAccess && 
                                                        <a href="#!" >
                                                            <i className="fas fa-edit text-warning" onClick={() => onEditLimitClicked(limit)} />
                                                        </a>
                                                    }
                                                </td>
                                                <td>{limit.currencyName}</td>
                                                <td className="text-right">{currencyFormat(limit.transactionLimit)}</td>
                                                <td className="text-right">{currencyFormat(limit.pinRequiredAfterContactlessEntriesValue)}</td>
                                            </tr>
                                        )
                                    )
                                ) : (
                                    <tr><td className="text-center text-danger" colSpan={5}>No data available in table</td></tr>
                                )
                            }
                        </tbody>
                    </table>

                    <Popup title={popupTitle} openPopup={openPopup} setOpenPopup={setOpenPopup}>
                        <EditContactLessLimit limit={limitDetails} setOpenPopup={setOpenPopup} issuerId={issuerId} setupId={setupId} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />
                    </Popup>               
            </Fragment>
            : <Spinner />
    )
}

export default ContactLessLimits
