export const GET_ISSUERS = 'GET_ISSUERS';
export const GET_ISSUER = 'GET_ISSUER';
export const ISSUER_ERROR = 'ISSUER_ERROR';
export const GET_ISSUERS_FOR_SELECT = 'GET_ISSUERS_FOR_SELECT';

export const GET_SETUPS = 'GET_SETUPS';
export const GET_SETUP = 'GET_SETUP';
export const SETUP_ERROR = 'SETUP_ERROR';

export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_LANGUAGES = 'GET_LANGUAGES';
export const GET_CURRENCIES = 'GET_CURRENCIES';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const AUTH_USER = 'AUTH_USER';
export const SET_DISABLED = 'SET_DISABLED';

export const GET_FX = 'GET_FX';
export const GET_FX_DETAILS = 'GET_FX_DETAILS';

export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const GET_ACCOUNT = 'GET_ACCOUNT';
export const ACCOUNTS_ERROR = 'GET_ACCOUNT';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_CURRENT_ISSUER = 'SET_CURRENT_ISSUER';

export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const HANDLE_TRANSACTIONS = 'HANDLE_TRANSACTIONS';



