import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Spinner from "../../../components/spinner/Spinner";
import RoleBasedAccessControlContext from '../../../context/rolebasedaccesscontrol/RoleBasedAccessControlContext';
import AccountsContext from "../../../context/accounts/AccountsContext";
import Popup from "../../../components/dialog/Popup";
import CardList from "../../secured/cardholders/CardList";
import Wrapper from "../../Wrapper";
import UserDefined from "../userdefined/UserDefined";
import UnauthorizedPage from "../../errors/UnauthorizedPage";
import SecurityClassificationError from "../../errors/SecurityClassification";

const AccountDetails: React.FC = () => {

    const { securityClassifications,getSecurityClassification,retrievePagePermission}  = useContext(RoleBasedAccessControlContext); 
    const [proccedAndLoad, setUserAccess] = useState<boolean>(false);
    const [canPageBeRendered, canInvokePageContent] = useState(false); 

    useEffect(() => {
        retrievePagePermission("accounts").then((isGranted: boolean) => {
            canInvokePageContent(true);
            setUserAccess(isGranted);
        }).catch((error: any) => {
            canInvokePageContent(false);
            setUserAccess(false);
          });
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        getSecurityClassification();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


  const { id } = useParams();
  const { accountDetails, getAccountDetails, loading } = useContext(AccountsContext);
  const [openPopup, setOpenPopup] = useState(false);
  const [cardsList, setCardList] = useState([]);

  useEffect(() => {
    getAccountDetails(id);
    // eslint-disable-next-line
  }, []);


  if(!canPageBeRendered)
  {
      return (
          <Wrapper title="Issuer Management" subTitle="Create Issuer">
              < Spinner />
          </Wrapper>
          )
  }

  if(!proccedAndLoad)
  {
      return <UnauthorizedPage />;
  }
  const readAccess = securityClassifications?.includes("Read");

  if(!readAccess) 
  {
    return (<SecurityClassificationError title="Read Classification Denied" pageName="Accounts Management Details" extraReference="Accounts Management Details" withWrapper={true}/>)
  }

  return (
    <Wrapper title="Account Management" subTitle="Account Detailed Information">
      <div className="card-body">
        {!loading &&
          accountDetails &&
          accountDetails.accountId === id ? (
          <>
            <div className="col-md-6">
              <h4 className="group-field-label pb-3">
                Detailed Information
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    <Link to="/accounts" className="link">
                      <i className="nav-icon fas fa-backward mr-2" />
                      Back To List
                    </Link>
                  </label>
                </div>
              </h4>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Account ID</label>
                <div className="col-sm-8">
                  <input
                    type="input"
                    className="form-control"
                    placeholder="Account ID"
                    name="accountId"
                    value={accountDetails?.accountId}
                    disabled
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Account Name</label>
                <div className="col-sm-8">
                  <input
                    type="input"
                    className="form-control"
                    placeholder="Account Name"
                    name="accountName"
                    value={accountDetails?.accountName}
                    disabled
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Issuer ID</label>
                <div className="col-sm-8">
                  <input
                    type="input"
                    className="form-control"
                    name="issuerId"
                    value={accountDetails?.issuerId || ""}
                    disabled
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Setup ID</label>
                <div className="col-sm-8">
                  <input
                    type="input"
                    className="form-control"
                    placeholder="Setup ID"
                    name="setupId"
                    value={accountDetails?.setupId}
                    disabled
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">User Defined</label>
                <div className="col-sm-8">
                  <div className="form-control p-0" style={{border: "0px"}}>
                    {Object.entries(accountDetails?.userDefined).map(
                      (el, ind) => (
                        <UserDefined key={ind} data={el} />
                      )
                    )}
                  </div>

                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="space-10"></div>
                <h4 className="group-field-label">Cardholders List</h4>
                <table
                  id="table-data"
                  className="table table-striped dataTable dtr-inline table-sm table-bordered"
                  role="grid"
                >
                  <thead className="table-header">
                    <tr role="row">
                      <th></th>
                      <th>Address</th>
                      <th>Postcode</th>
                      <th>ID</th>
                      <th>Card Holders</th>
                      <th>Date Created (dd-MM-yyyy)</th>
                      <th>Email</th>
                      <th>Mobile #</th>
                      <th>Name</th>
                      <th>Risk Level #</th>
                      <th>User Defined</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accountDetails["cardholders"] ? (
                      accountDetails["cardholders"].map(
                        (cardholder: any, idx: number) => (
                          <tr key={idx}>
                            <td></td>
                            <td align="center">{cardholder.avsAddress}</td>
                            <td align="center">{cardholder.avsPostcode}</td>
                            <td align="center">{cardholder.cardholderId}</td>
                            <td align="center">
                              <Link
                                to="#"
                                onClick={() => {
                                  setOpenPopup(true);
                                  setCardList(cardholder["cards"]);
                                }}
                              >
                                View Cardholders
                              </Link>
                            </td>
                            <td align="center">{cardholder.created}</td>
                            <td align="center">{cardholder.email}</td>
                            <td align="center">{cardholder.mobileNumber}</td>
                            <td align="center">{`${cardholder.name} ${cardholder.surname}`}</td>
                            <td align="center">{cardholder.riskLevel}</td>
                            <td align="center">
                              {Object.entries(cardholder["userDefined"]).map(
                                (el, ind) => (
                                  <UserDefined key={ind} data={el} />
                                )
                              )}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td className="text-center text-danger" colSpan={6}>
                          No data available in table
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <Popup
                title="Cards List"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                size="xl"
              >
                <CardList myList={cardsList} />
              </Popup>
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </Wrapper>
  );
};

export default AccountDetails;
