import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Wrapper from '../../Wrapper'
import RoleBasedAccessControlContext from '../../../context/rolebasedaccesscontrol/RoleBasedAccessControlContext';
import UnauthorizedPage from "../../errors/UnauthorizedPage";
import SecurityClassificationError from "../../errors/SecurityClassification";
import { Link, useLocation } from 'react-router-dom';
import Spinner from '../../../components/spinner/Spinner';
import TransactionMgtContext from '../../../context/transactions/TransactionMgtContext';
import { TableHeaders, TableInputSearch, TablePaginator } from '../../../components/table';
import { transactionColumns } from '../../../models/enum/columns';
import { parseISO, format } from 'date-fns';
import { ConfirmDialogInitValues } from "../../../models/ConfirmDialogInitValues";
import ConfirmDialog from "../../../components/dialog/ConfirmDialog";
import * as XLSX from 'xlsx';



const TransactionMgt = () => {

    const { securityClassifications, getSecurityClassification, retrievePagePermission } = useContext(RoleBasedAccessControlContext);
    const [proccedAndLoad, setUserAccess] = useState<boolean>(false);
    const [canPageBeRendered, canInvokePageContent] = useState(false);
    const location = useLocation();

    useEffect(() => {
        let pageName = location?.pathname?.split('/').pop()?.toLowerCase();
        retrievePagePermission(pageName).then((isGranted: boolean) => {
            canInvokePageContent(true);
            setUserAccess(isGranted);
            // Do something with menuItems
        }).catch((error: any) => {
            canInvokePageContent(false);
            setUserAccess(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getSecurityClassification();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const readAccess = securityClassifications?.includes("Read");
    const updateAccess = securityClassifications?.includes("Update");

    const { transactions, from, to, pageSize, pageNo, loading, getTransactions, handleTransactions } = useContext(TransactionMgtContext);

    const [page, setPage] = useState(pageNo);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [search, setSearch] = useState("");
    const [itemsPerPage, setItemsSize] = useState(pageSize);
    const [confirmDialog, setConfirmDialog] = useState(ConfirmDialogInitValues);
    const tableRef = useRef(null);
    const [selectedTransactionId, setSelectedTransactionId] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [actionType, setActionType] = useState("");
    const formatStartDate = format(startDate, 'yyyy-MM-dd');
    const formatEndDate = format(endDate, 'yyyy-MM-dd');


    const handleStartDateChange = (date: Date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date: Date) => {
        setEndDate(date);
    };

    const handleDownloadExcel = () => {
        const dataToExport = transactionData.map((transaction: any) => {
            const exportedData: { [key: string]: any } = {};
            transactionColumns.forEach((column) => {
                const columnName = column.selector;
                exportedData[column.name] = transaction[columnName];
            });

            return exportedData;
        });

        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Transactions');
        XLSX.writeFile(wb, 'Transactions.xlsx');
    };
    const handleAction = (transactionId: string, actionType: string) => {
        const actionLabel = actionType.charAt(0).toUpperCase() + actionType.slice(1);
        handleTransactions(transactionId, actionType, formatStartDate, formatEndDate,itemsPerPage, page);
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
    };

    const renderActionButton = (transactionId: string, actionType: string, label: string, color: string) => {
        return (
            <button
                className={`btn btn ${color}`}
                style={{ fontSize: "small" }}
                onClick={() => {
                    setSelectedTransactionId(transactionId);
                    setActionType(actionType);
                    setConfirmDialog({
                        isOpen: true,
                        title: `Are you sure you want to ${label.toLowerCase()} this record?`,
                        subTitle: "You cannot undo this operation.",
                        onConfirm: () => handleAction(transactionId, actionType),
                        onCancel: () => setConfirmDialog({ ...confirmDialog, isOpen: false }),
                    });
                }}
            >
                {label}
            </button>
        );
    };

    useEffect(() => {
        if (startDate && endDate)
            getTransactions(formatStartDate, formatEndDate, itemsPerPage, page);

    }, [startDate, endDate, itemsPerPage, page]);

    const transactionData = useMemo(() => {
        let data = transactions.data || [];

        data = data.filter((transaction: any) => {
            if (!transaction) return false;

            return Object.values(transaction).some((value: any) =>
                value && value.toString().toLowerCase().includes(search.toLowerCase())
            );
        });

        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            data = data.sort((a: any, b: any) => {
                const valueA = a[sorting.field];
                const valueB = b[sorting.field];
                if (typeof valueA === 'string' && typeof valueB === 'string') {
                    return reversed * valueA.localeCompare(valueB);
                } else {
                    return reversed * (valueA > valueB ? 1 : -1);
                }
            });
        }

        return data;
    }, [transactions, sorting, search]);

    const totalRows = useMemo(() => {
        return transactionData.length;
    }, [transactionData]);

    if (!canPageBeRendered) {
        return (<Wrapper title="Transaction Management"> < Spinner /> </Wrapper>)
    }

    if (!proccedAndLoad) {
        return <UnauthorizedPage />;
    }

    if (!readAccess) {
        return (<SecurityClassificationError title="Read Classification Denied" pageName="Transaction Management" extraReference="Transaction Management" withWrapper={true} />)
    }

    return (
        <Wrapper title="Transaction Management">
            <div className="card-body">
                {
                    !loading ?
                        <div className="dataTables_wrapper dt-bootstrap4">
                            {readAccess && transactionData && transactionData.length > 0 && (
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 mb-2">

                                        <button
                                            style={{ float: "inline-end" }}
                                            className="btn btn-success btn-sm"
                                            onClick={handleDownloadExcel}>
                                            Export to Excel
                                        </button>

                                    </div>
                                </div>
                            )}
                            <div className="row">
                                <div className="col-sm-12 col-md-4 input-date">
                                    <label style={{ marginRight: "5px" }}>Date From:</label>
                                    <input
                                        type="date"  data-date-format="YYY MM DD"
                                        
                                        style={{ width: "240px" }}
                                        value={formatStartDate}
                                        onChange={(e) => handleStartDateChange(new Date(e.target.value))}
                                        className="form-control"
                                    /> 
                                </div>
                                <div className="col-sm-12 col-md-4 input-date">
                                    <label style={{ marginRight: "5px" }}>To:</label>
                                    <input
                                        type="date"
                                        style={{ width: "240px" }}
                                        value={formatEndDate}
                                        onChange={(e) => handleEndDateChange(new Date(e.target.value))}
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-sm-12 col-md-4">
                                    <div className="dataTables_filter">
                                        <TableInputSearch
                                            onSearch={(value: string) => {
                                                setSearch(value);
                                            }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 table-responsive">
                                    <table ref={tableRef} id="table-data" className="table table-striped dataTable dtr-inline table-sm table-bordered" role="grid">
                                        <TableHeaders
                                            headers={transactionColumns}
                                            onSorting={(field: string, order: string) => {
                                                setSorting({ field, order })

                                            }}
                                            showEmptyHeader={false}
                                        />

                                        <tbody>
                                            {
                                                transactionData !== undefined
                                                    && Object.keys(transactionData).length !== 0
                                                    && transactionData.constructor !== Object ? (
                                                    transactionData.map(
                                                        (transaction: any, idx: number) => (
                                                            <tr key={idx}>

                                                                <td>{transaction.transactionId}</td>
                                                                <td>{transaction.transactionDate}</td>
                                                                <td>{transaction.authResponseId}</td>
                                                                <td>{transaction.transactionType}</td>
                                                                <td>{transaction.authorizationStatus}</td>
                                                                <td>{transaction.amount}</td>
                                                                <td>{transaction.currency}</td>
                                                                <td>{transaction.merchantName}</td>
                                                                <td>{transaction.issuerCardId}</td>
                                                                <td>{transaction.maskedPan}</td>
                                                                <td>{transaction.currencyAlphaCode}</td>
                                                                <td>
                                                                    {updateAccess && renderActionButton(transaction.transactionId, "approve", "Approve", "btn-success")}
                                                                </td>
                                                                <td>
                                                                    {updateAccess && renderActionButton(transaction.transactionId, "decline", "Decline", "btn-danger")}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )
                                                ) : (
                                                    <tr><td className="text-center text-danger" colSpan={12}>No data available in table</td></tr>
                                                )
                                            }
                                        </tbody>

                                    </table>
                                    {updateAccess &&
                                        <ConfirmDialog
                                            confirmDialog={confirmDialog}
                                            setConfirmDialog={setConfirmDialog}
                                            action={actionType}
                                            transactionId={selectedTransactionId}
                                        />}
                                </div>
                            </div>
                            <TablePaginator
                                total={totalRows}
                                itemsPerPage={pageSize}
                                currentPage={pageNo}
                                onPageChange={(page: any) => setPage(page)}
                                onPageSizeChange={(size: number) => setItemsSize(size)}
                            />
                        </div> : <Spinner />
                }
            </div>
        </Wrapper>
    )
}

export default TransactionMgt
