import React from 'react';
import './Switch.css';

const Switch = (props: any) => {
    const {isOn, handleToggle, id} = props;  
    
    const handleKeyPress = (e: React.KeyboardEvent<HTMLLabelElement>) => {      
      if(e.code !== "Space") return;

      e.preventDefault();
      handleToggle(id, !isOn)
    }
    
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {         
      const { id } = e.currentTarget                    
      handleToggle(id, !isOn )     
    }; 

    return (
      <div className="form-inline">
     <label className="switch-label mr-2">{"No"}</label>
        <input
          checked={isOn}
          onChange={handleChange}
          className="react-switch-checkbox"
          id={id}
          type="checkbox"
        />
        <label
          className="react-switch-label"
          htmlFor={id}
          style={{ background: isOn && '#42B194' }}
          tabIndex={0}
          onKeyDown={handleKeyPress}
        >
          <span className={`react-switch-button`} />
        </label>
        <label className="switch-label ml-2">{"Yes"}</label>
      </div>
    );
  };

export default Switch
