import React, { useState } from 'react'

interface IProps {
    headers: {
        id: number;
        name: string;
        selector: string;
        sortable: boolean;
    }[];
    onSorting(field: string, order: string): void;
    showEmptyHeader?: boolean;
}

const TableHeaders: React.FC<IProps> = (props) => {
    const { headers, onSorting , showEmptyHeader = true } = props;

    const [sortingField, setSortingField] = useState("");
    const [sortingOrder, setSortingOrder] = useState("asc")

    const onSortingChange = (field: string) => {
        const order = field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

        setSortingField(field);
        setSortingOrder(order);
        onSorting(field, order);
    }

    return (
        <thead className="table-header">
            <tr role="row">
            {showEmptyHeader && <th style={{ width: '5px' }}></th>}
                {
                    headers.map((c: any) => (
                        <th key={c.id}
                            onClick={() => c.sortable ? onSortingChange(c.selector) : null}
                            className="table-header-text">{c.name}
                            {
                                c.sortable && 
                                <span className="float-right"><i className={sortingOrder === "asc" ? "fas fa-sort-alpha-down-alt" : "fas fa-sort-alpha-up"}></i> </span> 
                            }  
                        </th>
                    ))
                }
            </tr>
        </thead>
    )
}

export default TableHeaders
