export const CardReverseCardholderIdPosition = [
    {
        id: 1,
        name: "Under barcode / Right Bottom"
    },
    {
        id: 2,
        name: "Left Bottom"
    },
    {
        id: 3,
        name: "Other (Right Middle)"
    },    
]