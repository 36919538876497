const CardReverseIssuerStatement = [
    {
        id: 1,        
        name: "This card is issued by Prepaid Financial Services Limited pursuant to a licence by Mastercard International. This card remains the property of Prepaid Financial Services Limited. If your card is lost or stolen you must contact us immediately"
    },
    {
        id: 2,
        name: "This card is issued by PFS Card Services Ireland Limited pursuant to a licence by Mastercard International. This card remains the property of PFS Card Services Ireland Limited. If your card is lost or stolen you must contact us immediately"
    },
    {
        id: 3,
        name: "This card is issued pursuant to a licence by Mastercard International. This card remains the property of PFS. If your card is lost or stolen you must contact us immediately"
    },    
]

export const CardReverseIssuerStatementOptions = CardReverseIssuerStatement.map(s => ({ ...s, display: s.name.substring(0,100).concat("...")}))