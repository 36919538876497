import { useEffect, useState } from "react";
import Switch from "../../../../components/switch";
import { DeliveryMethodOptions } from "../../../../models/enum/DeliveryMethodOptions";
import { vendorTypes } from "../../../../models/enum/vendorTypes";
import { TDeliveryDetails } from "../../../../models/ICardVendor";
import Bulk from "./Bulk";

const DeliveryDetails = ({details, handleChanges}: {details:any, handleChanges:any}) => {  
  const [deliveryDetails, setDeliveryDetails] = useState<TDeliveryDetails>(details);    

  useEffect(() => { setDeliveryDetails(details)}, [details] )  

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {     
    const { id, value, type} = e.currentTarget
    if (type === "checkbox") {           
      var newValue = deliveryDetails ? !(deliveryDetails as any)[id] : true;
      handleChanges(id, newValue, vendorTypes.DELIVERY_DETAILS )   
    } else {      
      handleChanges(id, value, vendorTypes.DELIVERY_DETAILS )   
    }          
  }; 

  const handleToggle = (id: string, value: boolean) => {        
    handleChanges(id, value, vendorTypes.DELIVERY_DETAILS )   
  };

  const handleBulk = (name: string, value: any, type: string) => {     
    handleChanges(name, value, vendorTypes.BULK )
  };

  const mapDeliveryMethod = (id: string) => {
    if (!id) return "";
    return DeliveryMethodOptions.find(s => s.id === id)?.name
  }

  return (
    <div className="card-body">
      <div className="row">
        <div className="col-md-6">
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Delivery Method</label>
            <div className="col-sm-7">
              <select
                title={mapDeliveryMethod(deliveryDetails?.method || "")}
                id="method"
                name="method"
                className="form-control form-control-sm overflow-ellipsis"
                value={deliveryDetails?.method || ""}
                onChange={handleChange}
              >
                <option value={""}>Select Delivery Method</option>                
                {DeliveryMethodOptions.map((item: any) => (
                  <option key={item.id} value={item.id}>
                    {item.id} - {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group row">
            <label className="col-sm-6 col-form-label">Bulk Delivery</label>
            <div className="col-sm-6 pt-1" title="Is Bulk Delivery">
              <Switch
                id="bulkDelivery"
                name="bulkDelivery"
                isOn={deliveryDetails?.bulkDelivery || false}
                handleToggle={handleToggle} 
              />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div
            className="card"
            style={{ marginTop: "0px", marginBottom: "0px" }}
          >
            <div className="badge bg-light">Bulk</div>
            <Bulk bulkData={deliveryDetails?.bulk} handleChanges={handleBulk} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryDetails;
