import React, { useReducer } from 'react';
import { useMsal } from '@azure/msal-react';
import  RoleBasedAccessControlContext  from '../rolebasedaccesscontrol/RoleBasedAccessControlContext';
import roleBasedControlAccessReducer  from "../rolebasedaccesscontrol/RoleBasedAccessControlReducer";
import { RoleActionType }  from  "../../models/enum/RoleActionType";
import { RoleBasedAccessControlDetails, RoleBasedAccessControlInitState } from "../../models/IRoleBasedControlAccess";
import { toast } from 'react-toastify';

/**
 * The RoleBasedAccessControlState contains all the state invokes which can be called from the required components.
 * This will allow the managment of pages interms of menu,pages and event operations which one is able to do or has.
 * @param param0 
 * @returns 
 */
const RoleBasedAccessControlState: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    // This to get the Azure AD credentials and check against.
    const { accounts } = useMsal();

    // the core componet which will help us manage the content on the pages via the state invokes. 
    const initialState: RoleBasedAccessControlInitState = {
        hasPageAccess : false,
        areConfigurationsSet : false,
        navigationItems : [],
        securityClassifications : [],
        stateError : null,
        roles: [],
    }

    // these are the security classifications according to the level of acesss.
    const securityClassification: { [key: string]: string[] } = {
        LowAccess: [], // no classification
        LimitedAccess: ["Read"], // only read classification
        PartialAccess : ["Create","Read","Update"], // No delete classification
        FullAccess: ["Create", "Read","Update", "Delete"], // all avaliable classifications
    };
    
    // this will hold the mapping which a user is able to has depending on the role or roles once has.
    // if one has many assigned these are combined to consider the full rights which one has.
    // Ideal: for future updates this can be moved away from portal level and maybe he integrated at Azure Level or even at api level
    // to help manage and release access control logic via RBAC more easily : via a dedicated middleware. 
    const accessManagement = [
        {
            Role:"Trace_Admin",
            PageAccess: ['Dashboard',"Setup","Issuer","forext-mgt","Accounts", "Auth","transaction-mgt"],
            MenuAccess: ["Home","Set-up","Issuer","Foreign Exchange","Accounts Management","Transactions"],
        },
        {
            Role:"Trace_Support",
            PageAccess: ['Dashboard',"Setup","Issuer","forext-mgt","Accounts", "Auth","transaction-mgt"],
            MenuAccess: ["Home","Set-up","Issuer","Foreign Exchange","Accounts Management","Transactions"],
        },// Full Access - Crud Operations check - crudLevel const
        {
            Role:"Trace_ProjectManager",
            PageAccess: ['Dashboard',"Setup","Issuer","Accounts"],
            MenuAccess: ["Home","Set-up","Issuer","Accounts Management"],
        },
        {
            Role:"Trace_AccountManagment",
            PageAccess: ['Dashboard',"Setup","Issuer","Accounts"],
            MenuAccess: ["Home","Set-up","Issuer","Accounts Management"],
        },
        {
            Role:"Trace_Onboarding",
            PageAccess: ['Dashboard',"Setup","Issuer","Accounts"],
            MenuAccess: ["Home","Set-up","Issuer","Accounts Management"],
        }, // Partial Access - Crud Operations check - crudLevel const
        {
            Role:"Trace_Risk",
            PageAccess: ["Dashboard","transaction-mgt"],
            MenuAccess: ["Home","Transactions"],
        },
        {
            Role:"Trace_Basic",
            PageAccess: ["Dashboard"],
            MenuAccess: ["Home"],
        }, // Limited Access - Crud Operations check - crudLevel const
    ]

    // This to initial the State.
    const [state, dispatch] = useReducer(roleBasedControlAccessReducer, initialState);

    // clear errors
    const clearErrors = () => dispatch({ type: RoleActionType.ClearError });
 
   /**
    * This will be call at RequestInterceptorProps which is the entry point to the sub states and will handle the synchronization
    * of the managment access a user has as per above mapping accessManagment and crudLevel thus giving the user a limited or full access 
    * on the features of the portal.
    * two important state properties are set the localstorage and the areConfigurationsSet : payloads.
    */
    const synchRoleBasedAccessControl = () => {
        // This will hold temp of the process the details of the RBAC.
        const rbacdetails: RoleBasedAccessControlDetails = { roles : [], pages : [], menus:[], securityClassifications : []};

        try 
        {
            redistributeConfigurations(); // this will check if RBAC keys is avail and reset as expected.

            if (accounts.length > 0) { // if accounts is empty from azure.
                const roles: string[] = accounts[0]?.idTokenClaims?.roles || [];
                const rbacRoles : string[] = [];
                const rbacSecurityClassification : string[] = [];
                const rbacPageAccess : string[] = [];
                const rbacMenuAccess : string[] = [];

                // for this to work add the required classifications and access under the const : accessManagement
                // IMPORTANT : USE THIS ONLY FOR LOCAL AND DEBUG OPERATIONS IF NEEDED. 
                //const isDebug = process.env.REACT_APP_IS_DEBUG === 'True';
                /*const isDebug : boolean = false; // if no roles are assigned you can enable this for debug operation important turn off after

                if(isDebug){
                    roles.push("Trace_Debug.FullAccess")
                }*/

                if(roles.length > 0){ // check if roles are avail.
                    for (let index in roles) {
                        if(roles[index].includes(".")){ // important for the right mapping : Full,Limited,Partial,Low (defined at azure level)
                            let roledetails = roles[index].split('.'); // seperate string by expected character
                            
                            let role = roledetails[0];// first element is the role e.g : Trace_Admin.
                            
                            let accessLevel = roledetails[1]; // second element is the level of access e.g : FullAccess, PartialAccess.
                            
                            //This to push the role in the expected array.
                            if(!rbacRoles.includes(role)){
                                rbacRoles.push(role); 
                            }

                            let getSecurityClassification = securityClassification[accessLevel]; // get the acceslevel details here we will have information such as : Create,Read,Update and Delete

                            //This to push the crud operations assigned on the role.
                            for(let index in getSecurityClassification){
                                let value = getSecurityClassification[index];
                                let isDefined = rbacSecurityClassification.includes(value);

                                if(isDefined === false){
                                    rbacSecurityClassification.push(value);
                                }
                            }
                            
                           //This to push the right pages access and even so the menu access one has.
                            for (var i=0; i < accessManagement.length; i++) { 
                                if(accessManagement[i].Role === role){
                                    for(let index in accessManagement[i].PageAccess){
                                        let page = accessManagement[i].PageAccess[index];
                                        if(!rbacPageAccess.includes(page.toLowerCase())){
                                            rbacPageAccess.push(page.toLowerCase());
                                        }
                                    }

                                    for(let index in accessManagement[i].MenuAccess){
                                        let menu = accessManagement[i].MenuAccess[index];
                                        if(!rbacMenuAccess.includes(menu.toLowerCase())){
                                            rbacMenuAccess.push(menu.toLowerCase());
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
                //Preparing the payload object which will be used as a reference for the whole RBAC functionality.
                rbacdetails.roles = rbacRoles;
                rbacdetails.securityClassifications = rbacSecurityClassification;
                rbacdetails.pages = rbacPageAccess;
                rbacdetails.menus = rbacMenuAccess;
            }
            //Note in case of wrong roles assigned from azure and in case one by any means has not beeen auth the final object will be an empty one.
            //thus retricting all the pages and menu from bein shown. 
            dispatch({ type: RoleActionType.SetRoleBasedAccessControls, payload: rbacdetails });
        }
        catch(error)
        {
            toast.error(<div><i className="fas fa-times mr-2"></i>{`Oops! We encountered an error during synchronization. Please refresh the page and try again`}</div>);
            dispatch({ type: RoleActionType.SetRoleBasedAccessControls, payload: rbacdetails });
        }        
    };

      // this to get the user information in relation to the right manamgment acesss he has.
      // its main scope is to avode invoking the synchRoleBasedAccessControl every time.
      const fetchControls = () : RoleBasedAccessControlDetails | null => {
        const data = localStorage.getItem("roleBasedAccessControlDetails");
        if (data) 
        {
          try 
          {
            return JSON.parse(data) as RoleBasedAccessControlDetails;
          } 
          catch (error) 
          {
            toast.error(<div><i className="fas fa-times mr-2"></i>{`We were unable to get your data due to an unexpected error. Please try again later.`}</div>);
          }
        }
        return null;
    };
    
    // This to re invoke the right functionality when needed.
    const resynchronizeControls  = () => {
        synchRoleBasedAccessControl();
        //InitRoleBasedAccessControlWithPromise();
    } 

    // this will retrive information in relation to the crud operations avaliable.
    const getSecurityClassification = () => { 
        try 
        {
            let controls = fetchControls(); // get controls

            if (controls === null || controls?.securityClassifications.length === 0) { // check if we have details.
                resynchronizeControls(); // try and re bind data.
                controls = fetchControls(); // fetch again.
            }

            // set the payload as expected with the expected values, a list of actions one can do. 
            dispatch({ type: RoleActionType.GetSecurityClassification, payload: controls?.securityClassifications })
        } catch (error) {
            toast.error(<div><i className="fas fa-times mr-2"></i>{`Oops! We encountered an error while getting your classifications access. Please refresh the page and try again`}</div>);
            dispatch({ type: RoleActionType.GetSecurityClassification, payload: [] })
        }
    };

    /**
     * this will retrive information in relation to the crud operations avaliable.
     * Here we used Promise : which are often used in React applications for asynchronous operations, such as fetching data from a server, 
     * performing animations, or executing long-running computations. In my case, this will allow me to handle the state readability and page 
     * visualization of components. 
     * @returns 
     */
    const getPromisedSecurityClassification = (): Promise<string[]> => {
        return new Promise((resolve, reject) => {
            try {
                let controls = fetchControls(); // get controls

                if (controls === null || controls?.securityClassifications.length === 0) { // check if we have details.
                    resynchronizeControls(); // try and re bind data.
                    controls = fetchControls(); // fetch again.
                }
    
                const classifications = controls?.securityClassifications;
                
                dispatch({ type: RoleActionType.GetSecurityClassification, payload: classifications });
                
                resolve(classifications || []);
            } catch (error) {
                toast.error(<div><i className="fas fa-times mr-2"></i>{`Oops! We encountered an error while getting your classifications access. Please refresh the page and try again`}</div>);
                dispatch({ type: RoleActionType.GetSecurityClassification, payload: [] })
                reject("Unable to get classifications access");
            }
        });
    };

    /**
     * This will try and get if the user has page permission or not.
     * Here we used Promise : which are often used in React applications for asynchronous operations, such as fetching data from a server, 
     * performing animations, or executing long-running computations. In my case, this will allow me to handle the state readability and page 
     * visualization of components. 
     * @param pageName 
     * @returns 
     */
    const retrievePagePermission = (pageName: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
            try {
                let controls = fetchControls(); // get controls

                if (controls === null || controls?.pages.length === 0) { // check if we have page details.
                    resynchronizeControls(); // try and re bind data.
                    controls = fetchControls(); // fetch again.
                }
                const isPageGranted = controls?.pages?.includes(pageName.toLowerCase()); // check if page is granted.

                dispatch({ type: RoleActionType.GetPageAccess, payload: isPageGranted });  // set payload.
                resolve(!!isPageGranted); // and return.
            } 
            catch (error) 
            {
                toast.error(<div><i className="fas fa-times mr-2"></i>{`Oops! We encountered an error while getting page permissions. Please refresh the page and try again`}</div>);
                dispatch({ type: RoleActionType.GetPageAccess, payload: false });
                reject("Unable to get page permissions");
            }
        });
    };

    /**
     * This will try and get if the user has menu permissions or not.
     */
    const getAllowedNavigationItems =  () => { 
        try 
        {
            let controls = fetchControls(); // get controls
            
            if (controls === null || controls.menus.length === 0) {
                resynchronizeControls(); // try and re bind data.
                controls = fetchControls(); // fetch again.
            }

            const menuItems = controls?.menus; // get and set value to be used for payload.

            dispatch({ type: RoleActionType.GetAllowedNavigationItems, payload: menuItems}); // set the payload.
        } catch (error) {
            toast.error(<div><i className="fas fa-times mr-2"></i>{`Oops! We encountered an error while getting menu permissions. Please refresh the page and try again`}</div>);
            dispatch({ type: RoleActionType.GetAllowedNavigationItems, payload: [] })
        }
    };

    /** This will try and get if the user has menu permissions or not.
     * Here we used Promise : which are often used in React applications for asynchronous operations, such as fetching data from a server, 
     * performing animations, or executing long-running computations. In my case, this will allow me to handle the state readability and page 
     * visualization of components. 
     */
    const getPromisedAllowedNavigationItems = () => {
        return new Promise((resolve, reject) => {
          try 
          {

            let controls = fetchControls(); // get controls
            
            if (controls === null || controls.menus.length === 0) {
                resynchronizeControls(); // try and re bind data.
                controls = fetchControls(); // fetch again.
            }

            const menuItems = controls?.menus; // get and set value to be used for payload.
            
            dispatch({ type: RoleActionType.GetAllowedNavigationItems, payload: menuItems});
            
            resolve(menuItems);
          } 
          catch (error) 
          {
            toast.error(<div><i className="fas fa-times mr-2"></i>{`Oops! We encountered an error while getting menu permissions. Please refresh the page and try again`}</div>);
            dispatch({ type: RoleActionType.GetAllowedNavigationItems, payload: [] })
            reject("Unable to get menu permissions");
          }
        });
      };

      const getUserRoles = () => {
        try 
        {

          let controls = fetchControls(); // get controls
          
          if (controls === null || controls.menus.length === 0) {
              resynchronizeControls(); // try and re bind data.
              controls = fetchControls(); // fetch again.
          }

          const roles = controls?.roles; // get and set value to be used for payload.
          
          dispatch({ type: RoleActionType.GetUserRoles, payload: roles});
        } 
        catch (error) 
        {
          toast.error(<div><i className="fas fa-times mr-2"></i>{`Oops! We encountered an error while getting user roles. Please refresh the page and try again`}</div>);
          dispatch({ type: RoleActionType.GetUserRoles, payload: [] })
        }
    };

    // this will hanlde the reset of the role base access control so that new configurations can be set.
    const redistributeConfigurations = () => { 
        if(fetchControls() !== null){
            localStorage.removeItem("roleBasedAccessControlDetails");
        }
    };

    const resetConfigurations = () => {
        if(fetchControls() !== null) {
            try 
            {
                // let controls = fetchControls(); // get controls
                
                // if (controls === null || controls.menus.length === 0) {
                //     resynchronizeControls(); // try and re bind data.
                //     controls = fetchControls(); // fetch again.
                // }

                // const roles = controls?.roles; // get and set value to be used for payload.
                
                dispatch({ type: RoleActionType.ResetConfigurations });
            } 
            catch (error) 
            {
                toast.error(<div><i className="fas fa-times mr-2"></i>{`Oops! We encountered an error while resetting configurations. Please refresh the page and try again`}</div>);
                dispatch({ type: RoleActionType.ResetConfigurations, payload: [] })
            }
        }
    }

    // thsi will be the end result which the state is able to expose when invoked.
    return (
        <RoleBasedAccessControlContext.Provider value={{ 
                areConfigurationsSet : state.areConfigurationsSet,
                hasPageAccess: state.hasPageAccess, 
                navigationItems : state.navigationItems,
                securityClassifications: state.securityClassifications,
                stateError : state.stateError,
                roles: state.roles,
                synchRoleBasedAccessControl,
                retrievePagePermission,
                getSecurityClassification,
                getPromisedSecurityClassification,
                getAllowedNavigationItems,
                getPromisedAllowedNavigationItems,
                clearErrors,
                getUserRoles,
                redistributeConfigurations,
                resetConfigurations
            }}>
        {children}
    </RoleBasedAccessControlContext.Provider>
    );
  };

  export default RoleBasedAccessControlState;