import React from 'react';
import Wrapper from '../Wrapper'

const NoPermissionsPage = () => {

    const itsmURL = process.env.REACT_APP_ITSM_PORTAL_URL;

    return (
        <Wrapper title="Error Page" subTitle="No Permissions">
            <div className="card-body">
                <div className="error-page">
                    <h2 className="headline text-warning"><i className="fas fa-exclamation-triangle text-warning" /></h2>
                    <div className="error-content">
                        <h3>Permission Denied.</h3>
                        <p>
                          Please note that you have not been assigned any permissions to view or manage the content of the website. 
                        </p>
                        <p>
                            Kindly contact EML EU Support Team to obtain authorization.  
                        </p>
                        <p>
                            Please follow link to submit an access request : <a href={itsmURL}> Open Request</a>
                        </p>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default NoPermissionsPage;