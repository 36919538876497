import { useEffect, useState } from "react";
import { debounceTime, distinctUntilChanged, Subject } from "rxjs";

function useDebounce(value: any, delay: number){
    const [debouncedValue, setDebouncedValue] = useState(value);
    let [value$] = useState(() => new Subject<string>());
    useEffect(() => {
        const sub = value$.pipe(
          debounceTime(delay),
          distinctUntilChanged(),          
        ).subscribe(setDebouncedValue);
        return () => sub.unsubscribe();
      }, [value$, delay])
    
   return [debouncedValue, (v: any) => value$.next(v)];
  }

export default useDebounce;