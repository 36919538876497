import { RoleActionType }  from  "../../models/enum/RoleActionType";
import {RoleBasedAccessControlInitState } from "../../models/IRoleBasedControlAccess";

const roleBasedControlAccessReducer = (state: RoleBasedAccessControlInitState, action: any) => {
    switch (action.type) {
      case RoleActionType.SetRoleBasedAccessControls:
        localStorage.setItem("roleBasedAccessControlDetails", JSON.stringify(action.payload)); 
        return {
            ...state,  
            areConfigurationsSet : true,
        };
    case RoleActionType.GetUserRoles:
        return {
            ...state,
            roles: action.payload,
        };
    case RoleActionType.GetSecurityClassification:
        return {
            ...state,
            securityClassifications: action.payload,
        };
      case RoleActionType.GetAllowedNavigationItems:
        return {
            ...state,
            navigationItems: action.payload,
        };
      case RoleActionType.GetPageAccess:
        return {
            ...state,
            hasPageAccess : action.payload,
        };
        case RoleActionType.SetError:
            return {
                ...state,
                stateError: action.payload,
            };   
        case RoleActionType.ClearError:
            return {
                ...state,
                stateError: null,
            };
        case RoleActionType.ResetConfigurations:
            localStorage.removeItem("roleBasedAccessControlDetails");
            return {
                ...state,  
                areConfigurationsSet : false,
                hasPageAccess: false,
                navigationItems : [],
                stateError : null,
                roles: [],
            };
        default:
            return state;
    }
};


export default roleBasedControlAccessReducer;
