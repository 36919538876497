import { IssuerInitState } from "../../models/IIssuer";
import {
  CLEAR_ERRORS,
  GET_ISSUER,
  GET_ISSUERS,
  GET_ISSUERS_FOR_SELECT,
  ISSUER_ERROR,
  SET_CURRENT_ISSUER
} from "../types";

const IssuerReducer = (state: IssuerInitState, action: any) => {
  switch (action.type) {
    case GET_ISSUERS:
      return {
        ...state,
        issuers: action.payload.data,
        totalRows: action.payload.paging.totalRecordCount,
        loading: false,
        pageSize: action.payload.paging.pageSize,
        pageNo: action.payload.paging.pageNo,
      };

    case GET_ISSUERS_FOR_SELECT:
      return {
        ...state,
        issuersForSelect: action.payload,
        loading: false,
      };

    case GET_ISSUER:
      return {
        ...state,
        issuerDetails: action.payload,
        loading: false,
      };

    case ISSUER_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    case SET_CURRENT_ISSUER:
      return {
        ...state,
        defaultIssuerId: action.payload?.id,
        defaultIssuerName:action.payload?.name
      };

    default:
      return state;
  }
};

export default IssuerReducer;
