import React, { useContext, useState } from 'react'
import Spinner from '../../../../components/spinner/Spinner';
import SetupContext from '../../../../context/setup/SetupContext';
import CountsTree from './CountsTree';
import ConsolidatedView from './ConsolidatedView';
import Popup from '../../../../components/dialog/Popup';
import UnauthorizedPage from "../../../errors/UnauthorizedPage";
import SecurityClassificationError from "../../../errors/SecurityClassification";


const TransactionCounts = (props: any) => {
    
    const { issuerId, setupId,securityClassifications,canPageBeRendered,proccedAndLoad } = props;
    const [consolidatedView, setConsolidatedView] = useState(false);
    const { transactionCounts, loading } = useContext(SetupContext);

    const readAccess = securityClassifications?.includes("Read");
    const createAccess = securityClassifications?.includes("Create");
    const updateAccess = securityClassifications?.includes("Update");

    if(!canPageBeRendered)
    {
        return (<div className="card-body" >< Spinner /> </div>)
    }

    if(!proccedAndLoad)
    {
        return <UnauthorizedPage withWrapper={false} />;
    }

    if(!readAccess){
        return (<SecurityClassificationError title="Read Classification Denied" pageName="Transaction Counts" extraReference='Transaction Counts' withWrapper={false}/>)
    }

    return (
        !loading ?
            <div className="col-md-8">
                <span>
                    {
                        readAccess && <button type="button" className="btn button-control-warning btn-sm mb-1" onClick={() => setConsolidatedView(true)}>
                        <i className="fas fa-info-circle p-1" />
                        <span>Consolidated View</span>
                    </button>
                    }
                </span>
                <table className="table table-borderless table-hover table-sm">
                    <tbody>
                        <CountsTree data={transactionCounts.kyc} title="KYC Customers" customerType={'kyc'} issuerId={issuerId} setupId={setupId} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />
                        <CountsTree data={transactionCounts.kycLite} title="KYC Lite Customers" customerType={'kyclite'} issuerId={issuerId} setupId={setupId} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />
                        <CountsTree data={transactionCounts.sdd} title="SDD Customers" customerType={'sdd'} issuerId={issuerId} setupId={setupId} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />
                        <CountsTree data={transactionCounts.anonymous} title="Anonymous Customers" customerType={'anonymous'} issuerId={issuerId} setupId={setupId} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />
                    </tbody>

                    {
                        readAccess && <Popup title="Transaction Counts Consolidated View" openPopup={consolidatedView} setOpenPopup={setConsolidatedView} size='lg'>
                            <ConsolidatedView data={transactionCounts} issuerId={issuerId} setupId={setupId} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}  />
                        </Popup>
                    }
                </table>
            </div>
            : <Spinner />
    )
}

export default TransactionCounts
