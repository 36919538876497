import { SyntheticEvent, useContext, useState } from "react";
import SetupContext from "../../../../context/setup/SetupContext";
import { checkInputPattern, currencyFormat } from "../../../../models/Helper";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { HelpDescriptions } from "../../../../models/enum/helpDescriptions";
import SecurityClassificationError from "../../../errors/SecurityClassification";
import Spinner from '../../../../components/spinner/Spinner';

interface IRegionalLimit {
  authorizationDropOffPeriod: number | null,
  maxTransactionAmount: number | null,
  daily: number | null,
  monthly: number | null,
  yearly: number | null,
  lifetime: number | null
}

const UpdateRegionalLimit = (props: any) => {
  const { data, setOpenPopup, issuerId, setupId, currencyCode, customerType, periodicLimit, limitType, messageReference, securityClassifications, proccedAndLoad, canPageBeRendered } = props;
  const { updateRegionalLimit } = useContext(SetupContext);

  const updateAccess = securityClassifications?.includes("Update");
  const createAccess = securityClassifications?.includes("Create");
  
  const setDefaultVal = (val: number | null, divisor: number) => {    
    return val === null ? null : val === 0 ? 0 : Number(currencyFormat(val/divisor));        
  };

  const setPayload = (val: number | null, multiplier: number) => {    
    return val === null ? null : val * multiplier;        
  };

  const setDisplay = (val: number | null) => {
    return val === null ? '' : val;
  };

  const [regionalLimit, setRegionalLimit] = useState<IRegionalLimit>({
    authorizationDropOffPeriod: setDefaultVal(data['authorizationDropOffPeriod'], 1),
    maxTransactionAmount: setDefaultVal(data['maxTransactionAmount'],100),
    daily: setDefaultVal(data['daily'],100),
    monthly: setDefaultVal(data['monthly'],100),
    yearly: setDefaultVal(data['yearly'],100),
    lifetime: setDefaultVal(data['lifetime'],100),
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const {name, value } = e.target      
      setRegionalLimit({ ...regionalLimit, [name]: value === "" ? null : Number(value) })
  }

  const onReset = () => {
    setRegionalLimit({
      authorizationDropOffPeriod: setDefaultVal(data['authorizationDropOffPeriod'],1),
      maxTransactionAmount: setDefaultVal(data['maxTransactionAmount'] ,100),
      daily: setDefaultVal(data['daily'] ,100),
      monthly: setDefaultVal(data['monthly'] ,100),
      yearly: setDefaultVal(data['yearly'] ,100),
      lifetime: setDefaultVal(data['lifetime'] ,100),
    })
  }

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const payload = {
      authorizationDropOffPeriod: setPayload(regionalLimit['authorizationDropOffPeriod'],1),
      maxTransactionAmount: setPayload(regionalLimit['maxTransactionAmount'],100),
      daily: setPayload(regionalLimit['daily'],100),
      monthly: setPayload(regionalLimit['monthly'],100),
      yearly: setPayload(regionalLimit['yearly'],100),
      lifetime: setPayload(regionalLimit['lifetime'],100),
    };
    
    updateRegionalLimit(issuerId, setupId, payload, currencyCode, customerType, periodicLimit, limitType);
    setOpenPopup(false);
  };

  if(!canPageBeRendered)
  {
      return (<div className="card-body" >< Spinner /> </div>)
  }

  if(!proccedAndLoad || !updateAccess)
  {
    return (<SecurityClassificationError title="" pageName="" extraReference={`${messageReference}`} withWrapper={false}/>)
  }
  
  return (
    <form className="row" onSubmit={onSubmit} onReset={onReset}>
      <div className="col-sm-12">
        {periodicLimit.toLowerCase() === "globally" ? null : (
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Drop Off Period
            &nbsp;<i className="fas fa-info-circle" id="authorizationDropOffPeriodLabel" data-tooltip-variant="info" aria-hidden="true"></i>
            </label>
            <Tooltip anchorId="authorizationDropOffPeriodLabel" className='tooltip'>
                <div>                    
                    {HelpDescriptions.AUTH_DROP_OFF}
                </div>
            </Tooltip>
            <div className="col-sm-7">
              <input
                type="text"
                className="form-control"
                placeholder="Drop Off Period"
                name="authorizationDropOffPeriod"
                onKeyPress={(event) => {
                  if (checkInputPattern(event)) {
                    event.preventDefault();
                  }
                }}
                autoComplete="off"
                // maxLength={6}
                defaultValue={setDisplay(regionalLimit["authorizationDropOffPeriod"])}
                onChange={onChange}                
              />
            </div>
          </div>
        )}
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">Max Transaction Amount</label>
          <div className="col-sm-7">
            <input
              type="text"
              className="form-control"
              placeholder="Max Transaction Amount"
              name="maxTransactionAmount"
              onKeyPress={(event) => {
                if (checkInputPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={setDisplay(regionalLimit["maxTransactionAmount"])}
              onChange={onChange}              
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">Daily</label>
          <div className="col-sm-7">
            <input
              type="text"
              className="form-control"
              placeholder="Daily"
              name="daily"
              onKeyPress={(event) => {
                if (checkInputPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={setDisplay(regionalLimit["daily"])}
              onChange={onChange}              
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">Monthly</label>
          <div className="col-sm-7">
            <input
              type="text"
              className="form-control"
              placeholder="Monthly"
              name="monthly"
              onKeyPress={(event) => {
                if (checkInputPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={setDisplay(regionalLimit["monthly"])}
              onChange={onChange}              
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">Yearly</label>
          <div className="col-sm-7">
            <input
              type="text"
              className="form-control"
              placeholder="Yearly"
              name="yearly"
              onKeyPress={(event) => {
                if (checkInputPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={setDisplay(regionalLimit["yearly"])}
              onChange={onChange}              
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label">Lifetime</label>
          <div className="col-sm-7">
            <input
              type="text"
              className="form-control"
              placeholder="Lifetime"
              name="lifetime"
              onKeyPress={(event) => {
                if (checkInputPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={setDisplay(regionalLimit["lifetime"])}
              onChange={onChange}              
            />
          </div>
        </div>
        <hr />
        <div className="form-group row">
          <div className="float-right">
            <button
              type="button"
              className="btn-secondary btn-sm m-1"
              onClick={() => setOpenPopup(false)}
            >
              Cancel
            </button>
            <button
              type="reset"
              className="btn-warning btn-sm m-1"              
            >
              Reset
            </button>
            {
              createAccess && 
              <button type="submit" className="btn-success btn-sm m-1">
                Save
              </button>
            }
          </div>
        </div>
      </div>
    </form>
  );
};

export default UpdateRegionalLimit;
