import React, { useReducer } from "react";
import { createAPIEndpoint, ENDPOINTS } from "..";
import { AuthInitState } from "../../models/Auth";
import {
  AUTH_USER,
  CLEAR_ERRORS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  SET_DISABLED,
} from "../types";
import AuthContext from "./AuthContext";
import AuthReducer from "./AuthReducer";

const AuthState: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const initialState: AuthInitState = {
    token: localStorage.getItem("token"),
    isAuthenticated: false,
    user: null,
    loading: true,
    error: null,
    disabled: false,
  };
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  // login user
  const loginUser = async () => {
    await createAPIEndpoint(ENDPOINTS.AUTH)
      .login()
      .then((res) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (!err.response) {
          dispatch({
            type: LOGIN_FAIL,
            payload: { error: "Make sure that the API is running" },
          });
        } else {
          dispatch({
            type: LOGIN_FAIL,
            payload: {
              error: `${err.response.data.errors.ErrorCode}: ${err.response.data.errors.Description}`,
            },
          });
        }
      });
  };

  const setDisabled = () => {
    dispatch({
      type: SET_DISABLED,
    });
  };

  const getUser = async () => {
    await createAPIEndpoint(ENDPOINTS.AUTH)
      .get("user")
      .then((res) => {
        dispatch({
          type: AUTH_USER,
          payload: res.data,
        });
      });
  };

  const logOut = () => {
    dispatch({
      type: LOGOUT,
      payload: {},
    });
  };

  // clear errors
  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        loading: state.loading,
        error: state.error,
        disabled: state.disabled,
        loginUser,
        getUser,
        logOut,
        clearErrors,
        setDisabled,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthState;
