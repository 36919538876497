import { FormEvent } from 'react'
import { Link } from 'react-router-dom';

const TableSelect = (props: any) => {    
    const { options, setIssuerId, defaultLabel, hasAddButton, addButtonTitle, addButtonLink, selectedIssuerId } = props;

    const handleInputChange = (e: FormEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget;
        setIssuerId(value !== '' ? value : null);
    }

    return (
        <div className="col-md-12 col-sm-12">
            <div className="dataTables_length">
                <label>Select:{'     '}
                    <select value={selectedIssuerId || ''} name="select_options" className="form-control form-control2" onChange={(e: any) => handleInputChange(e)} required>
                        <option value=''>{defaultLabel}</option>
                        {
                            options.map((item: any) => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))
                        }

                    </select>
                    {
                        hasAddButton && 
                        <Link to={addButtonLink}  className="btn btn-round btn-success ml-2">
                            <i className="fa fa-plus p-1" />
                            <span>{addButtonTitle}</span>
                        </Link>
                    }

                </label>
            </div>
        </div>
    )
}

export default TableSelect
