import axios, { AxiosResponse } from "axios";
import { IForextMgt } from "../models/IForextMgt";
import { TSetup } from "../models/Setup";
import { TContactlessLimit } from "../models/TContactlessLimit";
import { TIssuerWebHookModel } from "../models/TIssuerWebHookModel";
import { TPanConfig } from "../models/TPanConfig";
import { TLoadLimit } from "../models/TLoadLimit";
import { TMccLimit } from "../models/TMccLimit";
import { TMccCount } from "../models/TMccCount";
import { TTransactionCounts } from "../models/TTransactionCounts";
import { TRegionalLimit } from "../models/TRegionalLimit";
import '../components/toast/Toast.css';
import { IssuerForCreate } from "../models/IIssuer";
import { TGlobalLimit } from "../models/TGlobalLimit";
import { TCardVendor, TCardVendorKey } from "../models/ICardVendor";


axios.defaults.baseURL = window.__RUNTIME_CONFIG__.REACT_APP_GATEWAY_URL;

const sleep = (ms: number) => (reponse: AxiosResponse) => 
    new Promise<AxiosResponse>(resolve => setTimeout(() => resolve(reponse), ms));

export const ENDPOINTS = {
    ISSUER: 'Issuer',
    SETUP: (issuerId: string) => `/Issuer/${issuerId}/Setup`,
    SELECT_OPTIONS: 'SelectOptions',
    AUTH: 'Auth',
    FOREXMGT: 'ForexManagement',
    ACCOUNTS: 'Account',
    TRANSACTIONS: 'Transaction'
}

const config = {
    headers: {
        "Content-Type": "application/json"
    }
};

const setPanConfigModel = (strField: string, settingValue: boolean) => {
    switch (strField) {
        case "ARQC":
        case "Atm":
        case "BalanceEnquiry":
        case "Ecommerce":
        case "PinChange":
        case "Moto":
        case "Pos":
        case "Contactless":
        case "Mdes":
        case "AcquirerFees":
        case "MdesArcq":
        case "Cashback":
        case "BalanceMode":
        case "NegativeFeeMode":
            return {
                SettingValue: settingValue
            };        
    }
}

export const createAPIEndpoint = (endpoint: string) => {
    let url = endpoint;
    return {
        fetchPaginated: (search: '', pageNo: number, pageSize: number) => axios.get(`${url}?search=${search}&pagesize=${pageSize}&pageNo=${pageNo}`),
        fetchById: (id: string) => axios.get(`${url}/${id}`).then(sleep(1000)),
        fetch_options: (type: string) => axios.get(`${url}/${type}`),
        login: () => axios.post(url),
        get: (strOption: string) => axios.get(`${url}/${strOption}`, config)
    }
}

export const createFXEndpoint = (endpoint: string) => {
    let url = endpoint;
    return {
        getFxRate: (source: string, buying: string) => axios.get(`${url}/source/${source}/buying/${buying}`),
        updateFxRate: (fx: IForextMgt) => axios.put(`${url}`, {
            headers: {
                "Content-Type": "application/json"
            }, data: fx
        })
    }
}

export const createACCOUNTSEndpoint = (endpoint: string) => {
    let url = endpoint;
    return {
        fetchPaginated: (search: '', pageNo: number, pageSize: number) => axios.get(`${url}?search=${search}&pagesize=${pageSize}&pageNo=${pageNo}`),
        getAccountDetails: (accountId: string) => axios.get(`${url}/${accountId}`)
    }
}

export const createSETUPEndpoint = (endpoint: string) => {
    let url = endpoint;
    return {
        panConfig_Add: (id: string, panConfig: TPanConfig) => axios.patch(`${url}/${id}/PanConfiguration`, panConfig, config),
        assignNewSetupToIssuer: (setup: TSetup) => axios.post(url, setup, config),
        patchContactLessLimit: (setupId: string, contactlessLimit: TContactlessLimit) => axios.patch(`${url}/${setupId}/ContactlessLimit`, contactlessLimit, config),
        patchSetupSetting:(setupId: string, settingNameToUpdate: string, settingValue: any) => axios.patch(`${url}/${setupId}/?settingNameToUpdate=${settingNameToUpdate}`, {settingValue}, config),
        currencyLimit_Add: (setupId: string, currency: number) => axios.post(`${url}/${setupId}/CurrencyLimits?currency=${currency}`),
        currencyFees_Add: (setupId: string, currency: number) => axios.post(`${url}/${setupId}/CurrencyFees?currency=${currency}`),
        loadLimit_Update: (setupId: string, currency: number, customerType: string, loadLimit: TLoadLimit) => axios
        .patch(`${url}/${setupId}/CurrencyLimits/loadlimit?currency=${currency}&customerType=${customerType}`, loadLimit, config),
        globalLimit_Update: (setupId: string, currency: number, customerType: string, globalLimit: TGlobalLimit, periodicLimit: string) => axios
        .patch(`${url}/${setupId}/CurrencyLimits/globallimit?currency=${currency}&customerType=${customerType}&periodicLimit=${periodicLimit}`, globalLimit, config),
        mccLimit_Update: (setupId: string, currency: number, customerType: string, mccLimit: TMccLimit) => axios
        .patch(`${url}/${setupId}/CurrencyLimits/mcclimit?currency=${currency}&customerType=${customerType}`, mccLimit, config),
        mccCount_Add: (setupId: string, customerType: string, mccCount: TMccCount) => axios
        .patch(`${url}/${setupId}/TransactionCounts/mcccounts?customerType=${customerType}`, mccCount, config),
        mccBlock_Add: (setupId: string, mccList: Array<Number>) => axios
        .patch(`${url}/${setupId}/blockMcc`, mccList, config),
        mccCount_Delete: (setupId: string, customerType: string, mcc: Array<Number>) => axios
        .delete(`${url}/${setupId}/TransactionCounts/remove-mccitem?customerType=${customerType}`, {
            headers: {
                "Content-Type": "application/json"
            }, data: mcc
        }),
        regionalLimit_Update: (setupId: string, currency: number, customerType: string, regionalLimit: TRegionalLimit, periodicLimit: string, limitType: string) => axios
        .patch(`${url}/${setupId}/CurrencyLimits/${limitType}limit?currency=${currency}&customerType=${customerType}&periodicLimit=${periodicLimit}`, regionalLimit, config),
        transactionCounts_Update: (setupId: string, customerType: string, transCount: TTransactionCounts, periodicLimit: string, limitType: string) => axios
        .patch(`${url}/${setupId}/TransactionCounts/${limitType}counts?customerType=${customerType}&periodicLimit=${periodicLimit}`, transCount, config),
        currencyFees_Update: (setupId: string, currency: number, customerType: string, fees: any, feesType: string, subType?: string) => axios
        .patch(`${url}/${setupId}/CurrencyFees/${feesType}?currency=${currency}&customerType=${customerType}${subType ? '&subType='+subType : ''}`, fees, config),
        cardVendor_Patch: (id: string, cardVendor: TCardVendor) => axios.patch(`${url}/${id}/CardVendor`, cardVendor, config),
        cardVendor_Delete: (id: string, cardVendorKey: TCardVendorKey) => axios.delete(`${url}/${id}/CardVendor`, {
            headers: {
                "Content-Type": "application/json"
            }, data: cardVendorKey
        }),
    }
}

export const createISSUEREndpoint = (endpoint: string) => {
    let url = endpoint;
    return {
        addHook: (id: string, hookName: string, model: TIssuerWebHookModel) => axios.post(`${url}/${id}/Hook/${hookName}`, model, config),
        addHookCallout: (id: string, hookName: string, model: TIssuerWebHookModel) => axios.post(`${url}/${id}/Hook/Callout/${hookName}`, model, config),
        deleteHook: (id: string, hookName: string, model: TIssuerWebHookModel) => axios.delete(`${url}/${id}/Hook/${hookName}`, {
            headers: {
                "Content-Type": "application/json"
            }, data: model
        }),
        deleteHookCallout: (id: string, hookName: string, model: TIssuerWebHookModel) => axios.delete(`${url}/${id}/Hook/Callout/${hookName}`, {
            headers: {
                "Content-Type": "application/json"
            }, data: model
        }),
        updateHookUri: (id: string, hookName: string, hookUri: string) => axios.put(`${url}/${id}/Hook/${hookName}`, { value: hookUri }, config),
        updateHookCalloutUri: (id: string, hookName: string, hookUri: string) => axios.put(`${url}/${id}/Hook/Callout/${hookName}`, { value: hookUri }, config),
        addIssuer: (model: IssuerForCreate) => axios.post(`${url}`,model,config)
    }
}

export const createTRANSACTIONSEndpoint = (endpoint: string) => {
    let url = endpoint;
    return {
        fetchPaginated: (from: string, to: string, pageNo: number, pageSize: number) => axios.get(`${url}?from=${from}&to=${to}&pagesize=${pageSize}&pageNo=${pageNo}`),
        handleTransactions: (transactionId: string, transactionAuthorisation: string) => axios.post(`${url}?transactionId=${transactionId}&transactionAuthorisation=${transactionAuthorisation}`)
       
    }
} 

