import React, { useContext, useEffect, useMemo, useState } from 'react';
import Popup from '../../../components/dialog/Popup';
import Spinner from '../../../components/spinner/Spinner';
import { TableHeaders, TableInputSearch, TablePaginator } from '../../../components/table';
import RoleBasedAccessControlContext from '../../../context/rolebasedaccesscontrol/RoleBasedAccessControlContext';
import ForexMgtContext from '../../../context/forexmgt/ForexMgtContext';
import { fxColumns } from '../../../models/enum/columns';
import Wrapper from '../../Wrapper';
import UpdateFx from './UpdateFx';
import UnauthorizedPage from "../../errors/UnauthorizedPage";
import SecurityClassificationError from "../../errors/SecurityClassification";
import { useLocation } from 'react-router-dom';

const ForexMgt = () => {
    const { securityClassifications,getSecurityClassification,retrievePagePermission}  = useContext(RoleBasedAccessControlContext);
    const location = useLocation();
    const [proccedAndLoad, setUserAccess] = useState<boolean>(false);
    const [canPageBeRendered, canInvokePageContent] = useState(false); 

    useEffect(() => {
        let pageName = location?.pathname?.split('/').pop()?.toLowerCase();
        retrievePagePermission(pageName).then((isGranted: boolean) => {
            canInvokePageContent(true);
            setUserAccess(isGranted);
        }).catch((error: any) => {
            canInvokePageContent(false);
            setUserAccess(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        getSecurityClassification();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const readAccess = securityClassifications?.includes("Read");
    const updateAccess = securityClassifications?.includes("Update");

    const { fx, getFxRate, pageSize, pageNo, totalRows, loading, getFxRates } = useContext(ForexMgtContext);
    const [page, setPage] = useState(pageNo);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [itemsPerPage, setItemsSize] = useState(pageSize);
    const [openPopup, setOpenPopup] = useState(false);    

    useEffect(() => {        
        getFxRates(search, itemsPerPage, page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, itemsPerPage, page]);

    const fxData = useMemo(() => {
        let computedFx = fx.data;

        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedFx = computedFx.sort((a: any, b: any) => reversed * a[sorting.field].localeCompare(b[sorting.field]))
        }

        return computedFx;
    }, [fx, sorting]);

    if(!canPageBeRendered)
    {
        return (<Wrapper title="Foreign Exchange Management" subTitle="List of Exchange"> < Spinner /> </Wrapper> )
    }
    
    if(!proccedAndLoad)
    {
        return <UnauthorizedPage />;
    }

    if(!readAccess){
        return (<SecurityClassificationError title="Read Classification Denied" pageName="Foreign Exchange Management" extraReference="Foreign Exchange Management" withWrapper={true}/>)
    }
    
    return (
        <Wrapper title="Foreign Exchange Management">
            <div className="card-body">
                {
                    !loading ?
                        <div className="dataTables_wrapper dt-bootstrap4">
                            <div className="row">
                                <div className="col-sm-12 col-md-6">

                                </div>

                                <div className="col-sm-12 col-md-6">
                                    <div className="dataTables_filter">
                                        <TableInputSearch
                                            onSearch={(value: string) => {
                                                setSearch(value);
                                                setPage(1);
                                            }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <table id="table-data" className="table table-striped dataTable dtr-inline table-sm table-bordered" role="grid">
                                        <TableHeaders
                                            headers={fxColumns}
                                            onSorting={(field: string, order: string) => {
                                                setSorting({ field, order })
                                            }} />
                                        <tbody>
                                            {
                                                fxData !== undefined && Object.keys(fxData).length !== 0 && fxData.constructor !== Object ? (
                                                    fxData.map(
                                                        (fx: any, idx: number) => (
                                                            <tr key={idx}>
                                                                <td>
                                                                {
                                                                    updateAccess &&
                                                                    <a href="#!" onClick={() => setOpenPopup(true)}>
                                                                        <i className="fas fa-edit text-warning" onClick={() => 
                                                                            {
                                                                                setOpenPopup(true);
                                                                                getFxRate(fx.sourceCurrency, fx.buyingCurrency);
                                                                            }
                                                                        }></i>
                                                                    </a>
                                                                    }
                                                                </td>
                                                                <td>{fx.sourceCurrency}</td>
                                                                <td>{fx.buyingCurrency}</td>
                                                                <td>{fx.exchangeRate}</td>
                                                                <td>{fx.provider}</td>
                                                            </tr>
                                                        )
                                                    )
                                                ) : (
                                                    <tr><td className="text-center text-danger" colSpan={6}>No data available in table</td></tr>
                                                )
                                            }
                                        </tbody>

                                    </table>
                                </div>
                            </div>

                            <TablePaginator
                                total={totalRows}
                                itemsPerPage={pageSize}
                                currentPage={pageNo}
                                onPageChange={(page: any) => setPage(page)}
                                onPageSizeChange={(size: number) => setItemsSize(size)}
                            />
                        </div> : <Spinner />
                }
            </div>
            
            {
                updateAccess && <Popup title="Update Exchange Rate" openPopup={openPopup} setOpenPopup={setOpenPopup} >
                    <UpdateFx setOpenPopup={setOpenPopup} />
                </Popup>
            }
        </Wrapper>
    )
}

export default ForexMgt
