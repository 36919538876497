import React, { useContext } from 'react'
import Spinner from '../../../../components/spinner/Spinner';
import SetupContext from '../../../../context/setup/SetupContext';
import CountsTree from './CountsTree';

const ConsolitatedView = (props: any) => {
    const { issuerId, setupId,securityClassifications,canPageBeRendered,proccedAndLoad } = props;
    const { transactionCounts, loading } = useContext(SetupContext);

    return (
        !loading ? (<div className="consolidated-wrapper">
            <div className="col-md-12">
                <table className="table table-borderless table-sm">
                    <tbody>
                        <CountsTree data={transactionCounts.kyc} title="KYC Customers" customerType={'kyc'} issuerId={issuerId} setupId={setupId} isExpanded={true} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}/>
                        <CountsTree data={transactionCounts.kycLite} title="KYC Lite Customers" customerType={'kyclite'} issuerId={issuerId} setupId={setupId}isExpanded={true} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad} />
                        <CountsTree data={transactionCounts.sdd} title="SDD Customers" customerType={'sdd'} issuerId={issuerId} setupId={setupId} isExpanded={true} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}/>
                        <CountsTree data={transactionCounts.anonymous} title="Anonymous Customers" customerType={'anonymous'} issuerId={issuerId} setupId={setupId} isExpanded={true} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}/>
                    </tbody>
                </table>
            </div>
        </div>)
            : <Spinner />
    )
}

export default ConsolitatedView;
