import { useEffect, useRef, useState } from "react"
import { useDidUpdateEffect } from "../../hooks/useDidUpdateEffect"
import "./tagsInput.css"

interface ITagsInputProps {
    inputTags?: string[]
    placeHolder: string
    onChange: (val: any) => void
    onValidate?: (val: string) => boolean
}

const TagsInput = (props: ITagsInputProps) => {
    const { inputTags, placeHolder, onChange, onValidate } = props
    const [tags, setTags] = useState<Array<any>>(inputTags || [])
    const [focusOut, setFocusOut] = useState(false)
    const inputReference = useRef<null | HTMLInputElement>(null);
    const [duplicateBlockException, setDuplicateBlockException] = useState<string | null>(null);

    useDidUpdateEffect(() => {
        onChange && onChange(tags);
    }, [tags]);

    useDidUpdateEffect(() => {
        if (inputTags && JSON.stringify(inputTags) !== JSON.stringify(tags)) {
            setTags(inputTags);
        }
    }, [inputTags]);

    useEffect(() => {
        inputReference?.current?.focus();
    }, []);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        e.stopPropagation();

        const value = e.currentTarget.value?.trim()

        if (!value && tags.length && e.code === "Backspace") {
            e.currentTarget.value = ''
            setTags([...tags.slice(0, -1)]);
        }

         if (value && (e.code === "Enter" || e.code === "Space" )) {
            
            e.preventDefault();
            setDuplicateBlockException(null)
            if (onValidate && !onValidate(value)) return

            if (tags.includes(value) || tags.includes(Number(value))) {

                setDuplicateBlockException(`'${value}' already exists`);
                return 
            } else { 
                
                setDuplicateBlockException(null) 
            }   

            setTags([...tags, value])
            e.currentTarget.value = ''
            
        }
    };

    const handleFocusOut = (e: any) => {
        setFocusOut(true);
        const value = e.currentTarget.value?.trim()
        setDuplicateBlockException(null)

        if (!e.currentTarget.value) return

        if (onValidate && !onValidate(value)) return

        if (tags.includes(value) || tags.includes(Number(value))) {

            setDuplicateBlockException(`'${value}' already exists`)
            return
        }
        else {setDuplicateBlockException(null)}

        setTags([...tags, value])
        e.currentTarget.value = ''
    };

    const removeTag = (index: any) => {
        setTags(tags.filter((el: any, i: any) => i !== index));
    };

    return (
        <>
            <div className="tags-input-container">
                {
                    tags.map((tag: any, index: any) => (
                        <div className="tag-item" key={index}>
                            <span className="text">{tag}</span>
                            <span className="close" onClick={() => removeTag(index)}>&times;</span>
                        </div>
                    ))
                }
                <input type="text"
                    ref={inputReference}
                    className="tags-input"
                    placeholder={placeHolder}
                    onKeyDown={handleKeyDown}
                    onBlur={handleFocusOut}
                />
            </div>
            {duplicateBlockException && (
                <div className="validation-error">
                    {duplicateBlockException}
                </div>
            )}
        </>
    )
}

export default TagsInput