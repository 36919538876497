import React, { useReducer } from 'react'
import { toast } from 'react-toastify';
import { createAPIEndpoint, createSETUPEndpoint, ENDPOINTS } from '..';
import { TPanConfig } from '../../models/TPanConfig';
import { TLoadLimit } from '../../models/TLoadLimit';
import { TMccCount } from '../../models/TMccCount';
import { TRegionalLimit } from '../../models/TRegionalLimit';
import { TTransactionCounts } from '../../models/TTransactionCounts';
import { SetupInitState } from '../../models/ISetup';
import { GET_SETUP, GET_SETUPS, SETUP_ERROR } from '../types';
import SetupContext from './SetupContext'
import SetupReducer from './SetupReducer';
import { TSetup } from '../../models/Setup';
import { TContactlessLimit } from '../../models/TContactlessLimit';
import { TMccLimit } from '../../models/TMccLimit';
import { TGlobalLimit } from '../../models/TGlobalLimit';
import { useNavigate } from 'react-router-dom';
import { TCardVendor, TCardVendorKey } from '../../models/ICardVendor';


const SetupState: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    const navigate = useNavigate();
    const initialState: SetupInitState = {
        setups: [],
        setupDetails: null,
        panConfigs: null,
        blockedMCCs: null,
        currencyLimits: [],
        transactionCounts: null,
        currencyFees: [],
        contactlessLimits: null,
        cardVendorDetail: [],
        totalRows: 0,
        loading: true,
        pageSize: 10,
        pageNo: 1,
        error: null
    }

    const [state, dispatch] = useReducer(SetupReducer, initialState);

    // get setups
    const getSetups = async (search: '', pageNo: number, pageSize: number, issuerId: string) => {
        await createAPIEndpoint(ENDPOINTS.SETUP(issuerId)).fetchPaginated(search, pageSize, pageNo)
            .then(res => {
                dispatch({ type: GET_SETUPS, payload: res.data })
            }).catch(err => {
                toast.error(err, err.message)
            });
    }

    // get setup details
    const getSetupDetails = async (issuerId: string, id: string) => {
        await createAPIEndpoint(ENDPOINTS.SETUP(issuerId)).fetchById(id)
            .then(res => {
                dispatch({ type: GET_SETUP, payload: res.data.data })
            }).catch(error => {
                toast.error(<div><i className="fas fa-times mr-2"></i>{"Network Error"}</div>);
            });
    }

    // add pan config
    const addPanConfig = async (issuerId: string, id: string, panConfig: TPanConfig) => {
        await createSETUPEndpoint(ENDPOINTS.SETUP(issuerId)).panConfig_Add(id, panConfig)
            .then(_ => {
                toast.success(<div><i className="fas fa-check mr-2"></i>{"Record successfully added."}</div>);
                getSetupDetails(issuerId, id);
            }
            ).catch(error => {
                toast.error(<div><i className="fas fa-times mr-2"></i>{error.response.data.description}</div>);
            });
    }

    // add currency limit
    const addCurrencyLimit = async (issuerId: string, setupId: string, currency: number) => {
        await createSETUPEndpoint(ENDPOINTS.SETUP(issuerId)).currencyLimit_Add(setupId, currency)
            .then(_ => {
                toast.success(<div><i className="fas fa-check mr-2"></i>{"Record successfully added."}</div>);
                getSetupDetails(issuerId, setupId);
            }
            ).catch(error => {
                toast.error(<div><i className="fas fa-times mr-2"></i>{error.response.data.description}</div>);
            });
    }

    const masterUpdateSetup = async(issuerId: string, setupId: string, settingNameToUpdate: string, setupToUpdate: any) => {
        console.log(setupToUpdate);
        await createSETUPEndpoint(ENDPOINTS.SETUP(issuerId)).patchSetupSetting(setupId, settingNameToUpdate, setupToUpdate)
        .then(_ => {
            toast.success(<div><i className="fas fa-check mr-2"></i>{settingNameToUpdate + " successfully updated."}</div>);
                getSetupDetails(issuerId, setupId);
        }).catch(error => {
            toast.error(<div><i className="fas fa-times mr-2"></i>{settingNameToUpdate + " was not successfully updated."}</div>);
            toast.error(<div><i className="fas fa-times mr-2"></i>{error.response.data.description}</div>);
        });
    }

    // update currency load limit
    const updateLoadLimit = async (issuerId: string, id: string, loadLimit: TLoadLimit, currency: number, customerType: string) => {
        await createSETUPEndpoint(ENDPOINTS.SETUP(issuerId)).loadLimit_Update(id, currency, customerType, loadLimit)
            .then(_ => {
                toast.success(<div><i className="fas fa-check mr-2"></i>{"Record successfully updated."}</div>);
                getSetupDetails(issuerId, id);
            }
            ).catch(error => {
                toast.error(<div><i className="fas fa-times mr-2"></i>{error.response.data.description}</div>);
            });
    }

    // update currency global limit
    const updateGlobalLimit = async (issuerId: string, id: string, globalLimit: TGlobalLimit, currency: number, customerType: string, periodicLimit: string) => {
        await createSETUPEndpoint(ENDPOINTS.SETUP(issuerId)).globalLimit_Update(id, currency, customerType, globalLimit, periodicLimit)
            .then(_ => {
                toast.success(<div><i className="fas fa-check mr-2"></i>{"Record successfully updated."}</div>);
                getSetupDetails(issuerId, id);
            }
            ).catch(error => {
                toast.error(<div><i className="fas fa-times mr-2"></i>{error.response.data.description}</div>);
            });
    }

    // update mcc limit
    const updateMccLimit = async (issuerId: string, id: string, mccLimit: TMccLimit, currency: number, customerType: string) => {
        await createSETUPEndpoint(ENDPOINTS.SETUP(issuerId)).mccLimit_Update(id, currency, customerType, mccLimit)
            .then(_ => {
                toast.success(<div><i className="fas fa-check mr-2"></i>{"Record successfully updated."}</div>);
                getSetupDetails(issuerId, id);
            }
            ).catch(error => {
                toast.error(<div><i className="fas fa-times mr-2"></i>{error.response.data.description}</div>);
            });
    }

    // add mcc block
    const addMccBlock = async (issuerId: string, id: string, mccList: Array<Number>) => {
        await createSETUPEndpoint(ENDPOINTS.SETUP(issuerId)).mccBlock_Add(id, mccList)
            .then(_ => {

                toast.success(<div><i className="fas fa-check mr-2"></i>{"Record successfully updated."}</div>);
                getSetupDetails(issuerId, id);
            }
            ).catch(error => {

                if (error.response.data.description)
                    toast.error(<div><i className="fas fa-times mr-2"></i>{error.response.data.description}</div>);

                else toast.error(<div><i className="fas fa-times mr-2"></i>{"Something wrong happened!"}</div>);
            });
    }

    // add mcc count
    const addMccCount = async (issuerId: string, id: string, mccCount: TMccCount, customerType: string) => {
        await createSETUPEndpoint(ENDPOINTS.SETUP(issuerId)).mccCount_Add(id, customerType, mccCount)
            .then(_ => {
                toast.success(<div><i className="fas fa-check mr-2"></i>{"Record successfully updated."}</div>);
                getSetupDetails(issuerId, id);
            }
            ).catch(error => {
                toast.error(<div><i className="fas fa-times mr-2"></i>{error.response.data.description}</div>);
            });
    }

    // delete mcc count
    const removeMccCount = async (issuerId: string, id: string, mcc: Array<Number>, customerType: string) => {
        await createSETUPEndpoint(ENDPOINTS.SETUP(issuerId)).mccCount_Delete(id, customerType, mcc)
            .then(_ => {
                toast.success(<div><i className="fas fa-check mr-2"></i>{"Record successfully updated."}</div>);
                getSetupDetails(issuerId, id);
            }
            ).catch(error => {
                toast.error(<div><i className="fas fa-times mr-2"></i>{error.response.data.description}</div>);
            });
    }

    // update currency regional limit
    const updateRegionalLimit = async (issuerId: string, id: string, regionalLimit: TRegionalLimit, currency: number, customerType: string, periodicLimit: string, limitType: string) => {
        await createSETUPEndpoint(ENDPOINTS.SETUP(issuerId)).regionalLimit_Update(id, currency, customerType, regionalLimit, periodicLimit, limitType)
            .then(_ => {
                toast.success(<div><i className="fas fa-check mr-2"></i>{"Record successfully updated."}</div>);
                getSetupDetails(issuerId, id);
            }
            ).catch(error => {
                toast.error(<div><i className="fas fa-times mr-2"></i>{error.response.data.description}</div>);
            });
    }

    // update transaction counts
    const updateTransactionCounts = async (issuerId: string, id: string, transCount: TTransactionCounts, customerType: string, periodicLimit: string, limitType: string) => {
        await createSETUPEndpoint(ENDPOINTS.SETUP(issuerId)).transactionCounts_Update(id, customerType, transCount, periodicLimit, limitType)
            .then(_ => {
                toast.success(<div><i className="fas fa-check mr-2"></i>{"Record successfully updated."}</div>);
                getSetupDetails(issuerId, id);
            }
            ).catch(error => {
                toast.error(<div><i className="fas fa-times mr-2"></i>{error.response.data.description}</div>);
            });
    }

    // add currency fees
    const addCurrencyFees = async (issuerId: string, setupId: string, currency: number) => {
        await createSETUPEndpoint(ENDPOINTS.SETUP(issuerId)).currencyFees_Add(setupId, currency)
            .then(_ => {
                toast.success(<div><i className="fas fa-check mr-2"></i>{"Record successfully added."}</div>);
                getSetupDetails(issuerId, setupId);
            }
            ).catch(error => {
                toast.error(<div><i className="fas fa-times mr-2"></i>{error.response.data.description}</div>);
            });
    }

    // uodate currency fees
    const updateFees = async (issuerId: string, setupId: string, currency: number, fees: any, customerType: string, feesType: string, subType?: string) => {
        await createSETUPEndpoint(ENDPOINTS.SETUP(issuerId)).currencyFees_Update(setupId, currency, customerType, fees, feesType, subType)
            .then(_ => {
                toast.success(<div><i className="fas fa-check mr-2"></i>{"Record successfully added."}</div>);
                getSetupDetails(issuerId, setupId);
            }
            ).catch(error => {
                toast.error(<div><i className="fas fa-times mr-2"></i>{error.response.data.description}</div>);
            });
    }

    // assign new setup 
    const assignNewSetupToIssuer = async (issuerId: string, setup: TSetup) => {
        await createSETUPEndpoint(ENDPOINTS.SETUP(issuerId)).assignNewSetupToIssuer(setup)
            .then(response => {
                toast.success(<div><i className="fas fa-check mr-2"></i>{"Setup successfully added."}</div>);
                navigate(`/setup/${response.data.issuerId}/${response.data.setupId}`);
            }).catch(error => {
                if (!error.response) {
                    dispatch({
                        type: SETUP_ERROR,
                        payload: { error: "Make sure that the API is running" }
                    });
                }
            });
    }

    const addContactlessLimit = async (issuerId: string, setupId: string, contactlessLimit: TContactlessLimit) => {
        await createSETUPEndpoint(ENDPOINTS.SETUP(issuerId)).patchContactLessLimit(setupId, contactlessLimit)
            .then(_ => {
                toast.success(<div><i className="fas fa-check mr-2"></i>{"Limit successfully saved."}</div>);
                getSetupDetails(issuerId, setupId);
            }).catch(error => {
                if (!error.response) {
                    dispatch({
                        type: SETUP_ERROR,
                        payload: { error: "Make sure that the API is running" }
                    });
                }
            });
    }

    // add/update card vendor
    const setCardVendor = async (issuerId: string, id: string, cardVendor: TCardVendor) => {
        await createSETUPEndpoint(ENDPOINTS.SETUP(issuerId)).cardVendor_Patch(id, cardVendor)
            .then(_ => {
                toast.success(<div><i className="fas fa-check mr-2"></i>{"Record successfully saved."}</div>);
                getSetupDetails(issuerId, id);
                navigate(`/setup/${issuerId}/${id}`, { state: { hash: "#tab-cardVendors" } });
            }
            ).catch(error => {
                toast.error(<div><i className="fas fa-times mr-2"></i>{error.response.data.description}</div>);
            });
    }

    // delete card vendor
    const deleteCardVendor = async (issuerId: string, id: string, cardVendor: TCardVendorKey) => {
        await createSETUPEndpoint(ENDPOINTS.SETUP(issuerId)).cardVendor_Delete(id, cardVendor)
            .then(_ => {
                toast.success(<div><i className="fas fa-check mr-2"></i>{"Record successfully deleted."}</div>);
                getSetupDetails(issuerId, id);
            }
            ).catch(error => {
                toast.error(<div><i className="fas fa-times mr-2"></i>{error.response.data.description}</div>);
            });
    }


    return (
        <SetupContext.Provider value={{
            setups: state.setups,
            setupDetails: state.setupDetails,
            panConfigs: state.panConfigs,
            blockedMCCs: state.blockedMCCs,
            currencyLimits: state.currencyLimits,
            transactionCounts: state.transactionCounts,
            currencyFees: state.currencyFees,
            contactlessLimits: state.contactlessLimits,
            cardVendorDetail: state.cardVendorDetail,
            totalRows: state.totalRows,
            loading: state.loading,
            pageSize: state.pageSize,
            pageNo: state.pageNo,
            error: state.error,
            getSetups,
            getSetupDetails,
            addPanConfig,
            assignNewSetupToIssuer,
            addContactlessLimit,
            masterUpdateSetup,
            addCurrencyLimit,
            updateLoadLimit,
            updateGlobalLimit,
            updateMccLimit,
            updateRegionalLimit,
            updateTransactionCounts,
            addMccCount,
            removeMccCount,
            addCurrencyFees,
            updateFees,
            addMccBlock,
            setCardVendor,
            deleteCardVendor
        }}>
            {children}
        </SetupContext.Provider>
    )
}

export default SetupState
