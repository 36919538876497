import { SyntheticEvent, useContext, useState } from "react";
import SetupContext from "../../../../context/setup/SetupContext";
import {
  checkInputPattern,
  currencyFormat,
} from "../../../../models/Helper";
import TagsInput from '../../../../components/tagsInput/TagsInput';
import { Tooltip } from "react-tooltip";
import { HelpDescriptions } from "../../../../models/enum/helpDescriptions";
import SecurityClassificationError from "../../../errors/SecurityClassification";

interface IMccLimit {
  authorizationDropOffPeriod: number | null,
  maxTransactionAmount: number | null,
  daily: number | null,
  monthly: number | null,
  yearly: number | null,
}

const UpdateMccLimit = (props: any) => {
  const { data, setOpenPopup, issuerId, setupId, currencyCode, customerType,messageReference,securityClassifications,proccedAndLoad,canPageBeRendered  } =
    props;
  const [mcc, set_mcc] = useState(data["mcc"]);
  const { updateMccLimit } = useContext(SetupContext);

  const setDefaultVal = (val: number | null, divisor: number) => {    
    return val === null ? null : val === 0 ? 0 : Number(currencyFormat(val/divisor));        
  };

  const setPayload = (val: number | null, multiplier: number) => {    
    return val === null ? null : val * multiplier;        
  };

  const setDisplay = (val: number | null) => {
    return val === null ? '' : val;
  };

  const existingMccList = data?.mcc?.flatMap((mcc: any) => mcc) 

  const [mccLimit, setmccLimit] = useState<IMccLimit>({
    authorizationDropOffPeriod: setDefaultVal(data["authorizationDropOffPeriod"],1),
    maxTransactionAmount: setDefaultVal(data["maxTransactionAmount"],100),
    daily: setDefaultVal(data["daily"],100),
    monthly: setDefaultVal(data["monthly"],100),
    yearly: setDefaultVal(data["yearly"],100),
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value } = e.target                
    setmccLimit({ ...mccLimit, [name]: value === "" ? null : Number(value) })
  };

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const payload = {
      authorizationDropOffPeriod: setPayload(mccLimit["authorizationDropOffPeriod"],1),
      maxTransactionAmount: setPayload(mccLimit["maxTransactionAmount"],100),
      daily: setPayload(mccLimit["daily"],100),
      monthly: setPayload(mccLimit["monthly"],100),
      yearly: setPayload(mccLimit["yearly"],100),
      mcc,
    };
    updateMccLimit(issuerId, setupId, payload, currencyCode, customerType);
    setOpenPopup(false);
  };

  const isValid =     
    mcc && mcc.length > -1 &&    
      (mccLimit.authorizationDropOffPeriod ||
        mccLimit.maxTransactionAmount ||
        mccLimit.daily ||
        mccLimit.monthly ||
        mccLimit.yearly) &&
       !existingMccList.includes(mcc)

    const updateAccess = securityClassifications?.includes("Update");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const createAccess = securityClassifications?.includes("Create");

  
    if(!proccedAndLoad || !updateAccess)
    {
        return (<SecurityClassificationError title="" pageName="" extraReference={`${messageReference}`} withWrapper={false}/>)
    }

  return (
    <form className="row" onSubmit={onSubmit}>
      <div className="col-sm-12">
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">MCC</label>
          <div className="col-sm-8">
            <TagsInput
              placeHolder={'Enter MCC'}
              inputTags={mcc}
              onChange={(val) => {
                const mccData = val
                  .filter((item: any) => Number(item))
                  .map((item: any) => Number(item));
                set_mcc(mccData);
              }} 
              onValidate={(value) => {
                try {
                    const val = Number(value);
                    return !Number.isNaN(val);
                } catch (error) {
                    return false
                }
            }}                                                                       
          />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">Drop Off Period
          &nbsp;<i className="fas fa-info-circle" id="authorizationDropOffPeriodLabel" data-tooltip-variant="info" aria-hidden="true"></i>
            </label>
            <Tooltip anchorId="authorizationDropOffPeriodLabel" className='tooltip'>
                <div>                    
                    {HelpDescriptions.AUTH_DROP_OFF}
                </div>
            </Tooltip>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              placeholder="Drop Off Period"
              name="authorizationDropOffPeriod"
              onKeyPress={(event) => {
                if (checkInputPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={setDisplay(mccLimit["authorizationDropOffPeriod"])}
              onChange={onChange}              
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">Max Transaction Amount</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              placeholder="Forced Credit"
              name="maxTransactionAmount"
              onKeyPress={(event) => {
                if (checkInputPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={setDisplay(mccLimit["maxTransactionAmount"])}
              onChange={onChange}              
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">Daily</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              placeholder="Daily"
              name="daily"
              onKeyPress={(event) => {
                if (checkInputPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={setDisplay(mccLimit["daily"])}
              onChange={onChange}              
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">Monthly</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              placeholder="Monthly"
              name="monthly"
              onKeyPress={(event) => {
                if (checkInputPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={setDisplay(mccLimit["monthly"])}
              onChange={onChange}              
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">Yearly</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              placeholder="Yearly"
              name="yearly"
              onKeyPress={(event) => {
                if (checkInputPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={setDisplay(mccLimit["yearly"])}
              onChange={onChange}              
            />
          </div>
        </div>
        <hr />
        <div className="form-group row">
          <div className="float-right">
            <button
              type="button"
              className="btn-secondary btn-sm m-1"
              onClick={() => setOpenPopup(false)}
            >
              Cancel
            </button>
            <button disabled={!isValid} type="submit" className="btn-success btn-sm m-1">
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default UpdateMccLimit;
