import { useReducer } from "react";
import { createAPIEndpoint, createISSUEREndpoint, ENDPOINTS } from "..";
import { IssuerForCreate, IssuerInitState } from "../../models/IIssuer";
import IssuerContext from "./IssuerContext";
import IssuerReducer from "./IssuerReducer";
import { CLEAR_ERRORS, GET_ISSUER, GET_ISSUERS, GET_ISSUERS_FOR_SELECT, ISSUER_ERROR, SET_CURRENT_ISSUER } from "../types";
import { toast } from "react-toastify";
import { TIssuerWebHookModel } from "../../models/TIssuerWebHookModel";

const IssuerState: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const initialState: IssuerInitState = {
        issuers: [],
        issuersForSelect: [],
        issuerDetails: null,
        defaultIssuerId: null,
        totalRows: 0,
        loading: true,
        pageSize: 10,
        pageNo: 1,
        error: null
    }
    const [state, dispatch] = useReducer(IssuerReducer, initialState);

    // get issuers
    const getIssuers = async (search: '', pageNo: number, pageSize: number) => {
        await createAPIEndpoint(ENDPOINTS.ISSUER).fetchPaginated(search, pageNo, pageSize)
            .then(res => {                
                dispatch({ type: GET_ISSUERS, payload: res.data })
            });
    }

    // get issuers for Select Options
    const getIssuersForSelect = async () => {
        await createAPIEndpoint(ENDPOINTS.ISSUER).get("GetIssuersForSelect")
            .then(res => {

                let issuerTempList = res.data.data.map((item: any) => ({
                    id: item.issuerId,
                    name: item.issuerName
                }));

                dispatch({ type: GET_ISSUERS_FOR_SELECT, payload: issuerTempList })
            });
    }

    // get issuer by id
    const getIssuerById = async (id: string) => {
        await createAPIEndpoint(ENDPOINTS.ISSUER).fetchById(id)
            .then(res => {
                dispatch({ type: GET_ISSUER, payload: res.data.data });
            }).catch(err => {             
             dispatch({ type: ISSUER_ERROR, payload: err });
            });
    }

    // add webHook
    const addWebHook = async (id: string, hookName: string, model: TIssuerWebHookModel) => {
        if (hookName === "BalanceEnquiry" || hookName === "Authorization" || hookName === "Settlement") {
            await createISSUEREndpoint(ENDPOINTS.ISSUER).addHookCallout(id, hookName, model)
            .then(res => {
                if (res === undefined) {
                    toast.error(<div><i className="fas fa-times mr-2"></i>{`Header with Name ${model.header.name} already exists.`}</div>);
                }
                else {
                    toast.success(<div><i className="fas fa-exclamation mr-2"></i>{"Record successfully added."}</div>);
                    getIssuerById(id);
                }

            }).catch(error => {
                toast.error(<div><i className="fas fa-times mr-2"></i>{error.response.data.errors.Description}</div>);
            });
        }
        else {
            await createISSUEREndpoint(ENDPOINTS.ISSUER).addHook(id, hookName, model)
            .then(res => {
                if (res === undefined) {
                    toast.error(<div><i className="fas fa-times mr-2"></i>{`Header with Name ${model.header.name} already exists.`}</div>);
                }
                else {
                    toast.success(<div><i className="fas fa-exclamation mr-2"></i>{"Record successfully added."}</div>);
                    getIssuerById(id);
                }

            }).catch(error => {
                toast.error(<div><i className="fas fa-times mr-2"></i>{error.response.data.errors.Description}</div>);
            });
        }        
    }

    // delete webHook
    const deleteWebHook = async (id: string, hookName: string, model: TIssuerWebHookModel) => {
        if (hookName === "BalanceEnquiry" || hookName === "Authorization" || hookName === "Settlement") {
            await createISSUEREndpoint(ENDPOINTS.ISSUER).deleteHookCallout(id, hookName, model)
            .then(_ => {
                toast.success(<div><i className="fas fa-check mr-2"></i>{"Record successfully deleted."}</div>);
                getIssuerById(id);
            }
            ).catch(error => {
                toast.error(<div><i className="fas fa-times mr-2"></i>{error.response.data.errors.Description}</div>);
            });
        }
        else {
            await createISSUEREndpoint(ENDPOINTS.ISSUER).deleteHook(id, hookName, model)
            .then(_ => {
                toast.success(<div><i className="fas fa-check mr-2"></i>{"Record successfully deleted."}</div>);
                getIssuerById(id);
            }
            ).catch(error => {
                toast.error(<div><i className="fas fa-times mr-2"></i>{error.response.data.errors.Description}</div>);
            });
        }        
    }

    // update hook uri updateHookUri: (id: string, hookName: string, hookUri: string) 
    const updateHookUri = async (id: string, hookName: string, hookUri: string) => {
        if (hookName === "BalanceEnquiry" || hookName === "Authorization" || hookName === "Settlement") {
            await createISSUEREndpoint(ENDPOINTS.ISSUER).updateHookCalloutUri(id, hookName, hookUri)
            .then(_ => {                
                toast.success(<div><i className="fas fa-check mr-2"></i>{"Hook Uri successfully updated."}</div>);
                getIssuerById(id);
            }
            ).catch(error => {
                toast.error(<div><i className="fas fa-times mr-2"></i>{error.response.data.errors.Description}</div>);
            });
        }
        else {
            try {
                await createISSUEREndpoint(ENDPOINTS.ISSUER).updateHookUri(id, hookName, hookUri);                
                toast.success(<div><i className="fas fa-check mr-2"></i>{"Hook Uri successfully updated."}</div>);                
                getIssuerById(id);
            } catch(error: any) {
                toast.error(<div><i className="fas fa-times mr-2"></i>{error.response.data.errors.Description}</div>);
            }
        }

    }

    const addIssuer = async (issuer : IssuerForCreate) =>{
        
        await createISSUEREndpoint(ENDPOINTS.ISSUER).addIssuer(issuer)
        .then(
            res => {
                if (res === undefined) {
                    toast.error(<div><i className="fas fa-times mr-2"></i>{`Issuer with ID ${issuer.issuerId} already exists.`}</div>);
                }
                else {                    
                    toast.success(<div><i className="fas fa-exclamation mr-2"></i>{"Record successfully added."}</div>);
                }
            }
        ).catch(error => {  
            if(!error.response)
            {
                dispatch({
                    type: ISSUER_ERROR,
                    payload: {error : "Error Occoured"}
                })
            }
            else
            {
                toast.error(<div><i className="fas fa-times mr-2"></i>{`The Following Error has occured ${error} already exists.`}</div>);
            }

        })
    }

    // clear errors
    const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

    // set current Issuer
    const setCurrentIssuer = (issuer: string) => {

        dispatch({ type: SET_CURRENT_ISSUER, payload: issuer})
    };

    return (
        <IssuerContext.Provider value={{
            issuers: state.issuers,
            issuersForSelect: state.issuersForSelect,
            issuerDetails: state.issuerDetails,
            defaultIssuerId: state.defaultIssuerId,
            totalRows: state.totalRows,
            loading: state.loading,
            pageSize: state.pageSize,
            pageNo: state.pageNo,
            error: state.error,
            getIssuers,
            getIssuerById,
            getIssuersForSelect,
            addWebHook,
            deleteWebHook,
            updateHookUri,
            clearErrors,
            addIssuer,
            setCurrentIssuer
        }}>
            {children}
        </IssuerContext.Provider>
    )
}

export default IssuerState;