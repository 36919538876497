import React, { useReducer } from 'react'
import { toast } from 'react-toastify';
import { createTRANSACTIONSEndpoint, ENDPOINTS } from '..';
import {TransactionMgtInitState}  from "../../models/ITransactionMgt";
import { GET_TRANSACTIONS, HANDLE_TRANSACTIONS} from "../types";
import TransactionMgtContext from './TransactionMgtContext';
import TransactionMgtReducer from './TransactionMgtReducer';
import { parseISO, format } from 'date-fns';

const todayDate = format(new Date(), 'yyyy-MM-dd');

const TransactionMgtState: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const initialState: TransactionMgtInitState = {
        transactions: [],
        transactionDetails: null,
        totalRows: 0,
        loading: true,
        pageSize: 10,
        pageNo: 1,
        from: todayDate,
        to: todayDate,
        error: null
    }

    const getTransactions = async (from: string, to: string, pageNo: number, pageSize: number) => {
        await createTRANSACTIONSEndpoint(ENDPOINTS.TRANSACTIONS).fetchPaginated(from, to, pageSize, pageNo)
            .then(res => {
                dispatch({ type: GET_TRANSACTIONS, payload: res.data })
            }).catch(err => {
                console.log(err)
            });
    } 

    const handleTransactions = async (transactionId: string, transactionAuthorisation: string, formatStartDate : string, formatEndDate : string, pageNo: number, pageSize: number) => {
        await createTRANSACTIONSEndpoint(ENDPOINTS.TRANSACTIONS).handleTransactions(transactionId, transactionAuthorisation)
            .then(res => {
                if(transactionAuthorisation == "approve"){
                    toast.success(<div><i className="fas fa-check mr-2"></i>{"Transaction successfully approved."}</div>);
                }else if(transactionAuthorisation == "decline"){
                    toast.success(<div><i className="fas fa-check mr-2"></i>{"Transaction successfully declined."}</div>);
                }  
                getTransactions(formatStartDate, formatEndDate, pageNo, pageSize);
            }).catch(err => {
                console.log(err)
                toast.error(<div><i className="fas fa-times mr-2"></i>{"Transaction Authorisation failed."}</div>);
            });
    } 

    const [state, dispatch] = useReducer(TransactionMgtReducer, initialState);

    return (
        <TransactionMgtContext.Provider value={{
            transactions: state.transactions,
            transactionDetails: state.transactionDetails,
            totalRows: state.totalRows,
            loading: state.loading,
            pageSize: state.pageSize,
            pageNo: state.pageNo,
            from: state.from,
            to: state.to,
            error: state.error,
            getTransactions,
            handleTransactions
            
        }}>
             {children}
        </TransactionMgtContext.Provider>
    )
}

export default TransactionMgtState