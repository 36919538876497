import React, { useState,useEffect,useContext } from 'react';
import { Link } from "react-router-dom";
import Popup from '../../../../components/dialog/Popup';
import { HelpDescriptions } from '../../../../models/enum/helpDescriptions';
import { currencyFormat } from '../../../../models/Helper';
import UpdateLoadLimit from './UpdateLoadLimit';
import UnauthorizedPage from "../../../errors/UnauthorizedPage";
import SecurityClassificationError from "../../../errors/SecurityClassification";
import Spinner from '../../../../components/spinner/Spinner';


const LoadLimitTable = (props: any) => {
    const { data, title, subtitle, issuerId, setupId, currencyCode, customerType,securityClassifications,canPageBeRendered,proccedAndLoad } = props;
    const [openPopup, setOpenPopup] = useState(false);

    const setDisplay = (val: number | null, divisor: number) => {      
      return val === null ? '-' : val === 0 ? 0 : Number(currencyFormat(val/divisor));  
    };

    const readAccess = securityClassifications?.includes("Read");
    const updateAccess = securityClassifications?.includes("Update");

    if(!proccedAndLoad || !readAccess)
    {
        return <UnauthorizedPage withWrapper={false} />;
    }
    
    return (
      <table className="table table-hover table-borderless table-sm">
        <thead className="alert alert-success">
          <tr>
            <th></th>
            <th className="text-right">Max. Bal.</th>
            <th className="text-right">ForcedCredit</th>
            <th className="text-right">Daily</th>
            <th className="text-right">Monthly</th>
            <th className="text-right">Yearly</th>
          </tr>
        </thead>
        <tbody>
          {data !== null && Object.keys(data).length > 0 ? (
            <tr>
              <td>
                {
                 updateAccess && <Link
                  to="#"
                  onClick={() => {
                    setOpenPopup(true);
                  }}
                >
                  <i className="fas fa-pen-square" />
                </Link>
                }
              </td>
              <td className="text-right" style={{ width: "20%" }}>
                {setDisplay(data.maximumBalance, 100)}
              </td>
              <td className="text-right" style={{ width: "20%" }}>
                {setDisplay(data.forcedCredit, 100)}
              </td>
              <td className="text-right" style={{ width: "20%" }}>
                {setDisplay(data.daily, 100)}
              </td>
              <td className="text-right" style={{ width: "20%" }}>
                {setDisplay(data.monthly, 100)}
              </td>
              <td className="text-right" style={{ width: "20%" }}>
                {setDisplay(data.yearly, 100)}
              </td>
            </tr>
          ) : (
            <tr>
              <td className="text-center text-danger" colSpan={5}>
                No data available in table
              </td>
            </tr>
          )}
        </tbody>
        {
          updateAccess && 
            <Popup title={`${title} || ${subtitle}`} openPopup={openPopup} setOpenPopup={setOpenPopup}>
              <div className="description">{HelpDescriptions.CURRENCY_VALUE_LIMTS}</div><br/>  
              <UpdateLoadLimit
              data={data}
              issuerId={issuerId}
              setupId={setupId} 
              currencyCode={currencyCode}
              customerType={customerType}
              messageReference={`${title} || ${subtitle}`}
              securityClassifications={securityClassifications} 
              canPageBeRendered={canPageBeRendered} 
              proccedAndLoad={proccedAndLoad}
              setOpenPopup={setOpenPopup}/>
          </Popup>
        }
      </table>
    );
}

export default LoadLimitTable
