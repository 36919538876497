
import React, { useState, SyntheticEvent, useContext } from 'react';
import { Link } from "react-router-dom";
import Popup from '../../../../components/dialog/Popup';
import SetupContext from "../../../../context/setup/SetupContext";
import { HelpDescriptions } from '../../../../models/enum/helpDescriptions';
import { currencyFormat, isWholeNumberPattern } from "../../../../models/Helper";

const PeriodicCounts = (props: any) => {
    const { title, subtitle, data, issuerId, setupId, customerType, type, securityClassifications,canPageBeRendered,proccedAndLoad } = props;
    const { updateTransactionCounts } = useContext(SetupContext);
    const [openPopup, setOpenPopup] = useState(false);

    const setDefaultVal = (val: number | null) => {    
      return val === null ? null : val === 0 ? 0 : Number(currencyFormat(val));        
    };

    const setPayload = (val: number | null) => {    
      return val === null ? null : val;        
    };

    const setDisplay = (val: number | null) => {
      return val === null ? '' : val;
    };     

  const [transCount, setTransCount] = useState({
    daily: setDefaultVal(data['daily']),
    monthly: setDefaultVal(data['monthly']),
    yearly: setDefaultVal(data['yearly']),
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value } = e.target                    
    setTransCount({ ...transCount, [name]: value === "" ? null : Number(value) })
  }

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const payload = {
      daily: setPayload(transCount['daily']),
      monthly: setPayload(transCount['monthly']),
      yearly: setPayload(transCount['yearly']),
    };
    const limitType = subtitle === 'E-Commerce' ? 'ecommerce' : subtitle.toLowerCase();
    const countType = !type ? 'load' : type.toLowerCase();
    updateTransactionCounts(issuerId, setupId, payload, customerType, countType, limitType);
    setOpenPopup(false);
  };

  const updateAccess = securityClassifications?.includes("Update");
  const createAccess = securityClassifications?.includes("Create");

  return (
      <tr style={{ borderBottom: "1px solid #dee2e6" }}>
        <td className="px-5">
          {
          updateAccess && <Link
            to="#"
            onClick={() => {
              setOpenPopup(true);
            }}
          >
            <i className="fas fa-pen-square" />
          </Link>
          }
          &emsp;
          {!type ? "Load" : type} 
        </td>
        <td className="text-right" style={{ width: "20%" }}> {data?.daily === null ? "-" : setDefaultVal(data.daily)} </td>
        <td className="text-right" style={{ width: "20%" }}> {data?.monthly === null ? "-" : setDefaultVal(data.monthly)} </td>
        <td className="text-right" style={{ width: "20%" }}> {data?.yearly === null ? "-" : setDefaultVal(data.yearly)} </td>
        <Popup
          title={`${title} || ${subtitle}`}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <div className="description">{HelpDescriptions.TRANSACTION_COUNT_LIMTS}</div><br/>  
          <form className="row" onSubmit={onSubmit}>
            <div className="col-sm-12">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Daily</label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Daily"
                    name="daily"
                    onKeyPress={(event) => {
                      if (!isWholeNumberPattern(event)) {
                        event.preventDefault();
                      }
                    }}
                    autoComplete="off"
                    // maxLength={6}
                    defaultValue={setDisplay(transCount["daily"])}
                    onChange={onChange}                    
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Monthly</label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Monthly"
                    name="monthly"
                    onKeyPress={(event) => {
                      if (!isWholeNumberPattern(event)) {
                        event.preventDefault();
                      }
                    }}
                    autoComplete="off"
                    // maxLength={6}
                    defaultValue={setDisplay(transCount["monthly"])}
                    onChange={onChange}                    
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Yearly</label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Yearly"
                    name="yearly"
                    onKeyPress={(event) => {
                      if (!isWholeNumberPattern(event)) {
                        event.preventDefault();
                      }
                    }}
                    autoComplete="off"
                    // maxLength={6}
                    defaultValue={setDisplay(transCount["yearly"])}
                    onChange={onChange}                    
                  />
                </div>
              </div>
              <hr />
              <div className="form-group row">
                <div className="float-right">
                  <button
                    type="button"
                    className="btn-secondary btn-sm m-1"
                    onClick={() => setOpenPopup(false)}
                  >
                    Cancel
                  </button>
                  {
                    updateAccess && <button type="submit" className="btn-success btn-sm m-1">
                    Save
                  </button>
                  }
                </div>
              </div>
            </div>
          </form>
        </Popup>
      </tr>
    );
}

export default PeriodicCounts

