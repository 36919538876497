import React, { useContext, useEffect, useState } from 'react';
import Wrapper from '../../Wrapper'
import RoleBasedAccessControlContext from '../../../context/rolebasedaccesscontrol/RoleBasedAccessControlContext';
import UnauthorizedPage from "../../errors/UnauthorizedPage";
import SecurityClassificationError from "../../errors/SecurityClassification";
import { useLocation } from 'react-router-dom';
import Spinner from '../../../components/spinner/Spinner';


const CardMgt = () => {


    const {securityClassifications,getSecurityClassification,retrievePagePermission}  = useContext(RoleBasedAccessControlContext); 
    const [proccedAndLoad, setUserAccess] = useState<boolean>(false);
    const [canPageBeRendered, canInvokePageContent] = useState(false); 
    const location = useLocation();

    useEffect(() => {
        let pageName = location?.pathname?.split('/').pop()?.toLowerCase();
        retrievePagePermission(pageName).then((isGranted: boolean) => {
            canInvokePageContent(true);
            setUserAccess(isGranted);
            // Do something with menuItems
        }).catch((error: any) => {
            canInvokePageContent(false);
            setUserAccess(false);
          });
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        getSecurityClassification();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const readAccess = securityClassifications?.includes("Read");

    if(!canPageBeRendered)
    {
        return (<Wrapper title="Card Management"> < Spinner /> </Wrapper> )
    }
    
    if(!proccedAndLoad)
    {
        return <UnauthorizedPage />;
    }

    if(!readAccess){
        return (<SecurityClassificationError title="Read Classification Denied" pageName="Card Management" extraReference="Card Management" withWrapper={true}/>)
    }

    return (
        <Wrapper title="Card Management">
            <p> We are actively working on developing this page. We appreciate your patience and look forward to unveiling it soon.</p>
        </Wrapper>   
    )
}

export default CardMgt
