export const SidebarAdminMenus = [
    {
        name: 'Home',
        path: '/',
        icon: 'fas fa-home'
    },
    {
        name: 'Set-up',
        path: '/setup',
        icon: 'fas fa-cog'
    },
    // {
    //     name: 'Card Management',
    //     path: '/card-management',
    //     icon: 'fas fa-credit-card'
    // },
    // {
    //     name: 'Drop-Off Authorization',
    //     path: '/drop-off',
    //     icon: 'fas fa-arrow-alt-circle-down'
    // },
    {
        name: 'Foreign Exchange',
        path: '/forext-mgt',
        icon: 'fab fa-stack-exchange'
    },
    {
        name: 'Accounts Management',
        path: '/accounts',
        icon: 'fas fa-user-circle'
    },
    {
        name: 'Transactions',
        path: '/transaction-mgt',
        icon: 'far fa-list-alt'
    }
]

export const SidebarClientMenus = [
    {
        name: 'Accounts',
        path: '/accounts',
        icon: 'fas fa-user-circle'
    },
    {
        name: 'Card Holders',
        path: '/card-holders',
        icon: 'fas fa-id-card'
    },
    {
        name: 'Sub Setup',
        path: '/sub-setup',
        icon: 'fas fa-cogs'
    }
]