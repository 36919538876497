import React from 'react';
import Wrapper from '../Wrapper'

interface SecurityClassification {
    title: string;
    withWrapper:boolean;
    pageName: string;
    extraReference:string;
  }

const SecurityClassificationError: React.FC<SecurityClassification> = ({ title, pageName,extraReference,withWrapper}) => { 
    
    if(withWrapper as boolean == false){
            return (<div className="card-body">
            <p>We're sorry, but you do not have the necessary permissions to access the {extraReference}. </p>
            <p>This feature is restricted to authorized personnel only.</p> 
            <p>If you believe this is a mistake, please contact the system administrator or the support team for further assistance.</p>
            <p>Thank you for your understanding.</p>
        </div>)
    }

    return (
        <Wrapper title={`${title}`} subTitle={`${pageName}`}>
            <div className="card-body">
                <p>We're sorry, but you do not have the necessary permissions to access the {extraReference}. </p>
                <p>This feature is restricted to authorized personnel only.</p> 
                <p>If you believe this is a mistake, please contact the system administrator or the support team for further assistance.</p>
                <p>Thank you for your understanding <a href="/">return to Home Page</a>.</p>
            </div>
        </Wrapper>
    )
    
}

export default SecurityClassificationError;