export const DashboardCards = [
    {
        icons: ['icon', 'fas', 'fa-cog', 'text-success','icon-hover'],
        name:"Set up",
        accessMapper: "Set-up",
        value:"Management",
        action: "More Info",
        url: "/setup"
    },
    {
        icons: ['icon', 'fab', 'fa-stack-exchange', 'text-info','icon-hover'],
        name:"Foreign Exchange",
        accessMapper: "Foreign Exchange",
        value:"Management",
        action: "More Info",
        url:"/forext-mgt"
    },
    {
        icons: ['icon', 'fas', 'fa-user-circle', 'text-danger','icon-hover'],
        name:"Accounts",
        accessMapper: "Accounts Management",
        value:"Management",
        action: "More Info",
        url: "/accounts"
    },
    {
        icons: ['icon', 'far', 'fa-list-alt', 'text-primary','icon-hover'],
        name:"Transactions",
        accessMapper: "Transactions",
        value:"Transactions",
        action: "More Info",
        url: "/transaction-mgt"
    },
]