import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import ConfirmDialog from '../../../components/dialog/ConfirmDialog';
import Spinner from '../../../components/spinner/Spinner';
import Switch from '../../../components/switch';
import IssuerContext from '../../../context/issuer/IssuerContext';
import SetupContext from '../../../context/setup/SetupContext';
import useAutofocus from '../../../hooks/useAutofocus';
import { ConfirmDialogInitValues } from '../../../models/ConfirmDialogInitValues';
import { BalanceMode } from '../../../models/enum/BalanceMode';
import { GetCountries } from '../../../models/enum/countries';
import { NegativeFeeMode } from '../../../models/enum/NegativeFeeMode';
import { initSetup } from '../../../models/Setup';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import UnauthorizedPage from "../../errors/UnauthorizedPage";
import SecurityClassificationError from "../../errors/SecurityClassification";

const DetailedInformation = (props: any) => {
    const { issuerId, setupId,securityClassifications,canPageBeRendered,proccedAndLoad } = props;
    const [values, setValues] = useState(initSetup);
    const [dirtyFields, setDirtyFields] = useState({});
    const [confirmDialog, setConfirmDialog] = useState(ConfirmDialogInitValues);
    const { issuersForSelect, setCurrentIssuer, getIssuersForSelect } = useContext(IssuerContext);
    const { setupDetails, masterUpdateSetup, getSetupDetails, loading } = useContext(SetupContext);
    const countries = GetCountries();

    const focusElement = useAutofocus();

    useEffect(() => {
        if (!issuersForSelect.length)
            getIssuersForSelect();
        const selectedIssuer = issuersForSelect.find((i:any) => i.id === issuerId) || null;             
        setCurrentIssuer(selectedIssuer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issuerId, issuersForSelect])

    useEffect(() => {
        if (setupDetails !== null)
            setValues({ ...setupDetails });
    }, [setupDetails, setValues])

    const onChange = (e: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLSelectElement>) => {
        const {name, value } = e.currentTarget
        setValues({ ...values, [name]: value });
        setDirtyFields({ ...dirtyFields, [name]: setupDetails[name] !== value });
    }

    const handleToggle = (id: string, value: boolean) => {                  
        setValues({ ...values, [id]: value });
        setDirtyFields({ ...dirtyFields, [id]: setupDetails[id] !== value }); 
      };

    const confirmSubmit = (e: SyntheticEvent) => {
        e.preventDefault();

        setConfirmDialog({
            isOpen: true,
            title: `Are you sure you want to update the setup setting?`,
            subTitle: 'You cannot undo this operation.',
            onConfirm: () => onSubmit(e),
            onCancel: () => onCancel(e)           
        });
    }

    const onCancel = (e: SyntheticEvent) => {
        getSetupDetails(issuerId, setupId);
        setDirtyFields({});
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        });
    }
    
    const updateAccess = securityClassifications?.includes("Update");

    if(!canPageBeRendered)
    {
        return (<div className="card-body" >< Spinner /> </div>)
    }
       
    if(!proccedAndLoad)
    {
        return <UnauthorizedPage withWrapper={false} />;
    }

    /*if(!updateAccess){
        return (<SecurityClassificationError title="Update Classification Denied" pageName="Detailed Information" extraReference="Detailed Information" withWrapper={false} />)
    }*/
 
    const onSubmit = (e: SyntheticEvent) => {
        

        for (const [fieldName, isDirty] of Object.entries(dirtyFields)) {
            if (isDirty) {
                switch (fieldName) {
                    
                    case "negativeFeeMode":
                        masterUpdateSetup(issuerId, setupId, "NegativeFee", {
                            "NegativeFeeMode": values[fieldName]
                        });
                        break;

                    case "balanceMode":
                        masterUpdateSetup(issuerId, setupId, fieldName, {
                            "balanceMode": values[fieldName]
                        });
                    break;

                    case "isPosBlocked":
                    case "isMotoBlocked":
                    case "isContactlessBlocked":
                    case "isEcommerceBlocked":
                    case "isPinChangedBlocked":
                    case "isBalanceEnquiryBlocked":
                    case "isAtmBlocked":
                        masterUpdateSetup(issuerId, setupId, fieldName, {
                            "IsBlocked": values[fieldName]
                        });
                        break;

                    case "isAcquirerFeesApplied":
                        masterUpdateSetup(issuerId, setupId, "isAcquirerFeesApplied", {
                            "IsAcquirerFeesApplied": values[fieldName]
                        });
                        break;

                    case "isMdesEnrolled":
                        masterUpdateSetup(issuerId, setupId, "IsMdesEnrolled", {
                            "IsEnrolled": values[fieldName]
                        });
                        break;
                                            
                    case "isCashbackAllowed":
                        masterUpdateSetup(issuerId, setupId, "isCashbackAllowed", {
                            "IsAllowed": values[fieldName]
                        });     
                        break;
                    case "isAuthorizationCalledout":
                    case "isSettlementCalledout":
                    case "isBalanceEnquiryCalledout":

                        masterUpdateSetup(issuerId, setupId, "Callout", {
                                                                            "IsAuthorizationCalledout" : values.isAuthorizationCalledout,
                                                                            "IsSettlementCalledout" : values.isSettlementCalledout,
                                                                            "IsBalanceEnquiryCalledout" : values.isBalanceEnquiryCalledout
                                                                        });
                        break; 
                    case "setupName":
                    case "setupCountry":
                        masterUpdateSetup(issuerId, setupId, "SetupDetails", {
                                                                                "SetupName": values.setupName,
                                                                                "SetupCountry" : values.setupCountry
                                                                            });
                        break;                
                }
            }
        }

        setDirtyFields({});
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        });
    }

    function hasDirtyFields() {
        return Object.values(dirtyFields).some(x => x === true);
    }


    return (
        !loading ?
        <div className="card-body" >
            <form className="row" onSubmit={confirmSubmit}>
                <div className="col-md-6">
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Issuer Id</label>
                        <div className="col-sm-8">
                            <input
                                type="input"
                                className="form-control"                                
                                name="issuerId"
                                value={issuerId}
                                disabled />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Setup Id</label>
                        <div className="col-sm-8">
                            <input
                                type="input"
                                className="form-control"
                                placeholder="Setup Id"
                                name="setupId"
                                value={values.setupId}
                                disabled />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Programme Name</label>
                        <div className="col-sm-8">
                            <input
                                type="input"
                                className="form-control"
                                id="setupName"
                                placeholder="Setup Id"
                                name="setupName"
                                value={values.setupName}
                                onChange={onChange}
                                 />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Country</label>
                        <div className="col-sm-8">
                            <select
                                name="setupCountry"
                                className="form-control"
                                onChange={(e: any) => onChange(e)}
                                value={values.setupCountry}>
                                {
                                    countries.map((item: any) => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))
                                }
                                
                            </select>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label" >Balance Mode
                        &nbsp;<i className="fas fa-info-circle" id="balanceModeLabel" data-tooltip-variant="info" aria-hidden="true"></i>
                        </label>
                        <Tooltip anchorId="balanceModeLabel" className='tooltip'>
                            <div>
                                <h5>Balance Mode</h5>
                                <p>This setting determines if the card is acting as debit or credit.</p>
                                <ul>
                                    <li>Credit should only be used on a credit programme where PFS is lending to a cardholder</li><br/>
                                    <li>Credit should also be used on callout programmes when the client is tracking/maintaining the balance</li>
                                </ul>
                                
                            </div>
                        </Tooltip>
                        <div className="col-sm-8">
                            <select
                                ref={focusElement}
                                name="balanceMode"
                                className="form-control"
                                value={values.balanceMode}
                                onChange={(e: any) => onChange(e)}>
                                {
                                    BalanceMode.map((item: any) => (
                                        <option key={item.id} value={item.name}>{item.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Fee Mode
                        &nbsp;<i className="fas fa-info-circle" id="feeModeLabel" data-tooltip-variant="info" aria-hidden="true"></i>
                        </label>
                        <Tooltip anchorId="feeModeLabel" className='tooltip'>
                            <div>
                                <h5>Fee Mode</h5>
                                <p> This setting determines the impact the fee has on the transaction and balance.</p>
                                <ul>
                                    <li>Go Negative = take 100% of the fee and drive card negative if needs be</li><br/>
                                    <li>Decline = decline any transaction where fee will drive card negative</li><br/>
                                    <li>Stay Positive = approve any transaction where fee will drive card negative but do not debit the fee so card stays positive, if there are enough funds to take a partial fee then this will be taken</li>
                                </ul>
                                
                            </div>
                        </Tooltip>
                        <div className="col-sm-8">
                            <select
                                name="negativeFeeMode"
                                className="form-control"
                                value={values.negativeFeeMode}
                                onChange={(e: any) => onChange(e)}>
                                {
                                    NegativeFeeMode.map((item: any) => (
                                        <option key={item.id} value={item.name}>{item.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>        
                </div>

                <div className="col-md-6">
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Cashback</label>
                        <div className="col-sm-4" title="Cashback Transaction will be allowed">
                            <Switch
                                id="isCashbackAllowed"
                                isOn={values.isCashbackAllowed}
                                handleToggle={handleToggle} />
                        </div>

                        <label className="col-sm-2 col-form-label">ATM Block</label>
                        <div className="col-sm-4 pt-1" title="ATM Transaction will be blocked">
                            <Switch
                                id="isAtmBlocked"
                                isOn={values.isAtmBlocked}
                                handleToggle={handleToggle} />
                        </div>
                    </div>



                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Bal. Enquiry Block</label>
                        <div className="col-sm-4 pt-1" title="ATM Balance Enquiry will be blocked">
                            <Switch
                                id="isBalanceEnquiryBlocked"
                                isOn={values.isBalanceEnquiryBlocked}
                                handleToggle={handleToggle} />
                        </div>

                        <label className="col-sm-2 col-form-label">PIN Change Block</label>
                        <div className="col-sm-4 pt-1" title="ATM PIN Change will be blocked">
                            <Switch
                                id="isPinChangedBlocked"
                                isOn={values.isPinChangedBlocked}
                                handleToggle={handleToggle} />
                        </div>
                    </div>


                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Ecommerce Block</label>
                        <div className="col-sm-4 pt-1" title="E-commerce transaction will be blocked">
                            <Switch
                                id="isEcommerceBlocked"
                                isOn={values.isEcommerceBlocked}
                                handleToggle={handleToggle} />
                        </div>

                        <label className="col-sm-2 col-form-label">MOTO Block</label>
                        <div className="col-sm-4 pt-1" title="MOTO Transaction will be blocked">
                            <Switch
                                id="isMotoBlocked"
                                isOn={values.isMotoBlocked}
                                handleToggle={handleToggle} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">POS Block</label>
                        <div className="col-sm-4 pt-1" title="POS Transaction will be blocked">
                            <Switch
                                id="isPosBlocked"
                                isOn={values.isPosBlocked}
                                handleToggle={handleToggle} />
                        </div>

                        <label className="col-sm-2 col-form-label">Contactless Block</label>
                        <div className="col-sm-4 pt-1" title="Contactless POS Transaction will be blocked">
                            <Switch
                                id="isContactlessBlocked"
                                isOn={values.isContactlessBlocked}
                                handleToggle={handleToggle}/>
                        </div>
                    </div>


                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Acquirer Fee
                        &nbsp;<i className="fas fa-info-circle" id="acquirerFeeLabel" data-tooltip-variant="info" aria-hidden="true"></i>
                        </label>
                        <Tooltip anchorId="acquirerFeeLabel" className='tooltip'>
                            <div>
                                <h5>Acquirer Fee</h5>
                                <p> This is the Spanish Royal Decree Fee setting, enable this to charge the ATM fee to the card during the authorisation.</p>                                
                            </div>
                        </Tooltip>
                        <div className="col-sm-4 pt-1" title="Acquirer Fees will be applied">
                            <Switch
                                id="isAcquirerFeesApplied"
                                isOn={values.isAcquirerFeesApplied}
                                handleToggle={handleToggle} />
                        </div>

                        <label className="col-sm-2 col-form-label">MDES</label>
                        <div className="col-sm-4 pt-1" title="Setup will allow MDES tokenization">
                            <Switch
                                id="isMdesEnrolled"
                                isOn={values.isMdesEnrolled}
                                handleToggle={handleToggle}/>
                        </div>
                    </div>                    
                </div>

                <div className="col-md-12">
                    <h4 className="group-field-label">
                        Callout
                    </h4>
                    <div className="form-group row">
                        <label
                            className="col-sm-1 col-form-label">
                            Authorization</label>
                        <div
                            className="col-sm-2 "
                            title="Authorization Callout">
                            <Switch
                                id="isAuthorizationCalledout"
                                isOn={values.isAuthorizationCalledout}
                                handleToggle={handleToggle} />
                        </div>

                        <label className="col-sm-1 col-form-label">Settlement</label>
                        <div className="col-sm-2 pt-1" title="Settlement Callout">
                            <Switch
                                id="isSettlementCalledout"
                                isOn={values.isSettlementCalledout}
                                handleToggle={handleToggle} />
                        </div>

                        <label className="col-sm-1 col-form-label">Bal. Enquiry</label>
                        <div className="col-sm-2 pt-1" title="Bal. Enquiry Callout">
                            <Switch
                                id="isBalanceEnquiryCalledout"
                                isOn={values.isBalanceEnquiryCalledout}
                                handleToggle={handleToggle} />
                        </div>
                    </div>
                </div>
                
                <div className="col-md-12">
                    {
                        updateAccess && <input type="submit" value="Update" disabled={!hasDirtyFields()} className="btn button-control-warning float-right" />
                    }
                </div>
                {  updateAccess && <ConfirmDialog
                    confirmDialog={confirmDialog}
                    setConfirmDialog={setConfirmDialog}
                    action="update"
                />
                }
            </form>
        </div>
        : <Spinner />
    )
}

export default DetailedInformation
