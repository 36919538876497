import {
  InteractionRequiredAuthError,
  InteractionType,
} from "@azure/msal-browser";
import {
  AuthenticatedTemplate,  
  useMsalAuthentication,
} from "@azure/msal-react";
import { loginRequest } from "./authConfig";

import AppRoutes from "./Routes";

import "./site.css";

import IssuerState from "./context/issuer/IssuerState";
import SetupState from "./context/setup/SetupState";
import AuthState from "./context/auth/AuthState";
import ForexMgtState from "./context/forexmgt/ForexMgtState";
import TransactionMgtState from "./context/transactions/TransactionMgtState";
import AccountsState from "./context/accounts/AccountsState";
import RoleBasedAccessControlState from "./context/rolebasedaccesscontrol/RoleBasedAccessControlState";
import { Fragment, useEffect } from "react";
import RequestInterceptor from "./requestInterceptor";


const App = () => {
  const { login, error } = useMsalAuthentication(
    InteractionType.Popup,
    loginRequest
  );

  
  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Popup, loginRequest);
    }
  }, [login, error]);  

  return (
    <Fragment>
      <AuthenticatedTemplate>
        <RoleBasedAccessControlState>
          <RequestInterceptor>
              <AuthState>
                  <AccountsState>
                      <IssuerState>
                        <SetupState>
                          <ForexMgtState>
                            <TransactionMgtState>
                              <AppRoutes />
                            </TransactionMgtState>
                          </ForexMgtState>
                        </SetupState>
                      </IssuerState>
                  </AccountsState>
              </AuthState>
          </RequestInterceptor>
        </RoleBasedAccessControlState>
      </AuthenticatedTemplate>
    </Fragment>
  );
};

export default App;
