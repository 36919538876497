import React, { useReducer } from 'react'
import { createACCOUNTSEndpoint, ENDPOINTS } from '..';
import { AccountsInitState } from '../../models/IAccounts'
import { ACCOUNTS_ERROR, GET_ACCOUNT, GET_ACCOUNTS } from '../types';
import AccountsContext from './AccountsContext';
import AccountsReducer from './AccountsReducer';

const AccountsState: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const initialState: AccountsInitState = {
        accounts: [],
        accountDetails: null,
        totalRows: 0,
        loading: true,
        pageSize: 10,
        pageNo: 1,
        error: null
    }

    const getAccounts = async (search: '', pageNo: number, pageSize: number) => {
        await createACCOUNTSEndpoint(ENDPOINTS.ACCOUNTS).fetchPaginated(search, pageSize, pageNo)
            .then(res => {
                dispatch({ type: GET_ACCOUNTS, payload: res.data })
            }).catch(err => {
                console.log(err)
            });
    }

    const getAccountDetails = async (accountId: string) => {
        await createACCOUNTSEndpoint(ENDPOINTS.ACCOUNTS).getAccountDetails(accountId)
        .then(res => {            
            dispatch({ type: GET_ACCOUNT, payload: res.data.data });
        }).catch(err => {
            dispatch({ type: ACCOUNTS_ERROR, payload: err.error.description });
        })
    }


    const [state, dispatch] = useReducer(AccountsReducer, initialState);

    return (
        <AccountsContext.Provider value={{
            accounts: state.accounts,
            accountDetails: state.accountDetails,
            totalRows: state.totalRows,
            loading: state.loading,
            pageSize: state.pageSize,
            pageNo: state.pageNo,
            error: state.error,
            getAccounts,
            getAccountDetails
        }}>
             {children}
        </AccountsContext.Provider>
    )
}

export default AccountsState
