import { SyntheticEvent, useContext, useState } from "react";
import SetupContext from "../../../../context/setup/SetupContext";
import { checkInputPattern, currencyFormat } from "../../../../models/Helper";
import SecurityClassificationError from "../../../errors/SecurityClassification";

interface ILoadLimit {
  maximumBalance: number | null,
  forcedCredit: number | null,
  daily: number | null,
  monthly: number | null,
  yearly: number | null,  
}

const UpdateLoadLimit = (props: any) => {
  const { data, setOpenPopup, issuerId, setupId, currencyCode, customerType,messageReference,securityClassifications,proccedAndLoad,canPageBeRendered } = props;
  const { updateLoadLimit } = useContext(SetupContext);

  const updateAccess = securityClassifications?.includes("Update");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const createAccess = securityClassifications?.includes("Create");

  const setDefaultVal = (val: number | null, divisor: number) => {    
    return val === null ? null : val === 0 ? 0 : Number(currencyFormat(val/divisor));        
  };

  const setPayload = (val: number | null, multiplier: number) => {    
    return val === null ? null : val * multiplier;        
  };

  const setDisplay = (val: number | null) => {
    return val === null ? '' : val;
  }; 

  const [loadLimit, setLoadLimit] = useState<ILoadLimit>({
    maximumBalance: setDefaultVal(data['maximumBalance'],100),
    forcedCredit: setDefaultVal(data['forcedCredit'],100),
    daily: setDefaultVal(data['daily'],100),
    monthly: setDefaultVal(data['monthly'],100),
    yearly: setDefaultVal(data['yearly'],100),
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const {name, value } = e.target            
      setLoadLimit({ ...loadLimit, [name]: value === "" ? null : Number(value) })
  }

  const onReset = () => {
    setLoadLimit({
      maximumBalance: setDefaultVal(data['maximumBalance'],100),
      forcedCredit: setDefaultVal(data['forcedCredit'],100),
      daily: setDefaultVal(data['daily'],100),
      monthly: setDefaultVal(data['monthly'],100),
      yearly: setDefaultVal(data['yearly'],100)
    })
  }

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const payload = {
      maximumBalance: setPayload(loadLimit['maximumBalance'],100),
      forcedCredit: setPayload(loadLimit['forcedCredit'],100),
      daily: setPayload(loadLimit['daily'],100),
      monthly: setPayload(loadLimit['monthly'],100),
      yearly: setPayload(loadLimit['yearly'],100),
    };
    updateLoadLimit(issuerId, setupId, payload, currencyCode, customerType);
    setOpenPopup(false);
  };

  if(!proccedAndLoad || !updateAccess)
  {
      return (<SecurityClassificationError title="" pageName="" extraReference={`the ${messageReference}`} withWrapper={false}/>)
  }

  return (
    <form
      className="row"
        onSubmit={onSubmit}
        onReset={onReset}
    >
      <div className="col-sm-12">
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">Max. Bal.</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              placeholder="Max. Bal"
              name="maximumBalance"
              onKeyPress={(event) => {
                if (checkInputPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={setDisplay(loadLimit['maximumBalance'])}
              onChange={onChange}              
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">Forced Credit</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              placeholder="Forced Credit"
              name="forcedCredit"
              onKeyPress={(event) => {
                if (checkInputPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={setDisplay(loadLimit['forcedCredit'])}
              onChange={onChange}              
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">Daily</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              placeholder="Daily"
              name="daily"
              onKeyPress={(event) => {
                if (checkInputPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={setDisplay(loadLimit['daily'])}
              onChange={onChange}              
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">Monthly</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              placeholder="Monthly"
              name="monthly"
              onKeyPress={(event) => {
                if (checkInputPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={setDisplay(loadLimit['monthly'])}
              onChange={onChange}              
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">Yearly</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              placeholder="Yearly"
              name="yearly"
              onKeyPress={(event) => {
                if (checkInputPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={setDisplay(loadLimit['yearly'])}
              onChange={onChange}              
            />
          </div>
        </div>
        <hr />
        <div className="form-group row">
          <div className="float-right">
            <button
              type="button"
              className="btn-secondary btn-sm m-1"
              onClick={() => setOpenPopup(false)}
            >
              Cancel
            </button>
            <button
              type="reset"
              className="btn-warning btn-sm m-1"              
            >
              Reset
            </button>
            {
              updateAccess && 
              <button type="submit" className="btn-success btn-sm m-1">
                Save
              </button>
            }
          </div>
        </div>
      </div>
    </form>
  );
};

export default UpdateLoadLimit;
