export enum RoleActionType {
    SetRoleBasedAccessControls = "SET_ACCESS_CONTROLS",
    SetCurrentRole = "SET_CURRENT_ROLE",
    GetSecurityClassification = "GET_CRUD_EVENTS_ASSIGNED",
    GetPageAccess = "GET_PAGE_ACCESS",
    GetAllowedNavigationItems = "GET_MENU_ITEMS_TO_SHOW",
    SetDisabled = "SET_DISABLED", 
    SetError = "SET_RBAC_ERROR",
    ClearError = "CLEAR_RBAC_ERROR",
    ResetConfigurations = "RESET_RBAC",
    GetUserRoles = "GET_USER_ROLES",
}

