import React, { useState, useEffect } from "react";
import ControlledTextArea from "../../../../components/controlledtextarea/ControlledTextArea";
import Switch from "../../../../components/switch";
import { TCardReverse } from "../../../../models/ICardVendor";
import { vendorTypes } from "../../../../models/enum/vendorTypes";
import { CardReverseCardholderIdPosition } from "../../../../models/enum/CardReverseCardholderIdPosition";
import { CardReverseIssuerStatementOptions } from "../../../../models/enum/CardReverseIssuerStatement";
import { BarCodeCardReversePosition } from "../../../../models/enum/BarCodeCardReversePosition";

const CardReverse = ({cardReverseData, handleChanges} : {cardReverseData:any, handleChanges:any}) => {
  const [cardReverse, setCardReverse] = useState<TCardReverse>(cardReverseData);
  
  useEffect(() => {setCardReverse(cardReverseData)}, [cardReverseData])  

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>) => {
      const { id, value} = e.currentTarget         
      handleChanges(id, value, vendorTypes.CARD_REVERSE )          
  }; 
  
  const handleToggle = (id: string, value: boolean) => {        
    handleChanges(id, value, vendorTypes.CARD_REVERSE ) 
  };

  const mapIssuerStatement = (id: string) => {
    if (!id) return "";
    return CardReverseIssuerStatementOptions.find(s => s.id === Number(id))?.name
  }

  return (
    <div className="card-body">
      <div className="row">
        <div className="col-md-12">
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Bar Code</label>
            <div className="col-sm-6 pt-1" title="Has barcode?">
              <Switch
                id="barCode"
                name="barCode"
                isOn={cardReverse?.barCode || false}
                handleToggle={handleToggle}            
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">
              Cardholder Id Position
            </label>
            <div className="col-sm-8">             
              <select                 
                id="cardholderIdPosition"
                name="cardholderIdPosition"
                className="form-control form-control-sm"
                value={cardReverse?.cardholderIdPosition || ""}
                onChange={handleChange}
              >
                <option value={""}>Select Cardholder Id Position</option>
                {CardReverseCardholderIdPosition.map((item: any) => (
                  <option key={item.id} value={item.id}>
                    {item.id} - {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>          
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Contact Detail</label>
            <div className="col-sm-8">
              <ControlledTextArea
                className="form-control form-control-sm"
                rows={5}
                id="contactDetail"
                name="contactDetail"
                value={cardReverse?.contactDetail || ""} 
                onChange={handleChange}               
              />
            </div>

          </div>          
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Issuer Statement</label>
            <div className="col-sm-8">              
              <select 
                title={mapIssuerStatement(cardReverse?.issuerStatement || "")}
                id="issuerStatement"
                name="issuerStatement"
                className="form-control form-control-sm overflow-ellipsis"
                value={cardReverse?.issuerStatement || ""}
                onChange={handleChange}
                
              >
                <option value={""} className="overflow-ellipsis">Select Issuer Statement</option>
                {CardReverseIssuerStatementOptions.map((item: any) => (
                  <option key={item.id} value={item.id} title={item.name}>
                    {item.id} - {item.display}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">
              Bar Code Property Position
            </label>
            <div className="col-sm-8">             
              <select                 
                id="barCodePropertyPosition"
                name="barCodePropertyPosition"
                className="form-control form-control-sm"
                value={cardReverse?.barCodePropertyPosition || ""}
                onChange={handleChange}
                required
              >
                <option value={""}>Select Bar Code Property Position</option>
                {BarCodeCardReversePosition.map((item: any) => (
                  <option key={item.id} value={item.id}>
                    {item.id} - {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="form-group row">
          <label className="col-sm-4 col-form-label">
              Bar Code Property
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="barCodeProperty"
                name="barCodeProperty"
                value={cardReverse?.barCodeProperty || ""}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardReverse
