import cron from 'cron-validate'
import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ConfirmDialog from "../../../../components/dialog/ConfirmDialog";
import Spinner from "../../../../components/spinner/Spinner";
import Switch from "../../../../components/switch";
import RoleBasedAccessControlContext from '../../../../context/rolebasedaccesscontrol/RoleBasedAccessControlContext';
import SetupContext from "../../../../context/setup/SetupContext";
import useAutofocus from "../../../../hooks/useAutofocus";
import { ConfirmDialogInitValues } from "../../../../models/ConfirmDialogInitValues";
import { ActiveVendor } from "../../../../models/enum/ActiveVendor";
import { vendorTypes } from "../../../../models/enum/vendorTypes";
import {
  ICardVendor,
  initCardVendor,
  TCardVendor,
} from "../../../../models/ICardVendor";
import DeliveryDetails from "./DeliveryDetails";
import ProductInformation from "./ProductInformation";
import UnauthorizedPage from "../../../errors/UnauthorizedPage";
import SecurityClassificationError from "../../../errors/SecurityClassification";


const CardVendorDetail = ({ cardVendor }: ICardVendor) => {

  const {securityClassifications,getSecurityClassification,retrievePagePermission}  = useContext(RoleBasedAccessControlContext);
  const [proccedAndLoad, setUserAccess] = useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [canPageBeRendered, canInvokePageContent] = useState(false); 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

  useEffect(() => {
      retrievePagePermission("setup").then((isGranted: boolean) => {
          canInvokePageContent(true);
          setUserAccess(isGranted);
      }).catch((error: any) => {
          canInvokePageContent(false);
          setUserAccess(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
      getSecurityClassification();
        // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const readAccess = securityClassifications?.includes("Read");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const createAccess = securityClassifications?.includes("Create");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateAccess = securityClassifications?.includes("Update");

  const { vendorSetupId, issuerId, setupId } = useParams();
  const [vendorDetail, setVendorDetail] = useState<TCardVendor>(
    cardVendor || initCardVendor
  );
  const { cardVendorDetail, getSetupDetails, setCardVendor, loading } =
    useContext(SetupContext);
  const [confirmDialog, setConfirmDialog] = useState(ConfirmDialogInitValues);

  const [dirtyFields, setDirtyFields] = useState({});

  const [inValidCronException, setInValidCronException] = useState<string | null>(null);

  const focusElement = useAutofocus();

  const refreshDetails = (
    ((!cardVendor && vendorSetupId) ||
    (cardVendor?.vendorSetupId !== vendorSetupId)) &&
    vendorSetupId )

  useEffect(() => {
    if (refreshDetails) {      
      getSetupDetails(issuerId, setupId);
    }
    const detail = cardVendorDetail?.find(
      (d: TCardVendor) => d.vendorSetupId === vendorSetupId
    );
    if (detail) setVendorDetail(detail);
  }, [
    cardVendor,
    vendorSetupId,
    cardVendorDetail,
    getSetupDetails,
    issuerId,
    setupId,
    refreshDetails
  ]);  
  

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLSelectElement>
  ) => {
    const { id, value } = e.currentTarget;
    setVendorDetail({
      ...vendorDetail,
      [id]: value,
    });
    setDirtyFields({
      ...dirtyFields,
      [vendorTypes.CARD_VENDOR_DETAIL]: true,
    });
  };

  const handleCronBlur = (e: React.ChangeEvent<HTMLInputElement>) => {    
    const { value } = e.currentTarget;
    if (value.trim().length === 0) {
      setVendorDetail({
        ...vendorDetail,
        cronJob: null,
      });
      setInValidCronException(null)
    } else {
      if (validateCron(value)) {
        setInValidCronException(null)     
      } 
    }
  };

  const validateCron = (value: string) => {
    const cronResult = cron(value, {
      preset: 'default',
      override: {
        useAliases: true
      }
    })
    if (cronResult.isValid()) {           
      return true      
    } else {
      const errorValue = cronResult.getError()
      setInValidCronException(errorValue.join(" "))    
    }    
  }

  const handleToggle = (id: string, value: boolean) => {        
      setVendorDetail({ ...vendorDetail, [id]: value });
      setDirtyFields({
        ...dirtyFields,
        [vendorTypes.CARD_VENDOR_DETAIL]: true,
      });
  };

  const handleChanges = (name: string, value: any, type: string) => {    
    switch (type) {
      case vendorTypes.DELIVERY_DETAILS:
        setVendorDetail({
          ...vendorDetail,
          deliveryDetails: { ...vendorDetail.deliveryDetails, [name]: value },
        });        
        break;
      case vendorTypes.BULK:
        setVendorDetail({
          ...vendorDetail,
          deliveryDetails: {
            ...vendorDetail.deliveryDetails,
            bulk: {
              ...vendorDetail.deliveryDetails?.bulk,
              [name]: value,
            },
          },
        });        
        break;
      case vendorTypes.PRODUCT_INFORMATION:
        setVendorDetail({
          ...vendorDetail,
          productInformation: {
            ...vendorDetail.productInformation,
            [name]: value,
          },
        });        
        break;
      case vendorTypes.CARD_REVERSE:
        setVendorDetail({
          ...vendorDetail,
          productInformation: {
            ...vendorDetail.productInformation,
            cardReverse: {
              ...vendorDetail.productInformation?.cardReverse,
              [name]: value,
            },
          },
        });        
        break;
      case vendorTypes.CARRIER:
        setVendorDetail({
          ...vendorDetail,
          productInformation: {
            ...vendorDetail.productInformation,
            carrier: {
              ...vendorDetail.productInformation?.carrier,
              [name]: value,
            },
          },
        });        
        break;
      case vendorTypes.INSTANT_ISSUE_PACKS:
        setVendorDetail({
          ...vendorDetail,
          productInformation: {
            ...vendorDetail.productInformation,
            instantIssuePacks: {
              ...vendorDetail.productInformation?.instantIssuePacks,
              [name]: value,
            },
          },
        });        
        break;
    }
    setDirtyFields({
      ...dirtyFields,
      [type]: true,
    });
  };  

  const isFormValid = hasDirtyFields() 
    && !inValidCronException
    && vendorDetail?.vendorSetupId
    && vendorDetail?.activeVendor
    && vendorDetail?.productInformation?.productCode
    && vendorDetail?.productInformation?.language

  function hasDirtyFields() {
    return Object.values(dirtyFields).some((x) => x === true);
  }

  const confirmSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    setConfirmDialog({
      isOpen: true,
      title: `Are you sure you want to update the setup setting?`,
      subTitle: "You cannot undo this operation.",
      onConfirm: () => onSubmit(e),
      onCancel: () => onCancel(e),
    });
  };

  const onSubmit = (e: SyntheticEvent) => {
    setCardVendor(issuerId, setupId, vendorDetail);

    setDirtyFields({});
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };

  const onCancel = (e: SyntheticEvent) => {
    if (vendorSetupId) {
      getSetupDetails(issuerId, setupId);
      setDirtyFields({});
    }
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };

  if(!proccedAndLoad)
  {
      return <UnauthorizedPage withWrapper={false} />;
  }

  if(!updateAccess){
    return (<SecurityClassificationError title="Update Classification Denied" pageName="Card Styles" extraReference='Card Styles' withWrapper={false}/>)
  }

  return (
    !loading ?
    <>
      <div className="badge bg-light">Card Style Detail</div>
      <div className="card-body">
        <form className="row gy-2" onSubmit={confirmSubmit}>
          <div className="col-md-12">
            <div className="card-body">
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label required-field">
                    Card Style
                    </label>
                    <div className="col-sm-8">
                      <input
                        ref={focusElement}
                        type="text"
                        className="form-control form-control-sm"
                        id="vendorSetupId"
                        name="vendorSetupId"
                        value={vendorDetail?.vendorSetupId || ""}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label required-field">
                    Vendor Name
                    </label>
                    <div className="col-sm-8">
                      <select
                        id="activeVendor"
                        name="activeVendor"
                        className="form-control form-control-sm"
                        value={vendorDetail?.activeVendor || ""}
                        onChange={handleChange}
                        required
                      >
                        <option value={""}>Select Vendor Name</option>
                        {ActiveVendor.map((item: any) => (
                          <option key={item.name} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Delivery Schedule</label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="cronJob"
                        placeholder="Cron Expression"
                        name="cronJob"
                        value={vendorDetail?.cronJob || ""}
                        onChange={handleChange}
                        onBlur={handleCronBlur}
                      />
                      {inValidCronException && (
                        <div className="validation-error">
                          {inValidCronException}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Valid From
                    </label>
                    <div
                      className="col-sm-6 pt-1"
                      title="Indicator for including valid from date value in json file"
                    >
                      <Switch
                        id="validFrom"
                        isOn={vendorDetail?.validFrom || false}
                        handleToggle={handleToggle}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Fourth Line Override
                    </label>
                    <div
                      className="col-sm-8 pt-1"
                      title="Indicator whether to replace with Issuer Card Id in json file"
                    >
                      <Switch
                        id="fourthLineOverride"
                        isOn={vendorDetail?.fourthLineOverride || false}
                        handleToggle={handleToggle}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="card" style={{ marginTop: "-25px" }}>
                    <div className="badge bg-light">Delivery Details</div>
                    <DeliveryDetails
                      details={vendorDetail?.deliveryDetails}
                      handleChanges={handleChanges}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card">
                <div className="badge bg-light">Product Information</div>
                <ProductInformation
                  productInformationData={vendorDetail?.productInformation}
                  handleChanges={handleChanges}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            { updateAccess && <input
              type="submit"
              value="Update"
              className="btn button-control-warning float-right"
              disabled={!isFormValid}
            />
            }
          </div>
          { updateAccess && <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
            action="update"
          /> }
        </form>
      </div>
    </>
    : <Spinner />
  );
};

export default CardVendorDetail;
