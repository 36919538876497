import React, { Fragment } from "react";
import PeriodicCounts from "./PeriodicCounts";

const RegionalCounts = (props: any) => {
    const { data, title, subtitle, issuerId, setupId, customerType,securityClassifications,canPageBeRendered,proccedAndLoad} = props;

    return (
        <Fragment>
            <PeriodicCounts 
                type="Domestic"
                data={data !== null ? data.domestic : null} isParent={false}
                issuerId={issuerId}
                setupId={setupId}
                customerType={customerType}
                title={title}
                subtitle={subtitle}
                securityClassifications={securityClassifications} 
                canPageBeRendered={canPageBeRendered} 
                proccedAndLoad={proccedAndLoad}/>
            <PeriodicCounts 
                type="Intranational" 
                data={data !== null ? data.intranational : null} isParent={false}
                issuerId={issuerId}
                setupId={setupId}
                customerType={customerType}
                title={title}
                subtitle={subtitle}
                securityClassifications={securityClassifications} 
                canPageBeRendered={canPageBeRendered} 
                proccedAndLoad={proccedAndLoad}/>
            <PeriodicCounts 
                type="International" 
                data={data !== null ? data.international : null} isParent={false}
                issuerId={issuerId}
                setupId={setupId}
                customerType={customerType}
                title={title}
                subtitle={subtitle}
                securityClassifications={securityClassifications} 
                canPageBeRendered={canPageBeRendered} 
                proccedAndLoad={proccedAndLoad}/>
            <PeriodicCounts 
                type="Globally"
                data={data !== null ? data.globally : null}  isParent={false}
                issuerId={issuerId}
                setupId={setupId}
                customerType={customerType}
                title={title}
                subtitle={subtitle}
                securityClassifications={securityClassifications} 
                canPageBeRendered={canPageBeRendered} 
                proccedAndLoad={proccedAndLoad}/>
        </Fragment>
    )
}

export default RegionalCounts
