export const PersoTypeCode = [
    {
        id: 1,
        name: "Emboss"
    },
    {
        id: 2,
        name: "Indent"
    },
    {
        id: 3,
        name: "Flat Print (DUG/DOD/Laser)"
    }
]