import { ICurrencyFee, ICurrencyLimit, SetupInitState } from "../../models/ISetup";
import { GET_SETUP, GET_SETUPS, SETUP_ERROR } from "../types";

const SetupReducer = (state: SetupInitState, action: any) => {
  switch (action.type) {
    case GET_SETUPS:
      return {
        ...state,
        setups: action.payload,
        setupDetails: null,
        totalRows: action.payload.paging.totalRecordCount,
        loading: false,
        pageSize: action.payload.paging.pageSize,
        pageNo: action.payload.paging.pageNo,
      };

    case GET_SETUP:
      return {
        ...state,
        setupDetails: action.payload,
        panConfigs: action.payload.panConfigs,
        blockedMCCs: action.payload.blockedMCCs,
        currencyLimits: action.payload.currencyLimits.sort((a:ICurrencyLimit,b:ICurrencyLimit) => {return a.currencyCode - b.currencyCode}),
        transactionCounts: action.payload.transactionCounts,
        currencyFees: action.payload.currencyFees.sort((a:ICurrencyFee,b:ICurrencyFee) => {return a.currencyCode - b.currencyCode}),
        contactlessLimits: action.payload.contactlessLimits,
        cardVendorDetail: action.payload.cardVendorDetail,
        loading: false,
      };

    case SETUP_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default SetupReducer;