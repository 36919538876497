import { Card } from "../../../models/IAccountDetails";
import UserDefined from "../userdefined/UserDefined";

export interface IProps {
  myList: Array<Card>;
}

const CardList = (props: IProps) => {
  const { myList } = props;

  return (
    <table
      id="table-data"
      className="table table-striped dataTable dtr-inline table-sm table-bordered"
      role="grid"
    >
      <thead className="table-header">
                            <tr role="row">
                                <th></th>
                                <th>Status</th>
                                <th>Type</th>
                                <th>Name</th>
                                <th>Created</th>
                                <th>PAN</th>
                                <th>Wallet ID</th>
                                <th>Card ID</th>
                                <th>CVV</th>
                                <th>User Defined</th>
                            </tr>
                        </thead>
      <tbody>
        {myList.map((card: any, idx: number) => (
          <tr key={idx}>
            <td></td>
            <td>{card.cardStatus}</td>
            <td>{card.cardType}</td>
            <td>{card.printName}</td>
            <td>{card.created}</td>
            <td>{card.pan}</td>
            <td>{card.defaultWalletId}</td>
            <td>{card.cardId}</td>
            <td>{card.cvv}</td>
            <td>
            {Object.entries(card?.userDefined).map(
                                (el, ind) => (
                                  <UserDefined key={ind} data={el}/>
                                )
                              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CardList;
