export const initSetup = {
  setupId: "",
  setupName: "",
  setupCountry: "",
  negativeFeeMode: -1,
  balanceMode: -1,
  legalEntity: -1,
  isAtmBlocked: false,
  isBalanceEnquiryBlocked: false,
  isPinChangedBlocked: false,
  isPosBlocked: false,
  isEcommerceBlocked: false,
  isMotoBlocked: false,
  isContactlessBlocked: false,
  isMdesEnrolled: false,
  isAcquirerFeesApplied:false,  
  isAuthorizationCalledout: false,
  isSettlementCalledout: false,
  isBalanceEnquiryCalledout: false,
  isCashbackAllowed: false,
  pinRequiredAfterContactlessEntriesCount: 0
};

export type TSetup = {
  setupId: string;
  setupName: string;
  setupCountry: string;
  negativeFeeMode: string;
  balanceMode: string;
  legalEntity: string;
  mdesArqcCheck: boolean;
  isAtmBlocked: boolean;
  isBalanceEnquiryBlocked: boolean;
  isPinChangedBlocked: boolean;
  isPosBlocked: boolean;
  isEcommerceBlocked: boolean;
  isMotoBlocked: boolean;
  isContactlessBlocked: boolean;
  isMdesEnrolled: boolean;
  isAcquirerFeesApplied:boolean;
  isAuthorizationCalledout: boolean;
  isSettlementCalledout: boolean;
  isBalanceEnquiryCalledout: boolean;
  isCashbackAllowed: boolean;
  pinRequiredAfterContactlessEntriesCount: number;
};
