import { SyntheticEvent, useContext, useState } from "react";
import SetupContext from "../../../../context/setup/SetupContext";
import { isWholeNumberPattern } from "../../../../models/Helper";
import TagsInput from '../../../../components/tagsInput/TagsInput';

interface IMccCount {
  daily: number | null,
  monthly: number | null,
  yearly: number | null,
}

const AddMccCount = (props: any) => {
  const { setOpenPopup, issuerId, setupId, customerType, data,securityClassifications,canPageBeRendered,proccedAndLoad } = props;
  const [mcc, set_mcc] = useState<Array<Number>>([]);
  const { addMccCount } = useContext(SetupContext);  

  const existingMccList = data.map((item: any) => item.mcc).flatMap((mcc: any) => mcc)  

  const [mccCount, setmccCount] = useState<IMccCount>({
    daily: null,
    monthly: null,
    yearly: null,
  });

  const setPayload = (val: number | null) => {    
    return val === null ? null : val;        
  };

  const setDisplay = (val: number | null) => {
    return val === null ? '' : val;
  };  

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value } = e.target                    
    setmccCount({ ...mccCount,  [name]: value === "" ? null : Number(value) })
  };

  const onSubmit = (e: SyntheticEvent) => {    
    e.preventDefault();
    const payload = {
      daily: setPayload(mccCount["daily"]),
      monthly: setPayload(mccCount["monthly"]),
      yearly: setPayload(mccCount["yearly"]),
      mCC: mcc
    };
    addMccCount(issuerId, setupId, payload, customerType);
    setOpenPopup(false);
  };

  const isValid =     
    mcc && mcc.length > -1 &&
       !existingMccList.includes(mcc)

  const updateAccess = securityClassifications?.includes("Update");
  const createAccess = securityClassifications?.includes("Create");

  return (
    <form className="row" 
    onSubmit={onSubmit}
    >
      <div className="col-sm-12">
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">MCC</label>
          <div className="col-sm-8">
              <TagsInput
                placeHolder="Enter MCC"
                onChange={(val) => {
                    const mccData = val.filter((item: any) => Number(item)).map((item: any) => Number(item));
                    set_mcc(mccData);
                }}
              />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">Daily</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              placeholder="Daily"
              name="daily"
              onKeyPress={(event) => {
                if (!isWholeNumberPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={setDisplay(mccCount["daily"])}
              onChange={onChange}              
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">Monthly</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              placeholder="Monthly"
              name="monthly"
              onKeyPress={(event) => {
                if (!isWholeNumberPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={setDisplay(mccCount["monthly"])}
              onChange={onChange}              
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">Yearly</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              placeholder="Yearly"
              name="yearly"
              onKeyPress={(event) => {
                if (!isWholeNumberPattern(event)) {
                  event.preventDefault();
                }
              }}
              autoComplete="off"
              // maxLength={6}
              defaultValue={setDisplay(mccCount["yearly"])}
              onChange={onChange}              
            />
          </div>
        </div>
        <hr />
        <div className="form-group row">
          <div className="float-right">
            <button
              type="button"
              className="btn-secondary btn-sm m-1"
              onClick={() => setOpenPopup(false)}
            >
              Cancel
            </button>
            {
              updateAccess && <button disabled={!isValid} type="submit" className="btn-success btn-sm m-1">
              Save
            </button>
            }
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddMccCount;
