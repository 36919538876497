const ISO639_1_Languages = [
  {
    "value": "en",
    "label": "English"
  },
  {
    "value": "aa",
    "label": "Afar"
  },
  {
    "value": "ab",
    "label": "Abkhaz"
  },
  {
    "value": "ae",
    "label": "Avestan"
  },
  {
    "value": "af",
    "label": "Afrikaans"
  },
  {
    "value": "ak",
    "label": "Akan"
  },
  {
    "value": "am",
    "label": "Amharic"
  },
  {
    "value": "an",
    "label": "Aragonese"
  },
  {
    "value": "ar",
    "label": "Arabic"
  },
  {
    "value": "as",
    "label": "Assamese"
  },
  {
    "value": "av",
    "label": "Avaric"
  },
  {
    "value": "ay",
    "label": "Aymara"
  },
  {
    "value": "az",
    "label": "Azerbaijani"
  }, 
  {
    "value": "ba",
    "label": "Bashkir"
  },
  {
    "value": "be",
    "label": "Belarusian"
  },
  {
    "value": "bg",
    "label": "Bulgarian"
  },
  {
    "value": "bh",
    "label": "Bihari"
  },
  {
    "value": "bi",
    "label": "Bislama"
  },
  {
    "value": "bm",
    "label": "Bambara"
  },
  {
    "value": "bn",
    "label": "Bengali; Bangla"
  },
  {
    "value": "bo",
    "label": "Tibetan Standard, Tibetan, Central"
  },
  {
    "value": "br",
    "label": "Breton"
  },
  {
    "value": "bs",
    "label": "Bosnian"
  },
  {
    "value": "ca",
    "label": "Catalan; Valencian"
  },
  {
    "value": "ce",
    "label": "Chechen"
  },
  {
    "value": "ch",
    "label": "Chamorro"
  },
  {
    "value": "co",
    "label": "Corsican"
  },
  {
    "value": "cr",
    "label": "Cree"
  },
  {
    "value": "cs",
    "label": "Czech"
  },
  {
    "value": "cu",
    "label": "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic"
  },
  {
    "value": "cv",
    "label": "Chuvash"
  },
  {
    "value": "cy",
    "label": "Welsh"
  },
  {
    "value": "da",
    "label": "Danish"
  },
  {
    "value": "de",
    "label": "German"
  },
  {
    "value": "dv",
    "label": "Divehi; Dhivehi; Maldivian;"
  },
  {
    "value": "dz",
    "label": "Dzongkha"
  },
  {
    "value": "ee",
    "label": "Ewe"
  },
  {
    "value": "el",
    "label": "Greek, Modern"
  },
  {
    "value": "eo",
    "label": "Esperanto"
  },
  {
    "value": "es",
    "label": "Spanish; Castilian"
  },
  {
    "value": "et",
    "label": "Estonian"
  },
  {
    "value": "eu",
    "label": "Basque"
  },
  {
    "value": "fa",
    "label": "Persian (Farsi)"
  },
  {
    "value": "ff",
    "label": "Fula; Fulah; Pulaar; Pular"
  },
  {
    "value": "fi",
    "label": "Finnish"
  },
  {
    "value": "fj",
    "label": "Fijian"
  },
  {
    "value": "fo",
    "label": "Faroese"
  },
  {
    "value": "fr",
    "label": "French"
  },
  {
    "value": "fy",
    "label": "Western Frisian"
  },
  {
    "value": "ga",
    "label": "Irish"
  },
  {
    "value": "gd",
    "label": "Scottish Gaelic; Gaelic"
  },
  {
    "value": "gl",
    "label": "Galician"
  },
  {
    "value": "gn",
    "label": "GuaranÃ­"
  },
  {
    "value": "gu",
    "label": "Gujarati"
  },
  {
    "value": "gv",
    "label": "Manx"
  },
  {
    "value": "ha",
    "label": "Hausa"
  },
  {
    "value": "he",
    "label": "Hebrew (modern)"
  },
  {
    "value": "hi",
    "label": "Hindi"
  },
  {
    "value": "ho",
    "label": "Hiri Motu"
  },
  {
    "value": "hr",
    "label": "Croatian"
  },
  {
    "value": "ht",
    "label": "Haitian; Haitian Creole"
  },
  {
    "value": "hu",
    "label": "Hungarian"
  },
  {
    "value": "hy",
    "label": "Armenian"
  },
  {
    "value": "hz",
    "label": "Herero"
  },
  {
    "value": "ia",
    "label": "Interlingua"
  },
  {
    "value": "id",
    "label": "Indonesian"
  },
  {
    "value": "ie",
    "label": "Interlingue"
  },
  {
    "value": "ig",
    "label": "Igbo"
  },
  {
    "value": "ii",
    "label": "Nuosu"
  },
  {
    "value": "ik",
    "label": "Inupiaq"
  },
  {
    "value": "io",
    "label": "Ido"
  },
  {
    "value": "is",
    "label": "Icelandic"
  },
  {
    "value": "it",
    "label": "Italian"
  },
  {
    "value": "iu",
    "label": "Inuktitut"
  },
  {
    "value": "ja",
    "label": "Japanese"
  },
  {
    "value": "jv",
    "label": "Javanese"
  },
  {
    "value": "ka",
    "label": "Georgian"
  },
  {
    "value": "kg",
    "label": "Kongo"
  },
  {
    "value": "ki",
    "label": "Kikuyu, Gikuyu"
  },
  {
    "value": "kj",
    "label": "Kwanyama, Kuanyama"
  },
  {
    "value": "kk",
    "label": "Kazakh"
  },
  {
    "value": "kl",
    "label": "Kalaallisut, Greenlandic"
  },
  {
    "value": "km",
    "label": "Khmer"
  },
  {
    "value": "kn",
    "label": "Kannada"
  },
  {
    "value": "ko",
    "label": "Korean"
  },
  {
    "value": "kr",
    "label": "Kanuri"
  },
  {
    "value": "ks",
    "label": "Kashmiri"
  },
  {
    "value": "ku",
    "label": "Kurdish"
  },
  {
    "value": "kv",
    "label": "Komi"
  },
  {
    "value": "kw",
    "label": "Cornish"
  },
  {
    "value": "ky",
    "label": "Kyrgyz"
  },
  {
    "value": "la",
    "label": "Latin"
  },
  {
    "value": "lb",
    "label": "Luxembourgish, Letzeburgesch"
  },
  {
    "value": "lg",
    "label": "Ganda"
  },
  {
    "value": "li",
    "label": "Limburgish, Limburgan, Limburger"
  },
  {
    "value": "ln",
    "label": "Lingala"
  },
  {
    "value": "lo",
    "label": "Lao"
  },
  {
    "value": "lt",
    "label": "Lithuanian"
  },
  {
    "value": "lu",
    "label": "Luba-Katanga"
  },
  {
    "value": "lv",
    "label": "Latvian"
  },
  {
    "value": "mg",
    "label": "Malagasy"
  },
  {
    "value": "mh",
    "label": "Marshallese"
  },
  {
    "value": "mi",
    "label": "MÄori"
  },
  {
    "value": "mk",
    "label": "Macedonian"
  },
  {
    "value": "ml",
    "label": "Malayalam"
  },
  {
    "value": "mn",
    "label": "Mongolian"
  },
  {
    "value": "mr",
    "label": "Marathi (MarÄá¹­hÄ«)"
  },
  {
    "value": "ms",
    "label": "Malay"
  },
  {
    "value": "mt",
    "label": "Maltese"
  },
  {
    "value": "my",
    "label": "Burmese"
  },
  {
    "value": "na",
    "label": "Nauru"
  },
  {
    "value": "nb",
    "label": "Norwegian BokmÃ¥l"
  },
  {
    "value": "nd",
    "label": "North Ndebele"
  },
  {
    "value": "ne",
    "label": "Nepali"
  },
  {
    "value": "ng",
    "label": "Ndonga"
  },
  {
    "value": "nl",
    "label": "Dutch"
  },
  {
    "value": "nn",
    "label": "Norwegian Nynorsk"
  },
  {
    "value": "no",
    "label": "Norwegian"
  },
  {
    "value": "nr",
    "label": "South Ndebele"
  },
  {
    "value": "nv",
    "label": "Navajo, Navaho"
  },
  {
    "value": "ny",
    "label": "Chichewa; Chewa; Nyanja"
  },
  {
    "value": "oc",
    "label": "Occitan"
  },
  {
    "value": "oj",
    "label": "Ojibwe, Ojibwa"
  },
  {
    "value": "om",
    "label": "Oromo"
  },
  {
    "value": "or",
    "label": "Oriya"
  },
  {
    "value": "os",
    "label": "Ossetian, Ossetic"
  },
  {
    "value": "pa",
    "label": "Panjabi, Punjabi"
  },
  {
    "value": "pi",
    "label": "PÄli"
  },
  {
    "value": "pl",
    "label": "Polish"
  },
  {
    "value": "ps",
    "label": "Pashto, Pushto"
  },
  {
    "value": "pt",
    "label": "Portuguese"
  },
  {
    "value": "qu",
    "label": "Quechua"
  },
  {
    "value": "rm",
    "label": "Romansh"
  },
  {
    "value": "rn",
    "label": "Kirundi"
  },
  {
    "value": "ro",
    "label": "Romanian, [])"
  },
  {
    "value": "ru",
    "label": "Russian"
  },
  {
    "value": "rw",
    "label": "Kinyarwanda"
  },
  {
    "value": "sa",
    "label": "Sanskrit (Saá¹ská¹›ta)"
  },
  {
    "value": "sc",
    "label": "Sardinian"
  },
  {
    "value": "sd",
    "label": "Sindhi"
  },
  {
    "value": "se",
    "label": "Northern Sami"
  },
  {
    "value": "sg",
    "label": "Sango"
  },
  {
    "value": "si",
    "label": "Sinhala, Sinhalese"
  },
  {
    "value": "sk",
    "label": "Slovak"
  },
  {
    "value": "sl",
    "label": "Slovene"
  },
  {
    "value": "sm",
    "label": "Samoan"
  },
  {
    "value": "sn",
    "label": "Shona"
  },
  {
    "value": "so",
    "label": "Somali"
  },
  {
    "value": "sq",
    "label": "Albanian"
  },
  {
    "value": "sr",
    "label": "Serbian"
  },
  {
    "value": "ss",
    "label": "Swati"
  },
  {
    "value": "st",
    "label": "Southern Sotho"
  },
  {
    "value": "su",
    "label": "Sundanese"
  },
  {
    "value": "sv",
    "label": "Swedish"
  },
  {
    "value": "sw",
    "label": "Swahili"
  },
  {
    "value": "ta",
    "label": "Tamil"
  },
  {
    "value": "te",
    "label": "Telugu"
  },
  {
    "value": "tg",
    "label": "Tajik"
  },
  {
    "value": "th",
    "label": "Thai"
  },
  {
    "value": "ti",
    "label": "Tigrinya"
  },
  {
    "value": "tk",
    "label": "Turkmen"
  },
  {
    "value": "tl",
    "label": "Tagalog"
  },
  {
    "value": "tn",
    "label": "Tswana"
  },
  {
    "value": "to",
    "label": "Tonga (Tonga Islands)"
  },
  {
    "value": "tr",
    "label": "Turkish"
  },
  {
    "value": "ts",
    "label": "Tsonga"
  },
  {
    "value": "tt",
    "label": "Tatar"
  },
  {
    "value": "tw",
    "label": "Twi"
  },
  {
    "value": "ty",
    "label": "Tahitian"
  },
  {
    "value": "ug",
    "label": "Uyghur, Uighur"
  },
  {
    "value": "uk",
    "label": "Ukrainian"
  },
  {
    "value": "ur",
    "label": "Urdu"
  },
  {
    "value": "uz",
    "label": "Uzbek"
  },
  {
    "value": "ve",
    "label": "Venda"
  },
  {
    "value": "vi",
    "label": "Vietnamese"
  },
  {
    "value": "vo",
    "label": "VolapÃ¼k"
  },
  {
    "value": "wa",
    "label": "Walloon"
  },
  {
    "value": "wo",
    "label": "Wolof"
  },
  {
    "value": "xh",
    "label": "Xhosa"
  },
  {
    "value": "yi",
    "label": "Yiddish"
  },
  {
    "value": "yo",
    "label": "Yoruba"
  },
  {
    "value": "za",
    "label": "Zhuang, Chuang"
  },
  {
    "value": "zh",
    "label": "Chinese"
  },
  {
    "value": "zu",
    "label": "Zulu"
  }
 ]

  
  export const ISO639_1 = ISO639_1_Languages.map(l => ({ ...l, display: l.value.toLocaleUpperCase() + "  (" + l.label + ")"}))