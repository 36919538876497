import React from 'react';
import { Link } from "react-router-dom";
import { currencyFormat } from '../../../../models/Helper';

const PeriodicLimitTableRow = (props: any) => {
    const { data, type, setOpenPopup, setPeriodicLimit,securityClassifications,proccedAndLoad,canPageBeRendered } = props;

    const setDisplay = (val: number | null, divisor: number) => {
      if (val === undefined) return '-'
      return val === null ? '-' : val === 0 ? 0 : Number(currencyFormat(val/divisor));  
    };

    const updateAccess = securityClassifications?.includes("Update");
  
    return (
        <tr>
            <td style={{ width: "20%" }}>
                <Link
                  to="#"
                  onClick={() => {
                    setOpenPopup(true);
                    setPeriodicLimit(type);
                  }}
                >
                  <i className="fas fa-pen-square" />
                </Link>
                &emsp;
                {type}
            </td>
            <td className="text-right" style={{ width: "13%" }}>{setDisplay(data.authorizationDropOffPeriod,1)}</td>
            <td className="text-right" style={{ width: "13%" }}>{setDisplay(data.maxTransactionAmount ,100)}</td>
            <td className="text-right" style={{ width: "13%" }}>{setDisplay(data.daily ,100)}</td>
            <td className="text-right" style={{ width: "13%" }}>{setDisplay(data.monthly ,100)}</td>
            <td className="text-right" style={{ width: "13%" }}>{setDisplay(data.yearly ,100)}</td>
            <td className="text-right" style={{ width: "13%" }}>{setDisplay(data.lifetime ,100)}</td>
        </tr>
    )
}

export default PeriodicLimitTableRow
