import React, { useReducer } from 'react'
import { toast } from 'react-toastify';
import { createAPIEndpoint, createFXEndpoint, ENDPOINTS } from '..';
import { ForextMgtInitState, IForextMgt } from "../../models/IForextMgt"
import { GET_FX, GET_FX_DETAILS } from '../types';
import ForexMgtContext from './ForexMgtContext';
import ForexMgtReducer from './ForexMgtReducer';

const ForexMgtState: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const initialState: ForextMgtInitState = {
        fx: [],
        fxDetails: null,
        totalRows: 0,
        loading: true,
        pageSize: 10,
        pageNo: 1,
        error: null
    }

    const [state, dispatch] = useReducer(ForexMgtReducer, initialState);

    const getFxRates = async (search: '', pageNo: number, pageSize: number) => {
        await createAPIEndpoint(ENDPOINTS.FOREXMGT).fetchPaginated(search, pageSize, pageNo)
            .then(res => {
                dispatch({ type: GET_FX, payload: res.data })
            }).catch(err => {
                toast.error(err, err.message)
            });
    }

    const getFxRate = async (source: string, buying: string) => {
        await createFXEndpoint(ENDPOINTS.FOREXMGT).getFxRate(source, buying)
            .then(res => {
                dispatch({ type: GET_FX_DETAILS, payload: res.data.data })
            }).catch(err => {
                toast.error(err, err.message)
            });
    }
    
    const udpateFxRate = async (fx: IForextMgt) => {

    }

    return (
        <ForexMgtContext.Provider value={{
            fx: state.fx,
            fxDetails: state.fxDetails,
            totalRows: state.totalRows,
            loading: state.loading,
            pageSize: state.pageSize,
            pageNo: state.pageNo,
            error: state.error,
            getFxRates,
            getFxRate,
            udpateFxRate
        }}>
             {children}
        </ForexMgtContext.Provider>
    )
}

export default ForexMgtState
