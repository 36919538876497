import "./UserDefined.css";

const UserDefined = (props:any) => {
  const { data } = props;

  return (
    <div className="userdef-container">
      <div className="userDef" title={data[0]}>{data[0] ? data[0] : '-'}</div>
      <div className="userDef" title={data[1]}>{data[1] ? data[1] : '-'}</div>
    </div>
  );
};

export default UserDefined;
