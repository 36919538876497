import { Link } from "react-router-dom";
import { formatStringIfNullOrEmpty } from '../../../../models/Helper';

const GlobalLimitTableRow = (props: any) => {
    const { data, type, setOpenPopup, setPeriodicLimit,securityClassifications,canPageBeRendered,proccedAndLoad } = props;
    const updateAccess = securityClassifications?.includes("Update");

    return (
        <tr>
            <td style={{ width: "20%" }}>
                {updateAccess && <Link
                  to="#"
                  onClick={() => {
                    setOpenPopup(true);
                    setPeriodicLimit(type);
                  }}
                >
                  <i className="fas fa-pen-square" />
                </Link>
                }
                &emsp;
                {type}
            </td>            
            <td className="text-right" style={{ width: "16%" }}>{data.maxTransactionAmount === null ? "-" : formatStringIfNullOrEmpty(data.maxTransactionAmount / 100)}</td>
            <td className="text-right" style={{ width: "16%" }}>{data.daily === null ? "-" : formatStringIfNullOrEmpty(data.daily / 100)}</td>
            <td className="text-right" style={{ width: "16%" }}>{data.monthly === null ? "-" : formatStringIfNullOrEmpty(data.monthly / 100)}</td>
            <td className="text-right" style={{ width: "16%" }}>{data.yearly === null ? "-" : formatStringIfNullOrEmpty(data.yearly / 100)}</td>
            <td className="text-right" style={{ width: "16%" }}>{data.lifetime === null ? "-" : formatStringIfNullOrEmpty(data.lifetime / 100)}</td>
        </tr>
    )
}

export default GlobalLimitTableRow
