import { useContext, useState } from "react";
import { GetNewCurrencies } from "../../../../models/enum/currencies";
import SetupContext from "../../../../context/setup/SetupContext";
import { toast } from "react-toastify";
import SecurityClassificationError from "../../../errors/SecurityClassification";


const AddCurrencyLimit = (props: any) => {
  const [currency, set_currency] = useState(0);
  const { setOpenPopup, issuerId, setupId, currencyLimits,securityClassifications,canPageBeRendered,proccedAndLoad } = props;
  const { addCurrencyLimit } = useContext(SetupContext);

  const createAccess = securityClassifications?.includes("Create");

  if(!createAccess)
  {
      return (<SecurityClassificationError title="" pageName="" extraReference={"Add Currency Limit"} withWrapper={false}/>)
  }

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (!currency)
      toast.error(
        <div>
          <i className="fas fa-times mr-2"></i>Select a currency to add.
        </div>
      );
    else {
      addCurrencyLimit(issuerId, setupId, currency);
      setOpenPopup(false);
    }
  };

  const disableOpt = (item: any) => {
    return currencyLimits.some((el:any) => el['currencyName'] === item['id']);
  }
  return (
    <form className="row" onSubmit={onSubmit}>
      <div className="col-sm-12">
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">Currency</label>
          <div className="col-sm-8">
            <select
              name="defaultCurrency"
              className="form-control"
              onChange={(e: any) => set_currency(e.target.value)}
            >
              <option value={0}>Select Currency</option>
              {GetNewCurrencies().map((item: any) => (
                <option key={item.id} value={item.numCode} disabled={disableOpt(item)}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <hr />
        <div className="form-group row">
          <div className="float-right">
            <button
              type="button"
              className="btn-secondary btn-sm m-1"
              onClick={() => setOpenPopup(false)}
            >
              Cancel
            </button>
            {
              createAccess && <button type="submit" className="btn-success btn-sm m-1">
              Save
            </button>
            }
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddCurrencyLimit;
