import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import Spinner from '../../../components/spinner/Spinner';
import { TableHeaders, TableInputSearch, TablePaginator } from '../../../components/table';
import RoleBasedAccessControlContext from '../../../context/rolebasedaccesscontrol/RoleBasedAccessControlContext';
import AccountsContext from '../../../context/accounts/AccountsContext';
import { accountsColumns } from '../../../models/enum/columns';
import Wrapper from '../../Wrapper'
import UnauthorizedPage from "../../errors/UnauthorizedPage";
import SecurityClassificationError from "../../errors/SecurityClassification";
import { useLocation } from 'react-router-dom';

const Accounts = () => {
    const { securityClassifications,getSecurityClassification,retrievePagePermission}  = useContext(RoleBasedAccessControlContext);
    const location = useLocation();
    const [proccedAndLoad, setUserAccess] = useState<boolean>(false);
    const [canPageBeRendered, canInvokePageContent] = useState(false); 

    useEffect(() => {
        let pageName = location?.pathname?.split('/').pop()?.toLowerCase();
        retrievePagePermission(pageName).then((isGranted: boolean) => {
            canInvokePageContent(true);
            setUserAccess(isGranted);
        }).catch((error: any) => {
            canInvokePageContent(false);
            setUserAccess(false);
          });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        getSecurityClassification();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    const readAccess = securityClassifications?.includes("Read");

    const { accounts, pageSize, pageNo, totalRows, loading, getAccounts } = useContext(AccountsContext);
    const [page, setPage] = useState(pageNo);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [itemsPerPage, setItemsSize] = useState(pageSize);


    useEffect(() => {
        getAccounts(search, 10, 1);        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        if(itemsPerPage !== pageSize)
            getAccounts(search, itemsPerPage, page);        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsPerPage]);

    useEffect(() => {
        if(page !== pageNo)
            getAccounts(search, itemsPerPage, page);        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    const accountsData = useMemo(() => {
        let computedFx = accounts.data;

        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedFx = computedFx.sort((a: any, b: any) => reversed * a[sorting.field].localeCompare(b[sorting.field]))
        }

        return computedFx;
    }, [accounts, sorting]);

    
    if(!canPageBeRendered)
    {
        return (<Wrapper title="Accounts Management" subTitle="List of Accounts">< Spinner /> </Wrapper> )
    }
    
    if(!proccedAndLoad)
    {
        return <UnauthorizedPage />;
    }

    if(!readAccess){
        return (<SecurityClassificationError title="Read Classification Denied" pageName="Accounts Management" extraReference="Accounts Management" withWrapper={true} />)
    }
      
    return (
        <Wrapper title="Accounts Management" subTitle="List of Accounts">
            <div className="card-body">
            {
                    !loading && accountsData ?
                        <div className="dataTables_wrapper dt-bootstrap4">
                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                
                                </div>

                                <div className="col-sm-12 col-md-6">
                                    <div className="dataTables_filter">
                                        <TableInputSearch
                                            onSearch={(value: string) => {
                                                setSearch(value);
                                            }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <table id="table-data" className="table table-striped dataTable dtr-inline table-sm table-bordered" role="grid">
                                        <TableHeaders
                                            headers={accountsColumns}
                                            onSorting={(field: string, order: string) => {
                                                setSorting({ field, order })
                                            }} />
                                        <tbody>
                                            {
                                                accountsData !== undefined && Object.keys(accountsData).length !== 0 && accountsData.constructor !== Object ? (
                                                    accountsData.map(
                                                        (acc: any, idx: number) => (
                                                            <tr key={idx}>
                                                                <td> { readAccess && 
                                                                <Link to={`/accounts/${acc.accountId}`}>
                                                                <i className="fas fa-eye text-warning"></i>
                                                                </Link>
                                                                }
                                                                </td>
                                                                <td>{acc.accountId}</td>
                                                                <td>{acc.accountName}</td>
                                                                <td>{acc.setupId}</td>
                                                                <td>{acc.created}</td>
                                                            </tr>
                                                        )
                                                    )
                                                ) : (
                                                    <tr><td className="text-center text-danger" colSpan={6}>No data available in table</td></tr>
                                                )
                                            }
                                        </tbody>

                                    </table>
                                </div>
                            </div>

                            <TablePaginator
                                total={totalRows}
                                itemsPerPage={pageSize}
                                currentPage={pageNo}
                                onPageChange={(page: any) => setPage(page)}           
                                onPageSizeChange={(size:number) => setItemsSize(size)}                     
                            />
                        </div> : <Spinner />
                }
            </div>
        </Wrapper> 
    )
}

export default Accounts
