export const DeliveryMethodOptions = [
    {
      "id": "01",
      "name": "Royal Mail First Class"
    },
    {
      "id": "02",
      "name": "Royal Mail Second Class"
    },
    {
      "id": "03",
      "name": "Royal Mail Registered"
    },
    {
      "id": "04",
      "name": "Royal Mail Airmail"
    },
    {
      "id": "05",
      "name": "Royal Mail Registered Airmail"
    },
    {
      "id": "08",
      "name": "Royal Mail Bulk Registered"
    },
    {
      "id": "11",
      "name": "Whistl 1st"
    },
    {
      "id": "12",
      "name": "Whistl 2nd"
    },
    {
      "id": "21",
      "name": "DHL"
    },
    {
      "id": "22",
      "name": "Bulk DHL"
    },
    {
      "id": "24",
      "name": "Bulk FedEx"
    }
   ]