import React, { useState } from 'react'

interface IProps {
    onSearch(value: string): void;
    disabled?: boolean
}

const TableInputSearch: React.FC<IProps> = (props) => {

    const{onSearch, disabled} = props;
    
    const [search, setSearch] = useState("");

    const onInputChange =(value: string) => {
        setSearch(value);
        onSearch(value);   
    }
    return (

        <label>
            Search:
            <input type="text"
                className="form-control form-control2"
                style={{ width: "240px" }}
                placeholder="Search" 
                value={search}
                disabled={disabled}
                onChange={(e) => {
                    onInputChange(e.target.value)
                }}/>

        </label>
    )
}

export default TableInputSearch
