import React, {useState} from "react";
import UpdateMccLimit from "./UpdateMccLimit";
import Popup from "../../../../components/dialog/Popup";
import { currencyFormat } from "../../../../models/Helper";
import { Link } from "react-router-dom";
import { HelpDescriptions } from "../../../../models/enum/helpDescriptions";

const MccLimitTableRow = (props: any) => {
  const { data, issuerId, setupId, customerType,currencyCode,securityClassifications,proccedAndLoad,canPageBeRendered} = props;
  const [openPopup, setOpenPopup] = useState(false);

  const setDisplay = (val: number | null, divisor: number) => {
    if (val === undefined) return '-'
    return val === null ? '-' : val === 0 ? 0 : Number(currencyFormat(val/divisor));  
  };

  const updateAccess = securityClassifications?.includes("Update");

  return data !== null && data.length ? (
    data.map((mccLimit: any, idx: number) => (
      <tr key={idx}>
        <td className="text-center" style={{ width: "20%" }}>
          {data.mcc === null ? (
            "-"
          ) : (
              <div>
              { updateAccess && <Link
                to="#"
                onClick={() => {
                  setOpenPopup(true);
                }}
              >
                <i className="fas fa-pen-square" /> &emsp;
              </Link>
              }
              {mccLimit['mcc'].join(', ')}
              </div>
            )}
        </td>
        <td className="text-right" style={{ width: "16%" }}>
          {setDisplay(mccLimit.authorizationDropOffPeriod,1)}            
        </td>
        <td className="text-right" style={{ width: "16%" }}>
          {setDisplay(mccLimit.maxTransactionAmount,100)}
        </td>
        <td className="text-right" style={{ width: "16%" }}>
          {setDisplay(mccLimit.daily,100)}
        </td>
        <td className="text-right" style={{ width: "16%" }}>
          {setDisplay(mccLimit.monthly,100)}
        </td>
        <td className="text-right" style={{ width: "16%" }}>
          {setDisplay(mccLimit.yearly,100)}
        </td>
        <Popup
          title={`MCC LIMIT`}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          size="lg"
        >
          <div className="description">{HelpDescriptions.CURRENCY_VALUE_LIMTS}</div><br/> 
          <UpdateMccLimit
          data={data[0]}
          issuerId={issuerId}
          setupId={setupId}
          currencyCode={currencyCode}
          customerType={customerType}
          messageReference={`MCC LIMIT`}
          securityClassifications={securityClassifications} 
          canPageBeRendered={canPageBeRendered} 
          proccedAndLoad={proccedAndLoad}
          setOpenPopup={setOpenPopup}
          />
        </Popup>
      </tr>
    ))
  ) : (
    <tr>
      <td className="text-center">
      { updateAccess && <Link
          to="#"
          onClick={() => {
            setOpenPopup(true);
          }}
        >
          <i className="fas fa-pen-square" />
        </Link>
      }
      </td>
      <td className="text-center text-danger" colSpan={4}>
        No data available in table
      </td>
      
      <Popup
          title={`MCC LIMIT`}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          size="lg"
        >
          <UpdateMccLimit
          data={{
            "maxTransactionAmount": null,
            "daily": null,
            "monthly": null,
            "yearly": null,
            "authorizationDropOffPeriod": null,
            "mcc": []
          }}
          issuerId={issuerId}
          setupId={setupId}
          currencyCode={currencyCode}
          customerType={customerType}
          messageReference={`MCC LIMIT`}
          securityClassifications={securityClassifications} 
          canPageBeRendered={canPageBeRendered} 
          proccedAndLoad={proccedAndLoad}
          setOpenPopup={setOpenPopup}
          />
        </Popup>
    </tr>
  );
};

export default MccLimitTableRow;
