import React, { useState } from 'react'
import Popup from '../../../../components/dialog/Popup';
import { HelpDescriptions } from '../../../../models/enum/helpDescriptions';
import PeriodicLimitTableRow from './PeriodicLimitTableRow'
import UpdateRegionalLimit from './UpdateRegionalLimit';
import UnauthorizedPage from "../../../errors/UnauthorizedPage";

const RegionalLimit = (props: any) => {
    const { data, title, subtitle, issuerId, setupId, currencyCode, customerType, isExpanded,securityClassifications,proccedAndLoad,canPageBeRendered} = props;
    const [openPopup, setOpenPopup] = useState(false);
    const [periodicLimit, setPeriodicLimit] = useState('');
    
    const readAccess = securityClassifications?.includes("Read");
    const updateAccess = securityClassifications?.includes("Update");

    if(!proccedAndLoad || !readAccess)
    {
        return <UnauthorizedPage withWrapper={false} />;
    }

    return (
        <div className={`p-0 ${isExpanded ? '' : 'collapse'}`}>
            <table className="table table-hover table-bordered table-sm">
                <thead className="alert alert-success">
                    <tr>
                        <th></th>
                        <th className="text-right">Drop-off Period</th>
                        <th className="text-right">SingleTrn</th>
                        <th className="text-right">Daily</th>
                        <th className="text-right">Monthly</th>
                        <th className="text-right">Yearly</th>  
                        <th className="text-right">Lifetime</th>                      
                    </tr>
                </thead>

                {
                    data !== null &&
                    <tbody>
                        {
                            updateAccess && <PeriodicLimitTableRow data={data.domestic} type="Domestic" setOpenPopup={setOpenPopup} setPeriodicLimit={setPeriodicLimit} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}/>
                        }
                        {
                            updateAccess && <PeriodicLimitTableRow data={data.intranational} type="Intranational" setOpenPopup={setOpenPopup} setPeriodicLimit={setPeriodicLimit} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}/>
                        }
                        {
                            updateAccess && <PeriodicLimitTableRow data={data.international} type="International" setOpenPopup={setOpenPopup} setPeriodicLimit={setPeriodicLimit} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}/>
                        }
                        {
                            updateAccess && <PeriodicLimitTableRow data={data.globally} type="Globally" setOpenPopup={setOpenPopup} setPeriodicLimit={setPeriodicLimit} securityClassifications={securityClassifications} canPageBeRendered={canPageBeRendered} proccedAndLoad={proccedAndLoad}/>
                        }
                    </tbody>
                }
            </table>
            {
                updateAccess && <Popup title={`${title} || ${subtitle} || ${periodicLimit}`} openPopup={openPopup} setOpenPopup={setOpenPopup}>
                <div className="description">{HelpDescriptions.CURRENCY_VALUE_LIMTS}</div><br/>              
                    <UpdateRegionalLimit
                        data={data[periodicLimit.toLowerCase()]}
                        issuerId={issuerId}
                        setupId={setupId} 
                        currencyCode={currencyCode}
                        customerType={customerType}
                        periodicLimit={periodicLimit}
                        limitType={subtitle === 'E-Commerce' ? 'ecommerce' : subtitle.toLowerCase()}
                        messageReference={`${title} || ${subtitle} || ${periodicLimit}`}
                        securityClassifications={securityClassifications} 
                        canPageBeRendered={canPageBeRendered} 
                        proccedAndLoad={proccedAndLoad}
                        setOpenPopup={setOpenPopup}/>
                </Popup>
            }
        </div>
    )
}

export default RegionalLimit
