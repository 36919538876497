import React from 'react'
import Wrapper from '../Wrapper'

const NotFound = () => {
    
    return (
        <Wrapper title="Error Page" subTitle="Item not found">
            <div className="card-body">
                <div className="error-page">
                    <h2 className="headline text-warning"> 404</h2>
                    <div className="error-content">
                        <h3><i className="fas fa-exclamation-triangle text-warning" /> Oops! Item not found.</h3>
                        <p>
                            We could not find the item you were looking for.
                            Meanwhile, you may <a href="/">return to Home Page</a>.
                        </p>
                    </div>
                    {/* /.error-content */}
                </div>
            </div>
        </Wrapper>
    )
}

export default NotFound
