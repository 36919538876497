import { useState, SyntheticEvent, useContext } from 'react';
import Popup from "../../../../components/dialog/Popup";
import SetupContext from '../../../../context/setup/SetupContext';
import TagsInput from '../../../../components/tagsInput/TagsInput';
import UnauthorizedPage from "../../../errors/UnauthorizedPage";
import SecurityClassificationError from "../../../errors/SecurityClassification";


const MccBlocksTable = (props: any) => {
    const { data, issuerId, setupId,securityClassifications,canPageBeRendered,proccedAndLoad } = props;
    const [openPopup, setOpenPopup] = useState(false);
    const [mcc, set_mcc] = useState<Array<any>>([]);
    const { addMccBlock } = useContext(SetupContext);

    const onSubmit = (e: SyntheticEvent) => {
        e.preventDefault();
        const payload = { mccList: mcc.map(item => Number(item)) };
        addMccBlock(issuerId, setupId, payload);
        setOpenPopup(false);
    };
  

    const readAccess = securityClassifications?.includes("Read");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const updateAccess = securityClassifications?.includes("Update");
    const createAccess = securityClassifications?.includes("Create");

    if(!proccedAndLoad)
    {
        return <UnauthorizedPage withWrapper={false} />;
    }

    if(!readAccess){
        return (<SecurityClassificationError title="Read Classification Denied" pageName="MCC Block" extraReference='MCC Block' withWrapper={false}/>)
    }

    return (
        <>
            <span>
                {
                    createAccess && <button type="button" className="btn btn-round btn-sm btn-success mb-1" onClick={() => {
                                    setOpenPopup(true);
                                }}>
                    <i className="fa fa-plus p-1" />
                    <span>Set MCC Block</span>
                </button>
                }
            </span>

            <div className="space-4"></div>

            <table id="table-data" className="table table-bordered table-hover table-sm" role="grid">
                <thead className="table-header">
                    <tr role="row">
                        <th>
                            Blocked Mccs
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.length > 0 ? (
                            data.map(
                                (mcc: any, idx: number) => (
                                    <tr key={idx}>
                                        <td>{mcc}</td>
                                    </tr>
                                )
                            )
                        ) : (
                            <tr><td className="text-center text-danger" colSpan={5}>No MCC blocks found</td></tr>
                        )
                    }
                </tbody>


                <Popup title="Add MCC Blocks" openPopup={openPopup} setOpenPopup={setOpenPopup}>
                    {
                    createAccess ? (
                    <form className="row" onSubmit={onSubmit}>
                        <div className="col-sm-12">
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">MCC</label>
                                <div className="col-sm-8">
                                    <TagsInput 
                                        placeHolder={'Enter MCC'}
                                        inputTags={data}
                                        onChange={(newTags: any) => set_mcc(newTags)}
                                        onValidate={(value) => {
                                            try {
                                                const val = Number(value);
                                                return !Number.isNaN(val);
                                            } catch (error) {
                                                return false
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <hr />
                            <div className="form-group row">
                                <div className="float-right">
                                    <button
                                        type="button"
                                        className="btn-secondary btn-sm m-1"
                                        onClick={() => setOpenPopup(false)}
                                    >
                                        Cancel
                                    </button>
                                    {
                                        createAccess && <button type="submit" className="btn-success btn-sm m-1">
                                            Save
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>) : (
                        <SecurityClassificationError title="" pageName="" extraReference='Add MCC Block' withWrapper={false}/>
                    )}
                </Popup>
            </table>
        </>
    )
}

export default MccBlocksTable
