import { useState, useEffect } from "react";
import { ISO639_1 } from "../../../../models/enum/language";
import { PersoTypeCode } from "../../../../models/enum/PersoTypeCode";
import { vendorTypes } from "../../../../models/enum/vendorTypes";
import { TProductInformation } from "../../../../models/ICardVendor";
import CardReverse from "./CardReverse";
import Carrier from "./Carrier";
import InstantIssuePacks from "./InstantIssuePacks";

const ProductInformation = ({ productInformationData, handleChanges }: {productInformationData:any, handleChanges: any}) => {
  const [productInformation, setProductInformation] =
    useState<TProductInformation>(productInformationData);

  useEffect(() => {
    setProductInformation(productInformationData);
  }, [productInformationData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {     
    const { id, value} = e.currentTarget    
      handleChanges(id, value, vendorTypes.PRODUCT_INFORMATION )       
  }; 

  const handleCardReverse = (name: string, value: any) => {     
    handleChanges(name, value, vendorTypes.CARD_REVERSE )
  };

  const handleCarrier = (name: string, value: any) => {       
    handleChanges(name, value, vendorTypes.CARRIER )
  };

  const handleInstantIssuePacks = (name: string, value: any) => {       
    handleChanges(name, value, vendorTypes.INSTANT_ISSUE_PACKS )
  };  

  return (
    <div className="card-body">
      <div className="row">
        <div className="col-md-4">
          <div className="form-group row">
            <label className="col-sm-3 col-form-label required-field">Product Code</label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="productCode"
                name="productCode"
                value={productInformation?.productCode || ""}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">Image Code</label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="imageCode"
                name="imageCode"
                value={productInformation?.imageCode || ""}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">Logo Code</label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="logoCode"
                name="logoCode"
                value={productInformation?.logoCode || ""}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">Carrier Template Name</label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="templateCode"
                name="templateCode"
                value={productInformation?.templateCode || ""}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">Perso Type Code</label>
            <div className="col-sm-8">
              <select
                id="persoTypeCode"
                name="persoTypeCode"
                className="form-control form-control-sm"
                value={productInformation?.persoTypeCode || ""}
                onChange={handleChange}
                required
              >
                <option value={""}>Select Perso Type Code</option>
                {PersoTypeCode.map((item: any) => (
                  <option key={item.id} value={item.id}>
                    {item.id} - {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>          
          <div className="form-group row">
            <label className="col-sm-3 col-form-label required-field">Language</label>
            <div className="col-sm-8">
              <select
                id="language"
                name="language"
                className="form-control form-control-sm"
                value={productInformation?.language || ""}
                onChange={handleChange}
              >
                <option value={""}>Select Language</option>                
                {ISO639_1.map((lang: any) => (
                  <option key={lang.value} value={lang.value}>
                    {lang.display}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card h-100">
            <div className="badge bg-light">Card Reverse</div>
            <CardReverse cardReverseData={productInformation?.cardReverse}
              handleChanges={handleCardReverse} />
          </div>
        </div>
        <div className="col-md-4">
          <div className="card h-100">
            <div className="badge bg-light">Carrier</div>
            <Carrier carrierData={productInformation?.carrier}
              handleChanges={handleCarrier} />
          </div>
        </div>
        <div className="col-md-4">
          <div className="card h-100">
            <div className="badge bg-light">Instant Issue Packs</div>
            <InstantIssuePacks
              instantIssuePacksData={productInformation?.instantIssuePacks}
              handleChanges={handleInstantIssuePacks}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductInformation;
